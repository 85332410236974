import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Avatar, withStyles } from '@material-ui/core';

import Tooltip from 'components/Tooltip';

import utils from '~/utils';

function getNameInitials(name) {
  return name
    .split(' ')
    .slice(0, 2)
    .map(part => part.substr(0, 1))
    .join('');
}

const muiStyles = theme => ({
  avatar: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
});

const styles = {
  size: size => ({
    minWidth: size,
    width: size,
    height: size,
    lineHeight: `${size}px`,
    fontSize: Math.floor(size / 2),
  }),
};

const AvatarComponent = ({
  displayName,
  showTooltip,
  size,
  className,
  imgSrc,
  alt,
  classes,
  onClick,
  'data-testid': PAGE_NAME,
}) => (
  <Tooltip
    title={displayName}
    disableFocusListener={!showTooltip}
    disableHoverListener={!showTooltip}
    disableTouchListener={!showTooltip}
  >
    <Avatar
      data-testid={PAGE_NAME}
      alt={alt}
      src={imgSrc}
      className={classNames(classes.avatar, className)}
      // NOTE: Here we are using inline-styles because currently MUI
      // not supports passing props into style object
      style={{
        ...styles.size(size),
        backgroundColor: !!displayName && `#${utils.getColorFromString(displayName)}`,
      }}
      onClick={onClick}
    >
      {displayName ? getNameInitials(displayName) : null}
    </Avatar>
  </Tooltip>
);

AvatarComponent.propTypes = {
  displayName: PropTypes.string,
  showTooltip: PropTypes.bool,
  size: PropTypes.number,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  'data-testid': PropTypes.string,

  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

AvatarComponent.defaultProps = {
  displayName: '',
  showTooltip: false,
  size: 35,
  className: undefined,
  imgSrc: undefined,
  alt: undefined,
  onClick: noop,
  'data-testid': 'generic_avatar',
};

export default withStyles(muiStyles)(AvatarComponent);
