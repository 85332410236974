import { WOOTRIC_ENABLED } from '~/externalServices/serviceFlags';
import { CORVA_COMPANY_PROVIDER } from '~/constants/main';

export function appendWootricStyle() {
  const styles =
    '#wootric-modal #wootric-close { background: #FF0000 !important; color: white !important; width: 90px !important; }' +
    ' #wootric-modal #wootric-close:hover { background-color: #FF0000 !important; } #close-icon { stroke: #ffffff !important; } ' +
    '#wootric-dismiss { display: inline-block !important; font-weight: 320 !important; font-size: 16px !important; margin-left: -4px !important; }';

  const wootricCSS = document.createElement('style');
  wootricCSS.type = 'text/css';

  if (wootricCSS.styleSheet) wootricCSS.styleSheet.cssText = styles;
  else wootricCSS.appendChild(document.createTextNode(styles));

  document.getElementsByTagName('head')[0].appendChild(wootricCSS);
}

export function initializeWootricNPS(currentUser) {
  if (!WOOTRIC_ENABLED) return;
  const isCorvaUser = currentUser.getIn(['company', 'provider']) === CORVA_COMPANY_PROVIDER;
  if (isCorvaUser) return;

  appendWootricStyle();

  const email = currentUser.get('email');
  const createdAt = Math.trunc(Date.parse(currentUser.get('created_at')) / 1000);

  window.wootricSettings = {
    email,
    created_at: createdAt,
    account_token: 'NPS-75a0b9df',
  };
  window.wootric?.('run');
}
