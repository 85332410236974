import Rollbar from 'rollbar/dist/rollbar.noconflict.umd.min.js';
import { ROLLBAR_ENABLED } from '~/externalServices/serviceFlags';

const RollbarInstance = new Rollbar({
  enabled: ROLLBAR_ENABLED,
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || 'ebf43f4671014025bc75a1a7f8c5662f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  reportLevel: 'error',
  payload: {
    environment: process.env.REACT_APP_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_GIT_COMMIT_HASH,
        guess_uncaught_frames: true,
      },
    },
  },
  transform: payload => {
    const { trace } = payload.body;

    addNetworkInfo(payload);

    const ignoreRegex = /^(https?):\/\/package\.?[a-zA-Z0-9_-]*\.corva\.ai(.*)/;
    const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.corva\.ai(.*)/;
    const tempRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.s3-website-us-east-1\.amazonaws\.com(.*)/;
    if (trace && trace.frames) {
      for (let i = 0; i < trace.frames.length; i += 1) {
        const { filename } = trace.frames[i];
        if (filename && !filename.match(ignoreRegex)) {
          const m = filename.match(locRegex);
          // Be sure that the minified_url when uploading includes 'dynamichost'
          if (m) {
            trace.frames[i].filename = `${m[1]}://dynamichost${m[2]}`;
          } else {
            const tempM = filename.match(tempRegex);
            if (tempM) {
              trace.frames[i].filename = `${tempM[1]}://dynamichost${tempM[2]}`;
            }
          }
        }
      }
    }
  },
  checkIgnore: (isUncaught, _args, payload) => {
    if (detectHeadlessResizeObserverException({ isUncaught, payload })) {
      return true;
    }

    return false;
  },
});

window.Rollbar = RollbarInstance;

// Filter "Out of Memory" errors for headless chrome:
// https://app.rollbar.com/a/corva/fix/item/Corva-Web-Frontend/2292
//
// The error itself is harmless and identify that the ResizeObserver did not execute in a single animation frame time.
// But we still need to address it as it occurs pretty often and takes up space in our errors usage quotas
const detectHeadlessResizeObserverException = ({ isUncaught, payload }) => {
  const isHeadlessChrome = payload?.client?.javascript?.browser?.includes('HeadlessChrome');
  const isResizeObserverLimitException = payload?.body?.trace?.exception?.description?.includes(
    'ResizeObserver loop limit exceeded'
  );

  return isUncaught && isHeadlessChrome && isResizeObserverLimitException;
};

// This is a temporary function to better understand the case of failed chunks errors.
// The assumption is that they occur on bad internet connection - but we need to check it first
// If yes - we'll just update the config to ignore them
function addNetworkInfo(payload) {
  payload.custom = payload.custom || {};
  payload.custom.networkInfo = {
    isOnLine: navigator.onLine,
  };

  // Attempt to add network speed/type info using the Network Information API (if available)
  if (navigator.connection) {
    payload.custom.networkInfo = {
      ...payload.custom.networkInfo,
      effectiveType: navigator.connection.effectiveType, // e.g., '4g'
      downlink: navigator.connection.downlink, // Approximate download speed, in Mbps
      rtt: navigator.connection.rtt,
      saveData: navigator.connection.saveData,
    };
  }
}
