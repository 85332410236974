import { push } from 'react-router-redux';
import { initializeSocketClient } from '@corva/ui/clients/subscriptions';

import { connect } from '~/actions/subscriptions';

import { MAKE_APP_READY, SHOW_LOAD, HIDE_LOAD, RESET_APP_STATE } from '~/constants/main';

import * as nativeMessages from '~/utils/nativeMessages';

export function resetAppState() {
  return { type: RESET_APP_STATE };
}

export function makeAppReady() {
  return { type: MAKE_APP_READY };
}

export function showLoad() {
  return { type: SHOW_LOAD };
}

export function hideLoad() {
  return { type: HIDE_LOAD };
}

export function start() {
  return (dispatch, getState) => {
    connect(dispatch);
    initializeSocketClient();

    const currentRoute = getState().routing.locationBeforeTransitions.pathname;
    if (currentRoute === '/') dispatch(push('/dashboards'));

    nativeMessages.notifyPageLoaded();
  };
}
