import { Map, fromJS } from 'immutable';
import { getSubscriptionsClient } from '@corva/ui/clients/subscriptions';

import * as apiClient from '~/clients/api';

import {
  NAME,
  SUBSCRIBE_APP_FOR_ASSET,
  UNSUBSCRIBE_APP_FROM_ASSET,
  RECEIVE_APP_ASSET_DATA,
  RECEIVE_INITIAL_APP_ASSET_DATA,
  SUBSCRIBE_USER_FOR_DATA,
  UNSUBSCRIBE_USER_FROM_DATA,
  RECEIVE_USER_DATA,
  DEFAULT_SUBSCRIPTION_META,
  SET_APP_ASSET_DATA_ERROR,
} from '~/constants/subscriptions';

const subscriptionsClient = getSubscriptionsClient();

function receiveAppAssetData(event) {
  return {
    type: RECEIVE_APP_ASSET_DATA,
    appInstanceId: event.appInstanceId,
    provider: event.provider,
    collection: event.collection,
    assetId: event.assetId,
    event: event.event,
    params: fromJS(event.params),
    data: fromJS(event.data),
  };
}

function receiveInitialAppAssetData(event) {
  return {
    type: RECEIVE_INITIAL_APP_ASSET_DATA,
    appInstanceId: event.appInstanceId,
    provider: event.provider,
    collection: event.collection,
    assetId: event.assetId,
    event: event.event,
    params: fromJS(event.params),
    data: fromJS(event.data),
  };
}

function receiveUserData(event) {
  return {
    type: RECEIVE_USER_DATA,
    companyId: event.companyId,
    userId: event.userId,
    collection: event.collection,
    data: fromJS(event.data),
  };
}

function setAppAssetDataError(event) {
  return {
    type: SET_APP_ASSET_DATA_ERROR,
    appInstanceId: event.appInstanceId,
    provider: event.provider,
    collection: event.collection,
    event: event.event,
    error: event.error,
  };
}

export function subscribeAppForAsset(
  appInstanceId,
  subscriptions,
  assetId,
  params = Map(),
  appKey
) {
  return async (dispatch, getState) => {
    const additionalParams = Map(params); // NOTE: Make sure it's immutable.js object

    const existingAppAssetSubscriptions = getState()[NAME].get('appAssetSubscriptions');

    // NOTE: We don't want to subscribe the app once more if it's already subscribed. The issue
    // is that we dispatch RECEIVE_APP_ASSET_DATA in case of accumulate behavior and it will
    // duplicate the data stored in the Redux state.
    const subscriptionRequests = subscriptions
      .filter(
        ({ provider, collection, event = '' }) =>
          !existingAppAssetSubscriptions.hasIn([appInstanceId, provider, collection, event])
      )
      .map(async subscription => {
        const { provider, collection, event = '', params = {} } = subscription;
        const meta = { ...DEFAULT_SUBSCRIPTION_META, ...subscription.meta };
        const isTimeInRouteParams = !!additionalParams.get('time');
        const additionalParamsQuery = additionalParams.get('query');
        const paramsQuery = params.query;

        const mergedQueryContainer =
          additionalParamsQuery && paramsQuery
            ? { query: `${additionalParamsQuery}AND${paramsQuery}` }
            : {};

        // NOTE: subscribeToLatestOnly - well timeline has no affect on this subscription
        const allParams = meta.subscribeToLatestOnly
          ? additionalParams
              .delete('time')
              .delete('query')
              .merge(params)
          : additionalParams.delete('time').merge(params, mergedQueryContainer); // NOTE: We don't need it in sub params

        // NOTE: Filter out 'paramName: undefined'. Because paramName is stored in Store,
        // but received subscription data doesn't contain it. This causes in RECEIVE_APP_ASSET_DATA
        // diffrence in params and data update skip
        const allExistingParams = allParams.filter(param => param !== undefined);

        // NOTE: Only subscribe to live data if we're not asked for a historical time point
        if (
          (!isTimeInRouteParams || meta.alwaysSubscribe || meta.subscribeToLatestOnly) &&
          !meta.subscribeOnlyForInitialData
        ) {
          subscriptionsClient.subscribe({
            appInstanceId,
            provider,
            collection,
            assetId,
            event,
            params: allExistingParams.toJS(),
          });
        }

        dispatch({
          type: SUBSCRIBE_APP_FOR_ASSET,
          appInstanceId,
          provider,
          collection,
          assetId,
          event,
          params: allExistingParams,
        });

        if (!meta.subscribeOnly) {
          let initialData;
          try {
            initialData = await apiClient.getAppStorage(
              provider,
              collection,
              assetId,
              allExistingParams,
              appKey
            );
          } catch (e) {
            dispatch(
              setAppAssetDataError({
                appInstanceId,
                provider,
                collection,
                event,
                error: Map({ code: e.status, message: e.message }),
              })
            );

            console.error(e);
          }

          if (!initialData) return;

          // NOTE: Usually apps expect one item. Those that explicitly set a limit expect
          // a collection of items.
          const data = allExistingParams.has('limit') ? initialData : initialData.last();
          dispatch(
            receiveInitialAppAssetData({
              appInstanceId,
              provider,
              collection,
              assetId,
              event,
              data,
              params: allExistingParams,
            })
          );
        }
      });

    await Promise.all(subscriptionRequests);
  };
}

export function unsubscribeAppFromAsset(appInstanceId, subscriptions) {
  return (dispatch, getState) => {
    const { [NAME]: state } = getState();
    const appAssetSubscriptions = state.getIn(['appAssetSubscriptions', appInstanceId]);

    if (!appAssetSubscriptions) {
      return;
    }

    subscriptions.forEach(({ provider, collection, event = '' }) => {
      const assetId = appAssetSubscriptions.getIn([provider, collection, event, 'assetId']);

      subscriptionsClient.unsubscribe({
        appInstanceId,
        provider,
        collection,
        assetId,
        event,
      });
    });

    dispatch({ type: UNSUBSCRIBE_APP_FROM_ASSET, appInstanceId, subscriptions });
  };
}

export function reloadAppDataForAsset(subscription) {
  return async dispatch => {
    const { appInstanceId, provider, collection, event, assetId, params: rawParams } = subscription;
    const params = fromJS(rawParams);

    let initialData;
    try {
      initialData = await apiClient.getAppStorage(provider, collection, assetId, params);
    } catch (e) {
      return;
    }

    // NOTE: This is important as we just want to replace existing data
    const patchedParams = params.delete('behavior');
    const data = patchedParams.has('limit') ? initialData : initialData.last();

    dispatch(
      receiveAppAssetData({
        appInstanceId,
        provider,
        collection,
        assetId,
        event,
        data,
        params: patchedParams,
      })
    );
  };
}

export function subscribeForUserData(companyId, userId, subscriptions) {
  subscriptions.forEach(({ collection }) => {
    subscriptionsClient.subscribe({
      companyId,
      userId,
      collection,
    });
  });

  return {
    type: SUBSCRIBE_USER_FOR_DATA,
    companyId,
    userId,
    subscriptions,
  };
}

export function unsubscribeFromUserData(companyId, userId, subscriptions) {
  subscriptions.forEach(({ collection }) => {
    subscriptionsClient.unsubscribe({
      companyId,
      userId,
      collection,
    });
  });

  return {
    type: UNSUBSCRIBE_USER_FROM_DATA,
    companyId,
    userId,
    subscriptions,
  };
}

export function subscribeForCompanyData(companyId, subscriptions) {
  subscriptions.forEach(({ collection }) => {
    subscriptionsClient.subscribe({
      companyId,
      collection,
    });
  });

  return {
    type: SUBSCRIBE_USER_FOR_DATA,
    companyId,
    subscriptions,
  };
}

export function unsubscribeFromCompanyData(companyId, subscriptions) {
  subscriptions.forEach(({ collection }) => {
    subscriptionsClient.unsubscribe({
      companyId,
      collection,
    });
  });

  return {
    type: UNSUBSCRIBE_USER_FROM_DATA,
    companyId,
    subscriptions,
  };
}

export function connect(dispatch) {
  function onDataReceive(event) {
    if ('companyId' in event) {
      dispatch(receiveUserData(event));
    } else {
      dispatch(receiveAppAssetData(event));
    }
  }

  function onResubscribe(subscription) {
    if (subscription.assetId) dispatch(reloadAppDataForAsset(subscription));
  }

  subscriptionsClient.connect({
    onDataReceive,
    onResubscribe,
  });
}

export function disconnect() {
  subscriptionsClient.disconnect();
}
