import { flow, isEqual } from 'lodash';
import { createSelector } from 'reselect';

import { allSingleAssetFields } from '~/actions/login';
import { NAME } from '~/constants/login';
import {
  CORVA_COMPANY_PROVIDER,
  EQUINOR_EU_COMPANY_PROVIDER,
  EQUINOR_PROD_COMPANY_PROVIDER,
  HAL_COMPANY_PROVIDER,
} from '~/constants/main';

const stateSelector = state => state[NAME];

export const currentUserSelector = flow(stateSelector, state => state.get('currentUser'));
export const loginAuthFailureSelector = flow(stateSelector, state => state.get('loginAuthFailure'));
export const loggingInSelector = flow(stateSelector, state => state.get('loggingIn'));
export const loginApiFailureSelector = flow(stateSelector, state => state.get('loginApiFailure'));
export const loginTermsFailureSelector = flow(stateSelector, state =>
  state.get('loginTermsFailure')
);
export const loginAccountLockedFailureSelector = flow(stateSelector, state =>
  state.get('loginAccountLockedFailure')
);
export const invalidVerificationCodeSelector = flow(stateSelector, state =>
  state.get('invalidVerificationCode')
);
export const missingVerificationCodeSelector = flow(stateSelector, state =>
  state.get('missingVerificationCode')
);
export const loginFailureSelector = state =>
  loginAuthFailureSelector(state) ||
  loginApiFailureSelector(state) ||
  loginTermsFailureSelector(state) ||
  loginAccountLockedFailureSelector(state) ||
  invalidVerificationCodeSelector(state) ||
  missingVerificationCodeSelector(state);

export const isCurrentUserAdminSelector = flow(stateSelector, state => {
  const role = state.getIn(['currentUser', 'role']);
  return role === 'admin' || role === 'developeradmin';
});

export const isCurrentUserCorvaUserSelector = flow(
  stateSelector,
  state => state.getIn(['currentUser', 'company', 'provider'], '') === CORVA_COMPANY_PROVIDER
);

export const isCurrentUserHalliburtonUserSelector = flow(
  stateSelector,
  state => state.getIn(['currentUser', 'company', 'provider'], '') === HAL_COMPANY_PROVIDER
);

export const isCurrentUserEquinorUserSelector = flow(stateSelector, state => {
  const provider = state.getIn(['currentUser', 'company', 'provider'], '');

  return provider === EQUINOR_PROD_COMPANY_PROVIDER || provider === EQUINOR_EU_COMPANY_PROVIDER;
});

export const isImpersonatingSelector = createSelector([currentUserSelector], currentUser => {
  return currentUser?.get('impersonating') ?? false;
});

export const getCurrentUser = createSelector(
  [currentUserSelector],
  currentUser => currentUser && currentUser.toJS()
);

export const currentUserCompanyId = createSelector([currentUserSelector], currentUser =>
  currentUser.getIn(['company', 'id'])
);

export const currentUserCompanyProviderSelector = createSelector(
  [currentUserSelector],
  currentUser => currentUser.getIn(['company', 'provider'])
);

export const isCorvaV3FeaturesEnabledSelector = createSelector([currentUserSelector], currentUser =>
  currentUser.getIn(['settings', 'uiSettings', 'corva_v3_features_enabled'], false)
);

// NOTE: we need to use single asset from URL
// right now there a lot of places where are this selector is used
// remove it and pass single asset via DashboardInfoStore context
let lastResult = null;
let lastSearch = '';

export const currentUserSingleAssetSelector = createSelector(
  [() => window.location.search],
  search => {
    if (search === lastSearch && lastResult !== null) {
      return lastResult;
    }

    const searchParams = new URLSearchParams(search);
    const singleAssetFromUrl = {};

    allSingleAssetFields.forEach(field => {
      if (searchParams.has(field)) {
        const value = searchParams.get(field);
        singleAssetFromUrl[field] =
          // eslint-disable-next-line no-nested-ternary
          value === 'null' ? null : Number.isNaN(Number(value)) ? value : Number(value);
      }
    });

    if (!isEqual(singleAssetFromUrl, lastResult)) {
      lastResult = singleAssetFromUrl;
      lastSearch = search;
    }

    return lastResult;
  }
);

export const currentUserSingleAssetSelectorOrigin = createSelector(
  [currentUserSelector],
  currentUser => currentUser.getIn(['settings', 'singleAsset'])?.toJS() || {}
);
