import { Map } from 'immutable';

import * as t from '~/constants/tooltips';

const initialState = Map({
  params: {
    label: null,
    targetId: null,
    place: 'top',
    offset: {},
    disable: false,
  },
  customProps: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SHOW_TOOLTIP:
      return state.set('params', action.params).set('customProps', action.customProps);
    case t.HIDE_TOOLTIP:
      return initialState;
    default:
      return initialState;
  }
};
