export const NAME = 'login';

export const LOG_IN = 'login/LOG_IN';
export const LOGGED_IN = 'login/LOGGED_IN';
export const LOGIN_AUTH_FAILED = 'login/LOGIN_AUTH_FAILED';
export const LOGIN_API_FAILURE = 'login/LOGIN_API_FAILURE';
export const LOGIN_TERMS_FAILURE = 'login/LOGIN_TERMS_FAILURE';
export const LOGIN_ACCOUNT_LOCKED_FAILURE = 'login/LOGIN_ACCOUNT_LOCKED_FAILURE';
export const INVALID_VERIFICATION_CODE = 'login/INVALID_VERIFICATION_CODE';
export const MISSING_VERIFICATION_CODE = 'login/MISSING_VERIFICATION_CODE';
export const ACCEPT_TERMS_SUCCESS = 'login/ACCEPT_TERMS_SUCCESS';
export const RELOAD_CURRENT_USER = 'RELOAD_CURRENT_USER';
export const UPDATE_CURRENT_USER_SETTINGS = 'UPDATE_CURRENT_USER_SETTINGS';
