import { List, Map } from 'immutable';

import * as t from '~/constants/notifications';

const initialState = Map({
  notifications: List(),
  notificationsLoading: false,
  notificationsLoadingError: false,
  unreadNotificationsCount: 0,
});

export default function notifications(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case t.NOTIFICATIONS_LOADING_ATTEMPT:
      return state.set('notificationsLoadingError', null).set('notificationsLoading', true);
    case t.SET_NOTIFICATIONS:
      return state
        .set('notifications', action.notifications)
        .set('notificationsLoading', false)
        .set('notificationsLoadingError', null);
    case t.ADD_NOTIFICATIONS:
      return state.update('notifications', items => items.concat(action.notifications));
    case t.NOTIFICATIONS_LOADING_ERROR:
      return state
        .set('notifications', List())
        .set('notificationsLoadingError', true)
        .set('notificationsLoading', false);
    case t.ADD_NEW_NOTIFICATION:
      return state.set('notifications', state.get('notifications').unshift(action.notification));
    case t.INCREASE_UNREAD_NOTIFICATIONS_COUNT:
      return state.set('unreadNotificationsCount', state.get('unreadNotificationsCount') + 1);
    case t.SET_UNREAD_NOTIFICATIONS_COUNT:
      return state.set('unreadNotificationsCount', action.count);
    case t.CLEAR_UNREAD_NOTIFICATIONS_COUNT:
      return state.set('unreadNotificationsCount', 0);
    default:
      return state;
  }
}
