import { OpenFeature } from '@openfeature/react-sdk';
import { GoFeatureFlagWebProvider } from '@openfeature/go-feature-flag-web-provider';

const APP = 'corva-web-frontend';

const goFeatureFlagWebProvider = new GoFeatureFlagWebProvider({
  endpoint: process.env.REACT_APP_OPEN_FEATURE_ENDPOINT,
  apiKey: process.env.REACT_APP_OPEN_FEATURE_API_KEY,
  retryInitialDelay: 1000,
  retryDelayMultiplier: 2,
  maxRetries: 2,
});

OpenFeature.setContext({
  app: APP,
  admin: false,
  targetingKey: APP,
});

OpenFeature.setProvider(goFeatureFlagWebProvider);
