import Cartesian2D from 'echarts/lib/coord/cartesian/Cartesian2D';
import Axis2D from 'echarts/lib/coord/cartesian/Axis2D';

/**
 * 'baseAxis: boolean' property is added into Axis config.
 * It allows to choose manually direction for areaFill.
 */
(function addBaseAxisProperty(cartesian2D, axis2D) {
  // eslint-disable-next-line
  axis2D.prototype.isBaseAxis = function() {
    return this.model.get('baseAxis');
  };
  // eslint-disable-next-line
  cartesian2D.prototype.getBaseAxis = function() {
    const axes = this.getAxes();
    const baseAxisFromConfig = axes.find(axis => axis.isBaseAxis());
    return (
      baseAxisFromConfig ||
      this.getAxesByScale('ordinal')[0] ||
      this.getAxesByScale('time')[0] ||
      this.getAxis('x')
    );
  };
})(Cartesian2D, Axis2D);
