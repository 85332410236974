import { fromJS } from 'immutable';
import { segment } from '@corva/ui/constants';

const { SEGMENTS } = segment;

export const NAME = 'assets';

export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_ASSETS_ATTEMPT = 'LOAD_ASSETS_ATTEMPT';
export const LOAD_ASSETS_ERROR = 'LOAD_ASSETS_ERROR';
export const REMOVE_ASSET = 'REMOVE_ASSET';

export const ASSET_TYPE_NAMES = {
  rig: {
    name: 'rig',
    labelSingular: 'Rig',
    labelPlural: 'Rigs',
  },
  well: {
    name: 'well',
    labelSingular: 'Well',
    labelPlural: 'Wells',
  },
  program: {
    name: 'program',
    labelSingular: 'Program/BU',
    labelPlural: 'Programs/BUs',
  },
  frac_fleet: {
    name: 'frac_fleet',
    labelSingular: 'Frac Fleet',
    labelPlural: 'Frac Fleets',
  },
  pad: {
    name: 'pad',
    labelSingular: 'Pad',
    labelPlural: 'Pads',
  },
  drillout_unit: {
    name: 'drillout_unit',
    labelSingular: 'Drillout Unit',
    labelPlural: 'Drillout Units',
  },
  company: {
    name: 'company',
    labelSingular: 'Company',
    labelPlural: 'Companies',
  },
};

export const ASSET_TYPES_BY_SEGMENT = {
  // NOTE: please keep sorting order
  drilling: [ASSET_TYPE_NAMES.company, ASSET_TYPE_NAMES.program, ASSET_TYPE_NAMES.rig],
  completion: [
    ASSET_TYPE_NAMES.company,
    ASSET_TYPE_NAMES.program,
    ASSET_TYPE_NAMES.frac_fleet,
    ASSET_TYPE_NAMES.drillout_unit,
  ],
};

export const ASSET_TYPES = fromJS({
  rig: {
    name: 'rig',
    labelSingular: 'Rig',
    labelPlural: 'Rigs',
    parent_type: 'program',
    isResolvedToActiveChild: true,
  },
  well: {
    name: 'well',
    labelSingular: 'Well',
    labelPlural: 'Wells',
    parent_type: 'rig',
    isResolvedToActiveChild: false,
  },
  program: {
    name: 'program',
    labelSingular: 'Program/BU',
    labelPlural: 'Programs/BUs',
    isResolvedToActiveChild: false,
  },
  frac_fleet: {
    name: 'frac_fleet',
    labelSingular: 'Frac Fleet',
    labelPlural: 'Frac Fleets',
    isResolvedToActiveChild: false,
  },
  pad: {
    name: 'pad',
    labelSingular: 'Pad',
    labelPlural: 'Pads',
    isResolvedToActiveChild: false,
  },
  drillout_unit: {
    name: 'drillout_unit',
    labelSingular: 'Drillout Unit',
    labelPlural: 'Drillout Units',
    isResolvedToActiveChild: false,
  },
});

export const CORVA_WELLSECTIONS = [
  'Surface',
  'Intermediate',
  'Intermediate Vertical',
  'Intermediate Curve',
  'Intermediate 1',
  'Intermediate 2',
  'Production',
  'Production Vertical',
  'Production Curve',
  'Production Lateral',
  'Rig Move',
  'Rig Skid',
];

// NOTE: We define 3 types of rig and it will determine Rig Operations limits
export const LAND_RIG = 'land';
export const PLATFORM_RIG = 'platform';
export const FLOATING_RIG = 'floating';

export const RIG_CLASSIFICATIONS = [
  {
    label: 'Land',
    key: LAND_RIG,
  },
  {
    label: 'Platform',
    key: PLATFORM_RIG,
  },
  {
    label: 'Floating',
    key: FLOATING_RIG,
  },
];

export const FLOATING_VESSEL_SUBTYPE_DRILL_SHIP = 'drill_ship';
export const FLOATING_VESSEL_SUBTYPE_SEMI_SUBMERSIBLE_TYPE = 'semi_submersible';

export const FLOATING_VESSEL_CLASSIFICATION = [
  {
    label: 'Semi Submersible',
    key: FLOATING_VESSEL_SUBTYPE_SEMI_SUBMERSIBLE_TYPE,
  },
  {
    label: 'Drill Ship',
    key: FLOATING_VESSEL_SUBTYPE_DRILL_SHIP,
  },
];

export const ASSET_VISIBILITY = {
  VISIBLE_TO_ADMIN: 'visible_to_admin',
  VISIBLE: 'visible',
};

export const WELL_STATES = [
  {
    label: 'Planned',
    value: 'planned',
    segment: SEGMENTS.DRILLING,
  },
  {
    label: 'Drilling',
    value: 'drilling',
    segment: SEGMENTS.DRILLING,
  },
  {
    label: 'Drilled not Completed (DUC)',
    value: 'drilled_not_completed',
    segment: SEGMENTS.DRILLING,
  },
  {
    label: 'Completions',
    value: 'completions',
    segment: SEGMENTS.COMPLETION,
  },
  {
    label: 'Drillouts',
    value: 'drillouts',
    segment: SEGMENTS.COMPLETION,
  },
  {
    label: 'Producing',
    value: 'producing',
    segment: SEGMENTS.COMPLETION,
  },
  {
    label: 'Completed',
    value: 'completed',
    segment: SEGMENTS.COMPLETION,
  },
];

export const DRILLING_WELL_STATES = ['planned', 'drilling', 'drilled_not_completed'] as const;
export const COMPLETION_WELL_STATES = ['completions', 'producing', 'completed'] as const;
export const DRILLINT_WELL_STATES_SET = new Set(DRILLING_WELL_STATES);
export const COMPLETION_WELL_STATES_SET = new Set(COMPLETION_WELL_STATES);
