import { BEAMER_ENABLED } from '~/externalServices/serviceFlags';

const BEAMER_MUTED_FEATURES_KEY = 'BEAMER_MUTED_FEATURES_KEY';

export const BEAMER_STATES = {
  // NOTE: animation runs in active state
  active: 'active',
  // NOTE: timeout state is similar to active, but show colored icon instead of looping gif
  timeout: 'timeout',
  // NOTE: only show white icon
  inactive: 'inactive',
};

function disableBeamerTrigger() {
  localStorage.setItem(BEAMER_MUTED_FEATURES_KEY, 0);
  window[Symbol.for('beamerTrigger')]?.setBeamerState?.(BEAMER_STATES.inactive);
}

export function initializeBeamer(currentUser) {
  if (!BEAMER_ENABLED) return;
  window.beamer_config = {
    product_id: 'krfGAkiO30407',
    counter: false,
    user_firstname: currentUser.get('first_name'),
    user_lastname: currentUser.get('last_name'),
    user_email: currentUser.get('email'),
    callback(featuresNumber) {
      const previouslyShownFeaturesNumber = +(localStorage.getItem(BEAMER_MUTED_FEATURES_KEY) || 0);
      const setBeamerState = window[Symbol.for('beamerTrigger')]?.setBeamerState;
      if (setBeamerState) {
        if (featuresNumber > 0 && featuresNumber === previouslyShownFeaturesNumber)
          setBeamerState(BEAMER_STATES.timeout);

        if (featuresNumber > previouslyShownFeaturesNumber) {
          setBeamerState(BEAMER_STATES.active);
          localStorage.setItem(BEAMER_MUTED_FEATURES_KEY, featuresNumber);
        }
      }
    },
    onopen: disableBeamerTrigger,
    onclose: disableBeamerTrigger,
  };
}
