import { AnyAction, combineReducers } from 'redux';
// @ts-ignore
import { routerReducer } from 'react-router-redux';

import { alerts as alertsConstants } from '@corva/ui/constants';
import * as feedItemsConstants from '@corva/ui/constants/feed';
import * as assetsConstants from '~/constants/assets';
import * as mainConstants from '~/constants/main';
import * as loginConstants from '~/constants/login';
import * as tooltipsConstants from '~/constants/tooltips';
import * as streamsConstants from '~/constants/streams';
import * as subscriptionsConstants from '~/constants/subscriptions';
import * as incidentsConstants from '~/constants/incidents';
import * as notificationsConstants from '~/constants/notifications';
import { NAME as dashboards } from '~/constants/dashboards';
import { NAME as dashboardFilters } from '~/constants/dashboardFilters';

import alertsReducer from '~/reducers/alerts';
import assetsReducer from '~/reducers/assets';
import dashboardsReducer from '~/reducers/dashboards';
import feedItemsReducer from '~/reducers/feed';
import mainReducer from '~/reducers/main';
import loginReducer from '~/reducers/login';
import tooltipsReducer from '~/reducers/tooltips';
import streamsReducer from '~/reducers/streams';
import subscriptionsReducer from '~/reducers/subscriptions';
import incidentsReducer from '~/reducers/incidents';
import streamConnectorReducer from '../screens/App/screens/Main/screens/Streams/shared/components/StreamConnector/reducer.js';
import notificationsReducer from '~/reducers/notifications';
import dashboardFiltersReducer from '~/reducers/dashboardFilters';

const appReducer = combineReducers({
  routing: routerReducer,
  [alertsConstants.NAME]: alertsReducer,
  [assetsConstants.NAME]: assetsReducer,
  [feedItemsConstants.NAME]: feedItemsReducer,
  [mainConstants.NAME]: mainReducer,
  [loginConstants.NAME]: loginReducer,
  [tooltipsConstants.NAME]: tooltipsReducer,
  [streamsConstants.NAME]: streamsReducer,
  [subscriptionsConstants.NAME]: subscriptionsReducer,
  [incidentsConstants.NAME]: incidentsReducer,
  [notificationsConstants.NAME]: notificationsReducer,
  streamConnector: streamConnectorReducer,
  [dashboards]: dashboardsReducer,
  [dashboardFilters]: dashboardFiltersReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  // NOTE: Reset Redux state except routing. Source is Redux creator's answer:
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992
  if (action.type === mainConstants.RESET_APP_STATE) {
    state = { routing: state.routing }; // eslint-disable-line no-param-reassign
  }

  return appReducer(state, action);
};

export default rootReducer;
