import { Map, List } from 'immutable';

import * as t from '~/constants/assets';
import { isResolvableAsset } from '~/selectors/assetUtils';

// The state for this reducer is an identity map of assets: asset id -> asset
const initialState = Map({
  assets: Map(),
  assetsLoading: false,
});

function updateAssets(initAssets, newAssets) {
  // NOTE: Convert asset ids to integer
  const convertedNewAssets = newAssets.map(asset => {
    let convertedAsset = asset.update('id', id => +id);

    if (asset.get('active_child')) {
      convertedAsset = convertedAsset.updateIn(['active_child', 'id'], id => +id);
    }

    if (asset.get('last_active_child')) {
      convertedAsset = convertedAsset.updateIn(['last_active_child', 'id'], id => +id);
    }

    return convertedAsset;
  });

  const resolvableAssets = convertedNewAssets.filter(isResolvableAsset);
  const activeChildren = resolvableAssets.reduce((children, asset) => {
    const activeChild = asset.get('active_child');
    if (activeChild) return children.push(activeChild);

    const lastActiveChild = asset.get('last_active_child');
    if (lastActiveChild) return children.push(lastActiveChild);

    return children;
  }, List());

  const newResolvedAssets = convertedNewAssets.concat(activeChildren);

  return newResolvedAssets.reduce(
    (result, asset) =>
      result.update(asset.get('id'), existingAsset =>
        existingAsset ? existingAsset.merge(asset) : asset
      ),
    initAssets
  );
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.LOAD_ASSETS_ATTEMPT:
      return state.set('assetsLoading', true);
    case t.LOAD_ASSETS:
      return state
        .set('assets', updateAssets(state.get('assets'), action.assets))
        .set('assetsLoading', false);
    case t.REMOVE_ASSET:
      return state.set(
        'assets',
        state.get('assets').filter(asset => asset.get('id') !== action.assetId)
      );
    case t.LOAD_ASSETS_ERROR:
      return state.set('assetsLoading', false);
    default:
      return state;
  }
};
