import { Map, OrderedMap } from 'immutable';

import * as t from '@corva/ui/constants/feed';

const initialState = Map({
  newFeedItems: OrderedMap(),
  feedItemsToUpdate: OrderedMap(),
  feedItemsToDelete: OrderedMap(),
});

function listToOrderedMapById(list) {
  const keyValuePairsArray = list.map(listItem => [listItem.get('id'), listItem]).toArray();

  return OrderedMap(keyValuePairsArray);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.ADD_NEW_FEED_ITEMS:
      return state.update('newFeedItems', newFeedItems =>
        newFeedItems.concat(listToOrderedMapById(action.newFeedItems))
      );
    case t.DELETE_NEW_FEED_ITEM:
      return state.deleteIn(['newFeedItems', action.newFeedItemId]);
    case t.CLEAR_NEW_FEED_ITEMS:
      return state.set('newFeedItems', OrderedMap());

    case t.ADD_FEED_ITEM_TO_UPDATE_LIST:
      return state.setIn(['feedItemsToUpdate', action.feedItem.get('id')], action.feedItem);
    case t.DELETE_FEED_ITEM_FROM_UPDATE_LIST:
      return state.deleteIn(['feedItemsToDelete', action.feedItemId]);

    case t.ADD_FEED_ITEM_TO_DELETE_LIST:
      return state.setIn(['feedItemsToDelete', action.feedItem.get('id')], action.feedItem);
    case t.DELETE_FEED_ITEM_FROM_DELETE_LIST:
      return state.deleteIn(['feedItemsToDelete', action.feedItemId]);
    default:
      return state;
  }
};
