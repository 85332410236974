export function configurePerson(user) {
  if (window.Rollbar) {
    window.Rollbar.configure({
      payload: {
        person: {
          id: user.get('id'),
          username: `${user.get('first_name')} ${user.get('last_name')}`,
          email: user.get('email'),
        },
      },
    });
  }
}
