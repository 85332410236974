export const isReportsPage = window.location.pathname.startsWith('/reports/');

// qa, staging, beta, prod
export const ROLLBAR_ENABLED = process.env.REACT_APP_ROLLBAR_ENABLED === 'true';
export const INTERCOM_ENABLED = process.env.REACT_APP_INTERCOM_ENABLED === 'true' && !isReportsPage;

// export const MAPBOX_ENABLED = process.env.REACT_APP_MAPBOX_ENABLED === 'true';
export const MAPBOX_ENABLED = true;

// qa, beta, prod
export const SNOWPLOW_ENABLED = process.env.REACT_APP_SNOWPLOW_ENABLED === 'true' && !isReportsPage;

// Production and beta environments only
export const MIXPANEL_ENABLED = process.env.REACT_APP_MIXPANEL_ENABLED === 'true' && !isReportsPage;
export const GOOGLE_ANALYTICS_ENABLED =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true' && !isReportsPage;

export const STATUSPAGE_ENABLED =
  process.env.REACT_APP_STATUSPAGE_ENABLED === 'true' && !isReportsPage;

// Only production
export const BEAMER_ENABLED = process.env.REACT_APP_BEAMER_ENABLED === 'true' && !isReportsPage;
export const WOOTRIC_ENABLED = process.env.REACT_APP_WOOTRIC_ENABLED === 'true' && !isReportsPage;

// other
export const REQUIRE_USER_CONSENT_FOR_TRACKING =
  process.env.REACT_APP_REQUIRE_USER_CONSENT_FOR_TRACKING === 'true';

// NOTE: temporary flag, will be deleted after app store migrates from app ids to app keys
export const IS_EU_ENVIRONMENT = window.location.host.includes('eu');
