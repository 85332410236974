import { getCompanyDomainFromEmail } from '~/utils/analytics';
import { INTERCOM_ENABLED } from '~/externalServices/serviceFlags';

const { REACT_APP_ENVIRONMENT } = process.env;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || 'g23togek';

export function openIntercom() {
  if (INTERCOM_ENABLED) {
    window.Intercom?.('show');
  }
}

export function stopIntercomSession() {
  if (INTERCOM_ENABLED) {
    window.Intercom?.('shutdown');
  }
}

export function startIntercomSession(user) {
  if (!INTERCOM_ENABLED) {
    return;
  }

  const userId = user.get('id');
  // User IDs are created sequentially, so there is a high likelihood
  // that IDs may be replicated across environments. Because of this,
  // Intercom IDs must be prefixed in lower environments so that that
  // Intercom will only associate a user's chat data to the correct user.
  //
  // EXAMPLE: if a new user is created in STAGING with the id 177, then
  // the new user will be able to see chat data for PROD user 177.
  //
  // SOLUTION: prefix IDs in any environment other than BETA and PROD
  const isPrefixRequired =
    REACT_APP_ENVIRONMENT !== 'beta' && REACT_APP_ENVIRONMENT !== 'production';
  const userIdWithPrefix = isPrefixRequired ? `${REACT_APP_ENVIRONMENT}_${userId}` : userId;

  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    email: user.get('email'),
    company: {
      id: user.getIn(['company', 'id']),
      name: user.getIn(['company', 'name']),
      domain: getCompanyDomainFromEmail(user.get('email')),
    },
    name: `${user.get('first_name')} ${user.get('last_name')}`,
    created_at: user.get('created_at'),
    user_id: userIdWithPrefix,
    hide_default_launcher: !user.getIn(['preference', 'show_intercom_icon']),
    user_hash: user.get('intercom_user_hash'),
  });
}

export function displayIntercomIcon(bool) {
  if (!INTERCOM_ENABLED || !window.Intercom) {
    return;
  }

  window.Intercom('update', {
    hide_default_launcher: !bool,
  });
}

export function initIntercom() {
  if (!INTERCOM_ENABLED) {
    return;
  }

  /*  eslint-disable */
  // NOTE: this is provided by Intercom API script
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
  /*  eslint-enable */
}
