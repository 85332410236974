import { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';

const muiStyles = {
  tooltip: {
    maxWidth: 'none',
    // NOTE: Styles to look like react-tooltip
    fontSize: 13,
    backgroundColor: '#222',
    whiteSpace: 'pre-line',
  },
  // NOTE: Arrow by MUI docs example
  arrowPopper: {
    '&[x-placement*="bottom"] $arrowArrow': {
      top: 0,
      left: '50%',
      // transform: 'translate(-50%, 0)',
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: 'transparent transparent #222 transparent',
      },
    },
    '&[x-placement*="top"] $arrowArrow': {
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: '#222 transparent transparent transparent',
      },
    },
    '&[x-placement*="right"] $arrowArrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: 'transparent #222 transparent transparent',
      },
    },
    '&[x-placement*="left"] $arrowArrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: 'transparent transparent transparent #222',
      },
    },
  },
  arrowArrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
};

class Tooltip extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      arrowRef: null,
      open: false,
    };
  }

  handleTooltipClose = () => {
    this.setState({ open: false }, this.props.onClose);
  };

  handleTooltipOpen = e => {
    this.setState(
      {
        open: !this.props.visibilityCondition || this.props.visibilityCondition(e),
      },
      this.props.onOpen
    );
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  render() {
    const {
      children,
      classes,
      open,
      title,
      disableFocusListener,
      disableHoverListener,
      disableTouchListener,
      enterDelay,
      enterTouchDelay,
      id,
      interactive,
      leaveDelay,
      leaveTouchDelay,
      placement,
      className,
    } = this.props;

    return (
      <MuiTooltip
        title={
          <>
            {title}
            <span className={classes.arrowArrow} ref={this.handleArrowRef} />
          </>
        }
        classes={{
          tooltip: classNames(classes.tooltip, className),
          popper: classes.arrowPopper,
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(this.state.arrowRef),
                element: this.state.arrowRef,
              },
            },
          },
        }}
        id={id}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
        enterDelay={enterDelay}
        enterTouchDelay={enterTouchDelay}
        interactive={interactive}
        leaveDelay={leaveDelay}
        leaveTouchDelay={leaveTouchDelay}
        placement={placement}
        onClose={this.handleTooltipClose}
        onOpen={this.handleTooltipOpen}
        open={open || this.state.open}
      >
        {children}
      </MuiTooltip>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,

  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  open: PropTypes.bool,
  id: PropTypes.string,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  enterDelay: PropTypes.number,
  enterTouchDelay: PropTypes.number,
  interactive: PropTypes.bool,
  leaveDelay: PropTypes.number,
  leaveTouchDelay: PropTypes.number,
  placement: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  visibilityCondition: PropTypes.func,
  className: PropTypes.string,

  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Tooltip.defaultProps = {
  open: undefined,
  id: undefined,
  disableFocusListener: undefined,
  disableHoverListener: undefined,
  disableTouchListener: undefined,
  enterDelay: undefined,
  enterTouchDelay: undefined,
  interactive: undefined,
  leaveDelay: undefined,
  leaveTouchDelay: undefined,
  placement: undefined,
  onOpen: undefined,
  onClose: undefined,
  visibilityCondition: undefined,
  className: '',
};

export default withStyles(muiStyles)(Tooltip);
