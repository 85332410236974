import { getAllUnitTypes, getUniqueUnitsByType, getUnitsByType } from '@corva/ui/utils';
import { SEGMENTS } from '@corva/ui/constants/segment';
import { STREAM_SOURCE_TYPES } from '~/constants/streamSourceType';
import { STREAM_LOG_TYPES, UNIT_TYPE_TO_MEASURE_MAP } from '~/constants/streams';

// name for unitless channels not standardized so mnemonics could use unitless or Unitless,
// even though through UI user can select only unitless we allow to save Unitless
export const UNITLESS = 'unitless';
export const WITSML_UNITLESS_VARIATION = 'Unitless';

export const UNITLESS_NAMES_ENUM = {
  [UNITLESS]: true,
  [WITSML_UNITLESS_VARIATION]: true,
};

export const ANIMATION_SWITCH_CHANNEL_SPEED_MS = 400;
export const COLLAPSE_SPEED_MS = 400;

export const TASK_LIST_COLUMNS = {
  provider: 'corva',
  app_key: 'corva.stream-connector',
  operation: 'list_columns_by_stream_id',
  data_source: 'core',
};

const UNIT_TYPES = getAllUnitTypes();

export const UNITS_GROUPED_BY_MEASURES = UNIT_TYPES.reduce(
  (acc, { type: unitType, display: unitTypeDisplay }) => ({
    ...acc,
    [UNIT_TYPE_TO_MEASURE_MAP[unitType]]: getUniqueUnitsByType(unitType).map(unitItem => ({
      groupType: unitTypeDisplay,
      value: unitItem.abbr,
      label: unitItem.display,
    })),
  }),
  {}
);

export const UNITS_LIST = [...Object.values(UNITS_GROUPED_BY_MEASURES).flat()];

export const UNITS_SET_GROUPED_BY_TYPES = UNIT_TYPES.reduce(
  (acc, { type: unitType }) => ({
    ...acc,
    [UNIT_TYPE_TO_MEASURE_MAP[unitType]]: new Set(getUnitsByType(unitType).map(({ abbr }) => abbr)),
  }),
  {}
);

export const AVAILABLE_UNITS_SET = new Set(
  Object.values(UNITS_SET_GROUPED_BY_TYPES).flatMap(set => Array.from(set))
);

// All fields used in the channel should be specified
export const CHANNEL_FIELDS = ['column', 'source_unit', 'is_primary', 'backup_channels'];

export const VIRTUALIZATION_ENTITY_TYPE = {
  active: 'Active',
  backup: 'Backup',
  divider: 'Divider',
};

export const ROW_HEIGHTS = {
  divider: 40,
  header: 73,
  channelWithError: 80,
  channelWithoutError: 65,
};

export const emptyChannel = {
  column: '',
  source_unit: '',
};

export const emptyBackupChannel = {
  column: '',
  source_unit: '',
};

export const CHANNEL_TYPE = {
  Default: 'Default',
  Custom: 'Custom',
};

export const drillingTimeStreamConfig = {
  streamSegment: SEGMENTS.DRILLING,
  streamSourceType: STREAM_SOURCE_TYPES.DRILLING,
  streamLogType: STREAM_LOG_TYPES.time,
};

/**
 * Ability to test old column mapper(on dev, qa, beta) by adding the testOldDrillingColumnMapper key to local storage;
 * TODO: remove variable and old column mapper
 */
export const DISPLAY_NEW_DRILLING_COLUMN_MAPPER = !localStorage.getItem(
  'testOldDrillingColumnMapper'
);

export const ERRORS_MESSAGES = {
  isRequired: 'Must be not empty',
  isDuplicated: 'Must be unique',
  nameDuplicated: 'Parameter already exists',
  sourceUnitInvalid: 'Invalid unit',
  sourceUnitIncompatible: 'Unit type mismatch',
  requiredChannelsAreMissing: 'Some of the required channels are missing',
  invalidMappingConfig: 'Invalid mapping configuration. Please check all fields with errors',
};

export const CLIPBOARD_ERRORS_MESSAGES = {
  missingPermission:
    'The browser has blocked Clipboard permission. Please enable it to paste the JSON mapping',
  isNotJSON: 'Failed to parse the JSON',
  invalidMapping: 'Failed to parse mapping in JSON',
};
