import { UPDATE_INCIDENTS } from '~/constants/incidents';

const initialState = { incidents: [], updatedAt: null };

export default function incidentsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_INCIDENTS: {
      return {
        ...state,
        incidents: action.rawIncidents,
        updatedAt: String(action.updatedAt?.getTime()),
      };
    }
    default:
      return state;
  }
}
