import * as queryString from 'query-string';
import { fromJS, Map } from 'immutable';
import { identity } from 'lodash';
import {
  attachHTTPHeaders,
  getAppKeyFromStackTrace,
  getAuthorizationHeaders,
} from '@corva/ui/clients/utils';
import { showErrorNotification } from '~/actions/notificationToasts';
import { logout } from '~/actions/login';

import { DISPLAY_HTTP_MESSAGES } from './httpMessages';

const HTTP_ERROR_MESSAGES = {
  400: 'Request Failed',
  403: 'No Permission',
  404: 'Not Found',
  409: 'Request Failed',
};

const TOS_ERROR_MESSAGE = 'Please accept the latest terms of service';
const LOCKED_ERROR_MESSAGE = 'Account has been locked';
const INVALID_VERIFICATION_CODE_MESSAGE = 'Invalid verification code';
const MISSING_VERIFICATION_CODE_MESSAGE = 'Missing verification code';

const HTTP_METHODS_TO_SHOW_ERROR_NOTIFICATIONS = [
  // NOTE: Do not show error notifications for GET method. Because users may request a deleted item
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
];

export class APIException {
  constructor(response, json) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.message = json.message || 'No Message';
    this.errorBody = json;
  }

  isAuthenticationProblem() {
    return this.status === 401;
  }

  isTermsProblem() {
    // FIXME: this.message === TOS_ERROR_MESSAGE is a hack. Back-end team should come
    // with another solution.
    return this.status === 401 && this.message === TOS_ERROR_MESSAGE;
  }

  isLoginProblem() {
    return this.status === 401 && this.errorBody?.errors?.login?.length > 0;
  }

  isLockedAccountProblem() {
    return this.status === 401 && this.errorBody?.errors?.login?.includes(LOCKED_ERROR_MESSAGE);
  }

  isInvalidVerificationCode() {
    return (
      this.status === 401 &&
      this.errorBody?.errors?.login?.includes(INVALID_VERIFICATION_CODE_MESSAGE)
    );
  }

  isMissingVerificationCode() {
    return (
      this.status === 401 &&
      this.errorBody?.errors?.identity?.includes(MISSING_VERIFICATION_CODE_MESSAGE)
    );
  }
}

const parameters = queryString.parse(window.location.search);
export const baseUrl =
  parameters.api_url || process.env.REACT_APP_API_URL || 'http://api.local.corva.ai';

const AUTH_HEADERS = getAuthorizationHeaders();
const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  ...AUTH_HEADERS,
};

export function handleAuthenticationProblem(e) {
  if (e.isAuthenticationProblem?.() && !e.isTermsProblem() && !e.isLoginProblem()) {
    window.reduxStore.dispatch(logout());
  }
}

function errorHandlerDecorator(requestCoreFunc) {
  return async function errorHandler(path, config, overrides) {
    const response = await requestCoreFunc(path, config, overrides);

    const { status } = response;

    if (response.ok) {
      return status === 204 // NOTE: No content
        ? null
        : response;
    }

    // NOTE: Handle Error response logic
    let json;
    try {
      json = await response.json();
    } catch (e) {
      json = {};
    }
    const requestMethod = config.method;
    const isMethodToShowNotification =
      !overrides.hideNotification &&
      HTTP_METHODS_TO_SHOW_ERROR_NOTIFICATIONS.includes(requestMethod);

    const httpErrorMessage = DISPLAY_HTTP_MESSAGES[json.message] || HTTP_ERROR_MESSAGES[status];

    if (isMethodToShowNotification && httpErrorMessage) {
      window.reduxStore.dispatch(showErrorNotification(httpErrorMessage));
    }

    const exception = new APIException(response, json);
    handleAuthenticationProblem(exception);

    throw exception;
  };
}

const V1_ASSETS_REGEXP = /^\/v1\/assets.*/;

async function requestCore(path, config = {}, overrides = {}) {
  const { getBaseUrl = identity, appKey } = overrides;
  // temporary enable cache for the heaviest request
  if (V1_ASSETS_REGEXP.test(path)) {
    config.cache = 'reload'; // eslint-disable-line no-param-reassign
  }
  config.credentials = 'include';

  return fetch(
    `${getBaseUrl(baseUrl)}${path}`,
    attachHTTPHeaders({ config, appKey, ...AUTH_HEADERS })
  );
}

const request = errorHandlerDecorator(requestCore);

export async function postWithHeaders(path, content, params = {}) {
  const { isImmutable = true, getBaseUrl, hideNotification = false, ...queryParams } = params;
  const qry = queryString.stringify(queryParams, { arrayFormat: 'bracket' });

  const response = await request(
    `${path}${qry ? '?' : ''}${qry}`,
    {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify(content),
    },
    { getBaseUrl, appKey: getAppKeyFromStackTrace(), hideNotification }
  );

  return isImmutable
    ? Map({
        data: fromJS(await response?.json()),
        headers: fromJS(response?.headers),
      })
    : {
        data: await response?.json(),
        headers: response?.headers,
      };
}

export async function post(path, content, params = {}) {
  const { isImmutable = true } = params;

  const dataAndHeaders = await postWithHeaders(path, content, params);

  return isImmutable ? dataAndHeaders.get('data') : dataAndHeaders.data;
}

export async function sendFormData(path, data, queryParams = {}, options = {}) {
  const query = queryString.stringify(queryParams);
  const { method = 'POST' } = options;
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(arrayValueEntity => {
        formData.append(key, arrayValueEntity);
      });
    } else {
      formData.append(key, value);
    }
  });

  const response = await request(
    query ? `${path}?${query}` : path,
    {
      method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    },
    { appKey: getAppKeyFromStackTrace() }
  );

  return response && response.json();
}

export async function getWithHeaders(path, queryParams = {}, otherParams = {}) {
  const {
    isImmutable = true,
    getBaseUrl,
    appKey,
    signal,
    extraHeaders,
    queryArrayFormat,
    responseType = 'json', // Can be 'json' | 'blob'. If `isImmutable = true`, it forces 'json'
  } = otherParams;
  const qry = queryString.stringify(queryParams, { arrayFormat: queryArrayFormat || 'bracket' });
  const response = await request(
    `${path}${qry ? '?' : ''}${qry}`,
    {
      method: 'GET',
      ...(extraHeaders ? { headers: extraHeaders } : {}),
      signal,
    },
    { getBaseUrl, appKey: appKey || getAppKeyFromStackTrace() }
  );

  if (isImmutable) {
    return Map({
      data: response && fromJS(await response.json()),
      headers: response && fromJS(response.headers),
    });
  }

  return {
    data: response && (await response[responseType]?.()),
    headers: response && response.headers,
  };
}

export async function get(path, queryParams = {}, otherParams = {}) {
  const { isImmutable = true } = otherParams;

  const dataAndHeaders = await getWithHeaders(path, queryParams, {
    ...otherParams,
    appKey: otherParams.appKey || getAppKeyFromStackTrace(),
  });
  return isImmutable ? dataAndHeaders.get('data') : dataAndHeaders.data;
}

export async function getFile(path, queryParams = {}) {
  const qry = queryString.stringify(queryParams);
  const response = await request(
    `${path}${qry ? '?' : ''}${qry}`,
    { method: 'GET' },
    { appKey: getAppKeyFromStackTrace() }
  );

  return response && response.blob();
}

export async function put(path, content, queryParams = {}, params = {}) {
  const { isImmutable = true, hideNotification = false, getBaseUrl } = params;
  const qry = queryString.stringify(queryParams);
  const response = await request(
    `${path}${qry ? '?' : ''}${qry}`,
    {
      method: 'PUT',
      headers: JSON_HEADERS,
      body: JSON.stringify(content),
    },
    { getBaseUrl, appKey: getAppKeyFromStackTrace(), hideNotification }
  );

  if (!isImmutable) {
    return response.json();
  }

  return response && fromJS(await response.json());
}

export async function patch(path, content, queryParams = {}, params = {}) {
  const { isImmutable = true, hideNotification = false, getBaseUrl } = params;
  const qry = queryString.stringify(queryParams, { arrayFormat: 'bracket' });

  const response = await request(
    `${path}${qry ? '?' : ''}${qry}`,
    {
      method: 'PATCH',
      headers: JSON_HEADERS,
      body: JSON.stringify(content),
    },
    {
      getBaseUrl,
      appKey: getAppKeyFromStackTrace(),
      hideNotification,
    }
  );

  if (!isImmutable) {
    return response.json();
  }

  return response && fromJS(await response.json());
}

export async function del(path, queryParams = {}, otherParams = {}) {
  const { isImmutable = true, getBaseUrl } = otherParams;
  const query = queryString.stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await request(
    query ? `${path}?${query}` : path,
    {
      method: 'DELETE',
      headers: JSON_HEADERS,
    },
    { getBaseUrl, appKey: getAppKeyFromStackTrace() }
  );

  if (!isImmutable) {
    return response.json();
  }

  return response && fromJS(await response.json());
}
