import { IndexRedirect, Route, IndexRoute } from 'react-router';
import Loadable from 'react-loadable';

import { isNativeDetected } from '@corva/ui/utils/mobileDetect';
import { DISPLAY_NEW_DRILLING_COLUMN_MAPPER } from '../screens/App/shared/components/ColumnMapper/constants';
import { isProdEnv } from '~/utils/env';
import * as nativeMessages from '~/utils/nativeMessages';
import { asyncRouteLoadableOptions } from '~/utils/loadable';
import withPageLogger from '~/utils/withPageLogger';
import { loginCheck } from '~/actions/login';
import { currentUserSelector, isImpersonatingSelector } from '../selectors/login';

const app = Loadable(
  asyncRouteLoadableOptions({
    loader: () => import(/* webpackChunkName: "app" */ '../screens/App'),
  })
);

const loginMain = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "loginMain" */ '../screens/App/screens/Login/screens/Auth'),
  })
);

const loginLayout = Loadable(
  asyncRouteLoadableOptions({
    loader: () => import(/* webpackChunkName: "loginLayout" */ '../screens/App/screens/Login'),
  })
);

const loginUserWelcome = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "loginUserWelcome" */ '../screens/App/screens/Login/screens/UserWelcome'
      ),
  })
);

const loginResetPassword = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "loginResetPassword" */ '../screens/App/screens/Login/screens/ResetPassword'
      ),
  })
);

const loginOAuthCallback = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "loginOAuthCallback" */ '../screens/App/screens/Login/screens/OAuthCallback'
      ),
  })
);

const loginHelp = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "loginHelp" */ '../screens/App/screens/Login/screens/Help'),
  })
);

const loginForgotPassword = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "loginForgotPassword" */ '../screens/App/screens/Login/screens/ForgotPassword'
      ),
  })
);

const loginTermsOfService = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "loginTermsOfService" */ '../screens/App/screens/Login/screens/TermsOfService'
      ),
  })
);

const alertsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertsPage" */ '../screens/App/screens/Main/screens/Alerts/screens/AlertsPageNew'
      ),
  })
);

const alertPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertPage" */ '../screens/App/screens/Main/screens/Alerts/screens/AlertPage'
      ),
  })
);

const alertDefinitionsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertDefinitionsPage" */ '../screens/App/screens/Main/screens/Alerts/screens/AlertDefinitionsPageNew'
      ),
  })
);

const alertAddDefinitionPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertAddDefinitionPage" */ '../screens/App/screens/Main/screens/Alerts/screens/AddAlertDefinitionPage'
      ),
  })
);

const alertEditDefinitionPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertEditDefinitionPage" */ '../screens/App/screens/Main/screens/Alerts/screens/EditAlertDefinitionPage'
      ),
  })
);

const alertDashboardPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "alertDashboardPage" */ '../screens/App/screens/Main/screens/Alerts/screens/AlertDashboardPage'
      ),
  })
);

const feedPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "feedPage" */ '../screens/App/screens/Main/screens/Feed/screens/FeedPage'
      ),
  })
);

const fusionIssuesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "fusionIssuesPage" */ '../screens/App/screens/Main/screens/FusionIssues'
      ),
  })
);

const wellHubRedirector = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "wellHubRedirector" */ '../screens/App/screens/Main/screens/Assets/screens/WellHubRedirector'
      ),
  })
);

const assetPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "assetPage" */ '../screens/App/screens/Main/screens/Assets/screens/AssetPage'
      ),
  })
);

const padDashboardPageV2 = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "isde padDashboardPageV2" */ '../screens/App/screens/Main/screens/Assets/screens/AssetListPageV2/components/Pad/PadDashboard'
      ),
  })
);

// ToDo: It will replace pad dashboard v2 after https://corvaqa.atlassian.net/browse/CMPL-793 is done
const padDashboardPageV3 = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "padDashboardPageV3" */ '../screens/App/screens/Main/screens/Assets/screens/AssetListPageV2/components/Pad/PadDashboardNew'
      ),
  })
);

const rigPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "rigPage" */ '../screens/App/screens/Main/screens/Assets/screens/RigPage'
      ),
  })
);

const assetDashboardReport = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "assetDashboardReport" */ '../screens/App/screens/Main/screens/AssetDashboardReport'
      ),
  })
);

const assetListPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "assetListPage" */ '../screens/App/screens/Main/screens/Assets/screens'
      ),
  })
);

const companySettings = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "companySettings" */ '../screens/App/screens/Main/screens/CompanySettings'
      ),
  })
);

const companySettingsV2 = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "companySettingsV2" */ '../screens/App/screens/Main/screens/CompanySettingsV2'
      ),
  })
);

const companyGoalSettings = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "companyGoalSettings" */ '../screens/App/screens/Main/screens/CompanyGoalSettings'
      ),
  })
);

const newCompanyGoalSettings = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "newCompanyGoalSettings" */ '../screens/App/screens/Main/screens/NewCompanyGoalSettings'
      ),
  })
);

const dashboardReport = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "dashboardReport" */ '../screens/App/screens/Main/screens/DashboardReport'
      ),
  })
);

const dashboardsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "dashboardsPage" */ '../screens/App/screens/Main/screens/Dashboards/screens/DashboardsPage'
      ),
  })
);

const dashboardsPageWrapper = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "dashboardsPageWrapper" */ '../screens/App/screens/Main/screens/Dashboards/screens/DashboardsPage/components/DashboardsPageWrapper'
      ),
  })
);

const wellInfoPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "wellInfoPage" */ '../screens/App/screens/Main/screens/Dashboards/screens/WellInfoPage'
      ),
  })
);

const workflowPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "workflowPage" */ '../screens/App/screens/Main/screens/Dashboards/screens/WorkflowPage'
      ),
  })
);

const dashboardAppPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "dashboardAppPage" */ '../screens/App/screens/Main/screens/Dashboards/screens/AppPage'
      ),
  })
);

const userProfile = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "userProfile" */ '../screens/App/screens/Main/screens/UserProfile'
      ),
  })
);

const unitSettings = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "unitSettings" */ '../screens/App/screens/Main/screens/UnitSettings'
      ),
  })
);

const unitSettingsV2 = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "unitSettingsV2" */ '../screens/App/screens/Main/screens/UnitSettingsV2'
      ),
  })
);

const userPermissions = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "userPermissions" */ '../screens/App/screens/Main/screens/Permissions/UserPermissions'
      ),
  })
);

const groupPermissionsBlacklist = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "groupPermissionsBlacklist" */ '../screens/App/screens/Main/screens/Permissions/Blacklist'
      ),
  })
);

const groups = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "groups" */ '../screens/App/screens/Main/screens/Permissions/Groups'
      ),
  })
);

const groupUsers = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "groupUsers" */ '../screens/App/screens/Main/screens/Permissions/GroupUsers'
      ),
  })
);

const groupPermissions = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "groupPermissions" */ '../screens/App/screens/Main/screens/Permissions/GroupPermissions'
      ),
  })
);

const subscriptions = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "subscriptions" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Subscriptions'
      ),
  })
);

const subscription = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "subscription" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Subscription'
      ),
  })
);

const tiers = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "tiers" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Tiers'
      ),
  })
);

const tier = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "tier" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Tier'
      ),
  })
);

const purchases = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "purchases" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Purchases'
      ),
  })
);

const purchase = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "purchase" */ '../screens/App/screens/Main/screens/TiersAndSubscriptions/Purchase'
      ),
  })
);

const usersList = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "usersList" */ '../screens/App/screens/Main/screens/UsersList'),
  })
);

const apiKeys = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "apiKeys" */ '../screens/App/screens/Main/screens/ApiKeys'),
  })
);

const profilesList = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "profilesList" */ '../screens/App/screens/Main/screens/ProfilesList'
      ),
  })
);

const reportUnsubscribe = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "reportUnsubscribe" */ '../screens/App/screens/Login/screens/ReportUnsubscribe'
      ),
  })
);

const newStreamsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "newStreamsPage" */ '../screens/App/screens/Main/screens/Streams/screens/NewStreamsPage'
      ),
  })
);

const thresholdsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "thresholds" */ '../screens/App/screens/Main/screens/Thresholds'),
  })
);

const edrProvidersPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "edrProviders" */ '../screens/App/screens/Main/screens/EDRProviders'
      ),
  })
);

const rerunsList = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "rerunsPage" */ '../screens/App/screens/Main/screens/WellPartialRerunsList'
      ),
  })
);

const columnMapperTemplatesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "columnMapperTemplatesPage" */ '../screens/App/screens/Main/screens/ColumnMapperTemplates/screens/TemplatesViewPage'
      ),
  })
);

const createColumnMapperTemplatePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "CreateTemplatePage" */ '../screens/App/screens/Main/screens/ColumnMapperTemplates/screens/CreateTemplatePage'
      ),
  })
);

const columnMapperTemplatePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "ColumnMapperTemplatePage" */ '../screens/App/screens/Main/screens/ColumnMapperTemplates/screens/TemplateViewPage'
      ),
  })
);

const streamDetailPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "streamDetailPage" */ '../screens/App/screens/Main/screens/Streams/screens/StreamDetailPage'
      ),
  })
);

const streamAppDetailPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "streamAppDetailPage" */ '../screens/App/screens/Main/screens/Streams/screens/AppDetailPage'
      ),
  })
);

const toolsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "companyTools" */ '../screens/App/screens/Main/screens/Tools'),
  })
);

const newAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "newAppStorePage" */ '../screens/AppStore/pages/NewAppStore/NewAppStore'
      ),
  })
);

const discoverAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "discoverAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Discover'
      ),
  })
);

const drillingAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "drillingAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Drilling'
      ),
  })
);

const completionAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "completionAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Completion'
      ),
  })
);

const geoscienceAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "geoscienceAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Geoscience'
      ),
  })
);

const sustainabilityAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "sustainabilityAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Sustainability'
      ),
  })
);

const appUsageAnalyticsAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "appUsageAnalyticsAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/AppUsageAnalytics'
      ),
  })
);

const myAppsAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "myAppsAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/MyApps'
      ),
  })
);

const searchAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "searchAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Search'
      ),
  })
);

const workflowsAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "workflowsAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Workflows'
      ),
  })
);

const articlesAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "articlesAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Articles/Articles'
      ),
  })
);

const articleAppStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "articleAppStorePage" */ '../screens/AppStore/pages/NewAppStore/pages/Articles/Article'
      ),
  })
);

const appStorePage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "appStorePage" */ '../screens/AppStore/pages/AppStore/AppStore'),
  })
);

const appStoreAppPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "appStoreAppPage" */ '../screens/AppStore/pages/AppPage/AppPage'),
  })
);

const appStoreWorkflowPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "appStoreWorkflowPage" */ '../screens/AppStore/pages/WorkflowPage/WorkflowPage'
      ),
  })
);

const notificationsPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "notificationsPage" */ '../screens/App/screens/Main/screens/Notifications/screens/NotificationsPage'
      ),
  })
);

const activityCodesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "activityCodesPage" */ '../screens/App/screens/Main/screens/ActivityCodes'
      ),
  })
);

const nptCodesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "nptCodesPage" */ '../screens/App/screens/Main/screens/NptCodes'),
  })
);

const lessonsLearnedCodesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "lessonsLearnedCodesPage" */ '../screens/App/screens/Main/screens/LessonsLearnedCodes'
      ),
  })
);

const mudInventoryCodesPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "mudInventoryCodesPage" */ '../screens/App/screens/Main/screens/MudInventoryCodes'
      ),
  })
);

const synergyPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "synergyPage" */ '../screens/App/screens/Main/screens/SynergyPage'
      ),
  })
);

const auditPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "audit" */ '../screens/App/screens/Main/screens/Audit'),
  })
);

const devCenterPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () => import(/* webpackChunkName: "devCenter" */ '../screens/DevCenter'),
  })
);

const streamXPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () => import(/* webpackChunkName: "streamX" */ '../screens/StreamX'),
  })
);

const streamXPadMappings = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "streamXPads" */ '../screens/StreamX/pages/StreamXPadMappingsPage/StreamXPadsPage'
      ),
  })
);

const streamXPadMapping = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "streamXPad" */ '../screens/StreamX/pages/StreamXPadPage/StreamXPadPage'
      ),
  })
);

const streamXDataSources = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "streamXDataSource" */ '../screens/StreamX/pages/StreamXDataSourcePage/StreamXDataSourceList'
      ),
  })
);

const streamXDataSource = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "streamXDataSource" */ '../screens/StreamX/pages/StreamXDataSourcePage/StreamXDataSource'
      ),
  })
);

const devCenterAppsDataProvider = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppsDataProvider" */ '../screens/DevCenter/Apps/components/AppsDataProvider'
      ),
  })
);

const devCenterApps = Loadable(
  asyncRouteLoadableOptions({
    loader: () => import(/* webpackChunkName: "devCenterApps" */ '../screens/DevCenter/Apps'),
  })
);

const devCenterAppDetails = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "devCenterAppDetails" */ '../screens/DevCenter/AppDetails'),
  })
);

const devCenterAppDetailsInfo = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsInfo" */ '../screens/DevCenter/AppDetails/AppInfoTab'
      ),
  })
);

const devCenterAppDetailsVersions = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsVersions" */ '../screens/DevCenter/AppDetails/tabs/AppVersionsTab'
      ),
  })
);

const devCenterAppDetailsDatasets = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsDatasets" */ '../screens/DevCenter/AppDetails/tabs/DatasetsTab'
      ),
  })
);

const devCenterAppDependencies = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDependencies" */ '../screens/DevCenter/AppDetails/tabs/AppDependenciesTab'
      ),
  })
);

const devCenterAppSettingsTab = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppSettingsTab" */ '../screens/DevCenter/AppDetails/tabs/SettingsTab'
      ),
  })
);

const devCenterAppDetailsLogging = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsLogsTab" */ '../screens/DevCenter/AppDetails/tabs/LogsTab'
      ),
  })
);

const devCenterAppDetailsMonitoring = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsMonitoring" */ '../screens/DevCenter/AppDetails/tabs/MonitoringTab/MonitoringTab'
      ),
  })
);

const devCenterAppDetailsAppRunsTab = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsAppRunsTab" */ '../screens/DevCenter/AppDetails/tabs/AppRunnerTab/pages/AppRuns'
      ),
  })
);

const devCenterAppDetailsAppRunTab = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppDetailsAppRunTab" */ '../screens/DevCenter/AppDetails/tabs/AppRunnerTab/pages/AppRun'
      ),
  })
);

const devCenterProvisioning = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterOwnProvisioningPage" */ '../screens/DevCenter/Provisioning'
      ),
  })
);

const devCenterAppsMonitoring = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppsMonitoringPage" */ '../screens/DevCenter/Monitoring'
      ),
  })
);

const devCenterAppRunnerPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "devCenterAppRunnerPage" */ '../screens/DevCenter/AppRunner'),
  })
);

const devCenterAppsBilling = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(/* webpackChunkName: "devCenterAppsBilling" */ '../screens/DevCenter/AppsBilling'),
  })
);

const devCenterAppStoreBuilder = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppStoreBuilder" */ '../screens/DevCenter/AppStoreBuilder'
      ),
  })
);

const devCenterAppStoreBuilderDiscover = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppStoreBuilderDiscover" */ '../screens/DevCenter/AppStoreBuilder/pages/AppStoreBuilderDiscover'
      ),
  })
);

const devCenterAppStoreBuilderDrilling = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppStoreBuilderDrilling" */ '../screens/DevCenter/AppStoreBuilder/pages/AppStoreBuilderDrilling'
      ),
  })
);

const devCenterAppStoreBuilderCompletion = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterAppStoreBuilderCompletion" */ '../screens/DevCenter/AppStoreBuilder/pages/AppStoreBuilderCompletion'
      ),
  })
);

const devCenterWorkflowsContextProvider = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterWorkflowsContextProvider" */ '../screens/DevCenter/Workflows/WorkflowsContextProvider'
      ),
  })
);

const devCenterWorkflows = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterWorkflowsPage" */ '../screens/DevCenter/Workflows/Workflows'
      ),
  })
);

const devCenterWorkflow = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterWorkflowPage" */ '../screens/DevCenter/Workflows/Workflow'
      ),
  })
);

const devCenterArticlesContextProvider = Loadable(
  asyncRouteLoadableOptions({
    isSimpleDefaultExport: true,
    loader: () =>
      import(
        /* webpackChunkName: "devCenterArticlesContextProvider" */ '../screens/DevCenter/Articles/shared/ArticlesContextProvider'
      ),
  })
);

const devCenterArticles = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterArticlesPage" */ '../screens/DevCenter/Articles/Articles'
      ),
  })
);

const devCenterArticle = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterArticlePage" */ '../screens/DevCenter/Articles/Article'
      ),
  })
);

const devCenterInternalProvisioning = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterOwnProvisioningPage" */ '../screens/DevCenter/Provisioning/InternalProvisioning'
      ),
  })
);

const devCenterInternalProvisioningDetails = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterOwnProvisioningPage" */ '../screens/DevCenter/Provisioning/InternalProvisioning/InternalProvisioningDetails'
      ),
  })
);

// const devCenterExternalProvisioning = Loadable(
//   asyncRouteLoadableOptions({
//     loader: () =>
//       import(
//         /* webpackChunkName: "devCenterExternalProvisioningPage" */ '../screens/DevCenter/Provisioning/ExternalProvisioning'
//       ),
//   })
// );

const devCenterPurchases = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterPurchases" */ '../screens/DevCenter/Provisioning/Purchases'
      ),
  })
);

const devCenterPurchaseDetails = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterPurchaseDetails" */ '../screens/DevCenter/Provisioning/Purchases/PurchaseDetails'
      ),
  })
);

const devCenterSubsriptions = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterSubsriptions" */ '../screens/DevCenter/Provisioning/Subscriptions'
      ),
  })
);

const devCenterSubscriptionDetails = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterSubsriptionDetails" */ '../screens/DevCenter/Provisioning/SubscriptionDetails'
      ),
  })
);

const devCenterPackageReviews = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterPackageReviewsPage" */ '../screens/DevCenter/Provisioning/PackageReviews'
      ),
  })
);

const devCenterPackageReview = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterPackageReviewPage" */ '../screens/DevCenter/Provisioning/PackageReview'
      ),
  })
);

const devCenterDatasetsExplorer = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterDatasetsExplorerPage" */ '../screens/DevCenter/DatasetsExplorer/screens/DatasetsExplorerPage'
      ),
  })
);

const devCenterDatasetDetails = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "devCenterDatasetDetailsPage" */ '../screens/DevCenter/DatasetsExplorer/screens/DatasetDetailsPage'
      ),
  })
);

const dataQualityPage = Loadable(
  asyncRouteLoadableOptions({
    loader: () =>
      import(
        /* webpackChunkName: "dataQualityPage" */ '../screens/App/screens/Main/screens/DataQuality'
      ),
  })
);

const ASSET_ROUTE_REG_EXP = new RegExp('^/assets/\\d+(/.*|)$');
const ALERT_ROUTE_REG_EXP = new RegExp('^/alerts/\\d+$');
const ALERT_DEFINITIONS_ROUTE_REG_EXP = new RegExp('^/alert-definitions$');
const CREATE_ALERT_DEFINITIONS_ROUTE_REG_EXP = new RegExp('^/alert-definitions/new$');
const ALERT_DEFINITION_ROUTE_REG_EXP = new RegExp('^/alert-definitions/\\d+$');
const FEED_ITEM_ROUTE_REG_EXP = new RegExp('^/feed/\\d+$');

const goToAssetPageInNativeApp = nextState => {
  const { assetId, slug } = nextState.params;
  nativeMessages.notifyGoToAsset(assetId, slug);
};

const goToAlertPageInNativeApp = nextState => {
  const { alertId } = nextState.params;
  nativeMessages.notifyGoToAlert(alertId);
};

const goToAlertDefinitionsInNativeApp = () => {
  nativeMessages.notifyGoToAlertDefinitions();
};

const goToCreateAlertDefinition = () => {
  nativeMessages.notifyGoToCreateAlertDefinition();
};

const goToAlertDefinitionInNativeApp = nextState => {
  const { alertDefinitionId } = nextState.params;
  nativeMessages.notifyGoToAlertDefinition(alertDefinitionId);
};

const goToFeedItemInNativeApp = nextState => {
  const { id } = nextState.params;
  nativeMessages.notifyGoToFeedItem(id);
};

const isNewRoute = (prevState, nextState, routeRegExp) => {
  const { pathname: prevPathname } = prevState.location;
  const { pathname: nextPathname } = nextState.location;
  return routeRegExp.test(nextPathname) && !routeRegExp.test(prevPathname);
};

const handleNativeRouteChange = (prevState, nextState, replace) => {
  let preventRouteChange = false;
  if (isNewRoute(prevState, nextState, ASSET_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToAssetPageInNativeApp(nextState);
  } else if (isNewRoute(prevState, nextState, ALERT_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToAlertPageInNativeApp(nextState);
  } else if (isNewRoute(prevState, nextState, ALERT_DEFINITIONS_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToAlertDefinitionsInNativeApp();
  } else if (isNewRoute(prevState, nextState, CREATE_ALERT_DEFINITIONS_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToCreateAlertDefinition();
  } else if (isNewRoute(prevState, nextState, ALERT_DEFINITION_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToAlertDefinitionInNativeApp(nextState);
  } else if (isNewRoute(prevState, nextState, FEED_ITEM_ROUTE_REG_EXP)) {
    preventRouteChange = true;
    goToFeedItemInNativeApp(nextState);
  }

  if (preventRouteChange) {
    replace(prevState.location); // NOTE: Do not go to alert route in Native App
  }
};

const LOGIN_ROUTE_REG_EXP = new RegExp('^/login.*$');

const handleRouteChange = (prevState, nextState, replace) => {
  const reduxState = window.reduxStore.getState();
  const currentUser = currentUserSelector(reduxState);
  const isImpersonating = isImpersonatingSelector(reduxState);

  if (!currentUser && !isImpersonating && !LOGIN_ROUTE_REG_EXP.test(nextState.location.pathname)) {
    replace('/login');
  } else if (isNativeDetected) {
    handleNativeRouteChange(prevState, nextState, replace);
  }
};

// Note: the third arg - callback, is a way to tell react-router that this is async
const handleRouteEnter = async (nextState, replace, callback) => {
  const { search, pathname } = nextState.location;
  window.reduxStore.dispatch(loginCheck({ search, pathname }, { onCheckFinish: callback }));
};

const routes = (
  <Route
    path="/"
    component={withPageLogger(app)}
    onChange={handleRouteChange}
    onEnter={handleRouteEnter}
  >
    <Route path="/dev-center" component={devCenterPage}>
      <IndexRedirect to="apps" />

      <Route path="apps" component={devCenterAppsDataProvider}>
        <IndexRoute component={devCenterApps} />
        <Route path=":appId" component={devCenterAppDetails}>
          <IndexRedirect to="info" />
          <Route path="info" component={devCenterAppDetailsInfo} />
          <Route path="versions" component={devCenterAppDetailsVersions} />
          <Route path="datasets" component={devCenterAppDetailsDatasets} />
          <Route path="dependencies" component={devCenterAppDependencies} />
          <Route path="logging" component={devCenterAppDetailsLogging} />
          <Route path="settings" component={devCenterAppSettingsTab} />
          <Route path="monitoring" component={devCenterAppDetailsMonitoring} />
          <Route path="runner" component={devCenterAppDetailsAppRunsTab} />
          <Route path="runner/:appRunId" component={devCenterAppDetailsAppRunTab} />
        </Route>
      </Route>
      <Route path="apps-monitoring" component={devCenterAppsMonitoring} />
      <Route path="app-runner" component={devCenterAppRunnerPage} />
      <Route path="workflows" component={devCenterWorkflowsContextProvider}>
        <IndexRoute component={devCenterWorkflows} />
        <Route path=":workflowId" exact component={devCenterWorkflow} />
      </Route>

      <Route path="articles" component={devCenterArticlesContextProvider}>
        <IndexRoute component={devCenterArticles} />
        <Route path=":articleId" exact component={devCenterArticle} />
      </Route>

      <Route path="apps-billing" component={devCenterAppsBilling} />

      <Route path="app-store-builder" component={devCenterAppStoreBuilder}>
        <IndexRedirect to="discover" />
        <Route path="discover" component={devCenterAppStoreBuilderDiscover} />
        <Route path="drilling" component={devCenterAppStoreBuilderDrilling} />
        <Route path="completion" component={devCenterAppStoreBuilderCompletion} />
      </Route>

      <Route path="provisioning" component={devCenterProvisioning}>
        <IndexRedirect to="internal" />
        <Route path="internal" component={devCenterInternalProvisioning} />
        <Route
          exact
          path="internal/:provisioningUUID"
          component={devCenterInternalProvisioningDetails}
        />

        {/* <Route path="external" component={devCenterExternalProvisioning} /> */}
        <Route path="package-reviews">
          <IndexRoute component={devCenterPackageReviews} />
          <Route exact path=":packageReviewId" component={devCenterPackageReview} />
        </Route>
        <Route path="purchases">
          <IndexRoute component={devCenterPurchases} />
          <Route exact path=":purchaseId" component={devCenterPurchaseDetails} />
        </Route>
        <Route path="subscriptions">
          <IndexRoute component={devCenterSubsriptions} />
          <Route exact path=":subscriptionUuid" component={devCenterSubscriptionDetails} />
        </Route>
      </Route>
      <Route path="datasets" component={devCenterDatasetsExplorer}>
        <Route path=":provider/:datasetName" component={devCenterDatasetDetails} />
      </Route>
    </Route>

    <Route path="stream-app-store" component={appStorePage} />
    <Route path="stream-app-store/app/:appId" component={appStoreAppPage} />
    <Route path="app-store/articles/:articleId" component={articleAppStorePage} />
    <Route path="app-store" component={newAppStorePage}>
      <IndexRedirect to="discover" />
      <Route path="discover" component={discoverAppStorePage} />
      <Route path="drilling" component={drillingAppStorePage} />
      <Route path="completion" component={completionAppStorePage} />
      <Route path="geoscience" component={geoscienceAppStorePage} />
      <Route path="sustainability" component={sustainabilityAppStorePage} />
      <Route path="app-usage-analytics" component={appUsageAnalyticsAppStorePage} />
      <Route path="my-apps" component={myAppsAppStorePage} />
      <Route path="workflows" component={workflowsAppStorePage} />
      <Route path="search" component={searchAppStorePage} />
      {!isProdEnv && <Route path="articles" component={articlesAppStorePage} />}
    </Route>

    <Route path="app-store/app/:appId" component={appStoreAppPage} />
    <Route path="app-store/workflow/:workflowId" component={appStoreWorkflowPage} />

    <Route path="/stream-x" component={streamXPage}>
      <IndexRedirect to="pad-mappings" />
      <Route path="pad-mappings" component={streamXPadMappings} />
      <Route path="pad-mappings/:companyId/:padMappingId" component={streamXPadMapping} />
      <Route path="data-sources" component={streamXDataSources} />
      <Route path="data-sources/:companyId/:dataSourceId" component={streamXDataSource} />
    </Route>

    <Route path="login" component={loginLayout}>
      <IndexRoute component={loginMain} />
      <Route path="welcome/:resetPasswordToken" component={loginUserWelcome} />
      <Route path="reset-password" component={loginResetPassword} />
      <Route path="report-unsubscribe/:reportId" component={reportUnsubscribe} />
      <Route path="oauth-callback" component={loginOAuthCallback} />
      <Route path="help" component={loginHelp} />
      <Route path="forgot-password" component={loginForgotPassword} />
      <Route path="terms-of-service" component={loginTermsOfService} />
      <Route path=":provider" component={loginMain} />
      <Route path=":provider/:connection" component={loginMain} />
    </Route>
    <Route path="alerts" component={alertsPage} />
    <Route path="alerts/:alertId" component={alertPage} />
    <Route path="alert-definitions" component={alertDefinitionsPage} />
    <Route path="alert-definitions/new" component={alertAddDefinitionPage} />
    <Route path="alert-definitions/:alertDefinitionId" component={alertEditDefinitionPage} />
    <Route
      path="alert-definitions/:alertDefinitionId/dashboard/:dashboardId"
      component={alertDashboardPage}
    />
    <Route path="feed">
      <IndexRoute component={feedPage} />
      <Route exact path=":id" component={feedPage} />
    </Route>
    <Route exact path="fusion/issues/:issueId" component={fusionIssuesPage} />
    <Route path="notifications" component={notificationsPage} />
    <Route path="reports">
      <Route path="dashboards/:slug" component={dashboardReport} />
      <Route path="assets/:assetId/:slug" component={assetDashboardReport} />
    </Route>
    <Route path="assets" component={assetListPage} />
    <Route path="new-assets" component={assetListPage} />
    <Route path="assets/types" component={dashboardsPageWrapper}>
      <Route exact path="pad/:id" component={isProdEnv ? padDashboardPageV2 : padDashboardPageV3} />
      <Route exact path="rig/:id" component={rigPage} />
    </Route>
    <Route path="assets">
      <Route path=":assetId" component={wellHubRedirector}>
        <IndexRoute component={assetPage} />
        <Route path=":slug(/:subSlug)" component={assetPage} />
      </Route>
    </Route>
    <Route exact path="data-quality-app" component={dataQualityPage} />
    <Route path="dashboards" component={dashboardsPageWrapper}>
      <IndexRoute component={dashboardsPage} />
      <Route path="well-info" component={wellInfoPage} />
      <Route path="well-info/:subSlug" component={wellInfoPage} />
      <Route path="workflows" component={workflowPage} />
      <Route path="workflows/:workflowId" component={workflowPage} />
      <Route path="apps" component={dashboardAppPage} />
      <Route path="apps/:appId" component={dashboardAppPage} />
      <Route path=":slug" component={dashboardsPage} />
    </Route>
    <Route path="onboarding">
      <IndexRoute component={dashboardsPage} />
    </Route>
    <Route path="config">
      <Route path="users">
        <IndexRoute component={usersList} />
        <Route path=":id" component={userProfile} />
        <Route path=":id/units" component={unitSettings} />
        <Route path=":id/unitsV2" component={unitSettingsV2} />
        <Route path=":id/permissions" component={userPermissions} />
      </Route>
      <Route path="api-keys" component={apiKeys} />

      <Route path="audit" component={auditPage} />
      <Route path="streams">
        <Route path=":streamId" component={streamDetailPage} />
        <Route path=":streamId/apps/:appId" component={streamAppDetailPage} />
      </Route>
      <Route path="new-streams">
        <IndexRoute component={newStreamsPage} />
      </Route>
      <Route path="thresholds">
        <IndexRoute component={thresholdsPage} />
      </Route>
      <Route path="edr-providers">
        <IndexRoute component={edrProvidersPage} />
      </Route>
      <Route path="well-partial-re-runs" component={rerunsList} />
      {DISPLAY_NEW_DRILLING_COLUMN_MAPPER && (
        <Route path="column-mapper-templates">
          <IndexRoute component={columnMapperTemplatesPage} />
          <Route path="create" component={createColumnMapperTemplatePage} />
          <Route path=":id" component={columnMapperTemplatePage} />
        </Route>
      )}
      <Route path="company" component={companySettings} />
      <Route path="companyV2" component={companySettingsV2} />
      <Route path="company-goals" component={companyGoalSettings} />
      <Route path="new-company-goals" component={newCompanyGoalSettings} />
      <Route path="profiles" component={profilesList} />
      <Route path="tools" component={toolsPage} />
      <Route path="activity-codes" component={activityCodesPage} />
      <Route path="npt-codes" component={nptCodesPage} />
      <Route path="lessons-learned-codes" component={lessonsLearnedCodesPage} />
      <Route path="mud-inventory-codes" component={mudInventoryCodesPage} />
      <Route path="synergy" component={synergyPage} />
      <Route path="groups">
        <IndexRoute component={groups} />
        <Route path=":groupId" component={groupUsers} />
        <Route path=":groupId/permissions" component={groupPermissions} />
        <Route path=":groupId/permissions/blacklist" component={groupPermissionsBlacklist} />
      </Route>
      <Route path="subscriptions">
        <IndexRoute component={subscriptions} />
        <Route path=":subscriptionType/:id" component={subscription} />
      </Route>
      <Route path="tiers">
        <IndexRoute component={tiers} />
        <Route path=":tierId" component={tier} />
      </Route>
      <Route path="purchases">
        <IndexRoute component={purchases} />
        <Route path=":purchaseId" component={purchase} />
      </Route>
    </Route>
  </Route>
);

export default routes;
