import { PureComponent } from 'react';

import { LoadingIndicator, EmptyState } from '@corva/ui/components';

// NOTE: To change this, take a look at react-loadable API: https://github.com/jamiebuilds/react-loadable
/* eslint-disable react/prop-types */
class LoaderView extends PureComponent {
  componentDidUpdate(prevProps) {
    if (window.Rollbar && this.props.error && this.props.error !== prevProps.error) {
      console.error(this.props.error);
      window.Rollbar.error(this.props.error);
    }
  }

  render() {
    if (this.props.error) {
      return <EmptyState title={this.props.errorTitle} state={EmptyState.IMAGES.AppLoadingError} />;
      // NOTE: If it takes more than 200ms(to avoid flashing) to load the component, show the loader
    } else if (this.props.pastDelay) {
      return <LoadingIndicator fullscreen={this.props.isFullscreen} />;
    }
    // NOTE: Don't render anything, if it takes less than 200ms to load the component
    return null;
  }
}

const createLoader = ({ isFullscreen, errorTitle }) => props => (
  <LoaderView isFullscreen={isFullscreen} errorTitle={errorTitle} {...props} />
);
/* eslint-enable */

export const asyncRouteLoadableOptions = options => ({
  loading: createLoader({ isFullscreen: true, errorTitle: 'Page Loading Error' }),
  render: (loaded, props) => {
    const Component = options.isSimpleDefaultExport ? loaded.default : loaded.default.component;

    return <Component {...props} />;
  },
  ...options,
});

export const asyncAppLoadableOptions = options => ({
  loading: createLoader({ isFullscreen: false, errorTitle: 'App Loading Error' }),
  ...options,
});
