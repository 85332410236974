import { browserHistory } from 'react-router';

const cleanSessionStorageFromHistoryRecords = () =>
  Object.keys(sessionStorage).forEach(key => {
    if (key.startsWith('@@History')) {
      sessionStorage.removeItem(key);
    }
  });

export const historyPush = location => {
  if (typeof location === 'string' || !location?.state) {
    browserHistory.push(location);
    return;
  }

  try {
    browserHistory.push(location);
  } catch {
    cleanSessionStorageFromHistoryRecords();
    browserHistory.push(location);
  }
};
