import { Map } from 'immutable';

import * as actions from '~/constants/login';

const initialState = Map({
  currentUser: null,
  loginAuthFailure: false,
  loggingIn: false,
  loginApiFailure: false,
  loginTermsFailure: false,
  loginAccountLockedFailure: false,
  invalidVerificationCode: false,
  missingVerificationCode: false,
});

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOG_IN:
      return state.merge({
        loggingIn: true,
        loginAuthFailure: false,
        loginApiFailure: false,
        loginTermsFailure: false,
        loginAccountLockedFailure: false,
        invalidVerificationCode: false,
        missingVerificationCode: false,
      });
    case actions.LOGGED_IN:
      return state.merge({
        loggingIn: false,
        currentUser: action.user,
        loginAuthFailure: false,
        loginApiFailure: false,
        loginTermsFailure: false,
        loginAccountLockedFailure: false,
        invalidVerificationCode: false,
      });
    case actions.LOGIN_AUTH_FAILED:
      return state.merge({
        loggingIn: false,
        loginAuthFailure: true,
        loginApiFailure: false,
        loginTermsFailure: false,
        loginAccountLockedFailure: false,
        invalidVerificationCode: false,
        missingVerificationCode: false,
      });
    case actions.LOGIN_API_FAILURE:
      return state.merge({
        loggingIn: false,
        loginAuthFailure: false,
        loginApiFailure: true,
        loginTermsFailure: false,
        loginAccountLockedFailure: false,
        invalidVerificationCode: false,
        missingVerificationCode: false,
      });
    case actions.LOGIN_TERMS_FAILURE:
      return state.merge({
        loggingIn: false,
        loginAuthFailure: false,
        loginApiFailure: false,
        loginTermsFailure: true,
        loginAccountLockedFailure: false,
        invalidVerificationCode: false,
        missingVerificationCode: false,
      });
    case actions.LOGIN_ACCOUNT_LOCKED_FAILURE:
      return state.merge({
        loggingIn: false,
        loginAuthFailure: false,
        loginApiFailure: false,
        loginTermsFailure: false,
        loginAccountLockedFailure: true,
        invalidVerificationCode: false,
        missingVerificationCode: false,
      });
    case actions.INVALID_VERIFICATION_CODE:
      return state.merge({
        ...initialState,
        invalidVerificationCode: true,
      });
    case actions.MISSING_VERIFICATION_CODE:
      return state.merge({
        ...initialState,
        missingVerificationCode: true,
      });
    case actions.ACCEPT_TERMS_SUCCESS:
      return state.merge({
        loginTermsFailure: false,
      });
    case actions.RELOAD_CURRENT_USER:
      return state.merge({
        currentUser: action.user,
      });
    case actions.UPDATE_CURRENT_USER_SETTINGS:
      return (
        state
          // For some weird reason this puts a plain object into `settings.uiSettings`
          .mergeIn(['currentUser', 'settings'], action.settings)
          .updateIn(['currentUser', 'settings', 'uiSettings'], uiSettings => Map(uiSettings))
      );
    default:
      return state;
  }
}
