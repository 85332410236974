import { camelCase } from 'lodash';
import moment from 'moment/moment';
import { addGlobalContexts, newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { MIXPANEL_ACTION_TYPES } from '~/constants/mixpanel';
import { getCompanyDomainFromEmail } from '~/utils/analytics';
import { isProdEnv, isBetaEnv } from '~/utils/env';
import { SNOWPLOW_ENABLED } from '~/externalServices/serviceFlags';

const IS_LOGGING_TO_PROD = isBetaEnv || isProdEnv;
const APP_ID = IS_LOGGING_TO_PROD ? 'corva' : 'corva-qa';
const SNOWPLOW_ENDPOINT = IS_LOGGING_TO_PROD ? 'snowplow.corva.ai' : 'snowplow-poc.qa.corva.ai';

const CONTEXT_SCHEMAS = {
  user: 'iglu:com.example/user_context/jsonschema/1-0-0',
  company: 'iglu:com.example/company_context/jsonschema/1-0-0',
};

const EVENT_SCHEMAS = {
  [MIXPANEL_ACTION_TYPES.APP.CLICK]: 'iglu:com.example/app_event/jsonschema/1-0-0',
  [MIXPANEL_ACTION_TYPES.APP.VIEW]: 'iglu:com.example/app_view/jsonschema/1-0-0',
  [MIXPANEL_ACTION_TYPES.APP.VIEW_CONTINUOUS]:
    'iglu:com.example/app_view_continuous/jsonschema/1-0-0',
};

let isSnowplowInitialized = false;

export function initializeSnowplow(currentUser) {
  if (!SNOWPLOW_ENABLED || isSnowplowInitialized) return;

  const companyId = currentUser.getIn(['company', 'id']);
  const companyName = currentUser.getIn(['company', 'name']);
  const userId = currentUser.get('id');

  // Define company context
  const companyContext = {
    schema: CONTEXT_SCHEMAS.company,
    data: {
      companyId,
      name: companyName,
      domain: getCompanyDomainFromEmail(currentUser.get('email')),
    },
  };

  // Define user context
  const userContext = {
    schema: CONTEXT_SCHEMAS.user,
    data: {
      userId,
      email: currentUser.get('email'),
      name: `${currentUser.get('first_name')} ${currentUser.get('last_name')}`,
      createdAt: moment(currentUser.get('created_at')).format('YYYY-MM-DD'),
      segment: currentUser.getIn(['preference', 'segment']),
      title: currentUser.get('title'),
      group: currentUser.get('group')?.toJS() || [],
      resource: currentUser.get('resource')?.toJS() || [],
    },
  };

  try {
    const tracker = newTracker('snowplow-poc', SNOWPLOW_ENDPOINT, {
      appId: APP_ID,
    });

    // Set user id
    tracker.setUserId(userId);
    // Add contexts to tracker
    addGlobalContexts([companyContext, userContext]);
    isSnowplowInitialized = true;
  } catch (e) {
    console.error('Error while initializing snowplow');
  }
}

export async function trackSnowplowEvent(type, logData) {
  if (!SNOWPLOW_ENABLED || !isSnowplowInitialized) {
    return;
  }

  if (!(type in EVENT_SCHEMAS)) {
    // Track only events from EVENT_SCHEMAS
    return;
  }

  const RAW_VALUE_KEYS = ['Segment', 'corvaV3'];

  const customData = Object.entries(logData).reduce((acc, [key, value]) => {
    if (RAW_VALUE_KEYS.includes(key)) {
      acc[camelCase(key)] = value;
    } else {
      acc[camelCase(key)] = String(value);
    }
    return acc;
  }, {});

  trackSelfDescribingEvent({
    event: {
      schema: EVENT_SCHEMAS[type],
      data: customData,
    },
  });
}
