import { getLocalStorageItem, updateLocalStorageItem } from '@corva/ui/utils';
import { isEmpty } from 'lodash';

const ORIGINAL_USER_DATA_STORAGE_KEY = 'originalUserData';

export const setOriginalUserData = userData => {
  if (isEmpty(userData)) return;

  const isCorvaV3FeaturesEnabled = userData.settings.uiSettings?.corva_v3_features_enabled;

  const originalUserData = {
    isCorvaV3FeaturesEnabled,
  };

  updateLocalStorageItem(ORIGINAL_USER_DATA_STORAGE_KEY, originalUserData);
};

export const getOriginalUserData = () => getLocalStorageItem(ORIGINAL_USER_DATA_STORAGE_KEY);

export const removeOriginalUserData = () =>
  localStorage.setItem(ORIGINAL_USER_DATA_STORAGE_KEY, null);
