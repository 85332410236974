import { getValue, setValue, pushValue, deleteValue } from './core';

const IMPERSONATION_REDIRECT_URL = 'IMPERSONATION_REDIRECT_URL';

const CHARTS_VIEW_SETTINGS_STORAGE_KEY = 'chartsViewSettings';
const APPS_HIDDEN_ALERTS = 'appsHiddenAlerts';
export const ASSET_PAGE_STORAGE_VERSION = 0;
export const ASSETS_PAGE_SETTINGS = `assets-page-storage`;
const FEED_INPUT_RIG_ID = 'feedInputRigId';

const SEGMENT_STORAGE_KEY = 'segment';

const SHARED_WELL_COLORS = 'sharedWellColors';

export const FEATURE_FLAG_APPS_ISOLATION = 'featureFlagAppsIsolation';
const IS_LOGGING_ANALYTICS_TO_CONSOLE = 'isLoggingAnalyticsToConsole';

// Note: Segment storage
export function setSegment(segment) {
  return setValue(SEGMENT_STORAGE_KEY, segment);
}

export function getSegment() {
  return getValue(SEGMENT_STORAGE_KEY);
}

export function startImpersonation({ redirectURL }) {
  return setValue(IMPERSONATION_REDIRECT_URL, redirectURL);
}

export function getImpersonationRedirectURL() {
  return getValue(IMPERSONATION_REDIRECT_URL);
}

export function stopImpersonation() {
  return deleteValue(IMPERSONATION_REDIRECT_URL);
}

// NOTE: App's settings storage
export function setAppViewStorageSettings(path, value, callback) {
  return setValue(
    [CHARTS_VIEW_SETTINGS_STORAGE_KEY, ...(Array.isArray(path) ? path : [path])],
    value,
    null, // NOTE: There are no expiration time here
    callback
  );
}

export function getAppViewStorageSettings(path, defaultValue) {
  return getValue(
    [CHARTS_VIEW_SETTINGS_STORAGE_KEY, ...(Array.isArray(path) ? path : [path])],
    defaultValue
  );
}

export function setHiddenAlerts(appId, value, callback) {
  return pushValue(
    [APPS_HIDDEN_ALERTS, appId],
    value,
    [5, 'd'], // NOTE: Pass the key of what time you want to add, and the amount you want to add
    callback
  );
}

export function getHiddenAlerts(appId) {
  return getValue([APPS_HIDDEN_ALERTS, appId]);
}

export function removeAssetPageSettings() {
  return deleteValue(ASSETS_PAGE_SETTINGS);
}

export function setFeedInputRigId(value, callback) {
  return setValue(
    FEED_INPUT_RIG_ID,
    value,
    null, // NOTE: There are no expiration time here
    callback
  );
}

export function removeFeedInputRigId() {
  return deleteValue(FEED_INPUT_RIG_ID);
}

export function getFeedInputRigId() {
  return getValue(FEED_INPUT_RIG_ID);
}

export function isAppsIsolationFeatureActive() {
  return getValue(FEATURE_FLAG_APPS_ISOLATION) ?? true;
}

export function isLoggingAnalyticsToConsole() {
  return getValue(IS_LOGGING_ANALYTICS_TO_CONSOLE) || false;
}

export function getSharedWellColors() {
  return getValue(SHARED_WELL_COLORS);
}

export function setSharedWellColors(value) {
  return setValue(SHARED_WELL_COLORS, value, null);
}
