import { Map } from 'immutable';

import * as t from '~/constants/dashboardFilters';

const initialState = Map({
  dashboardFilters: Map(),
  defaultDashboardFilters: Map(),
  filters: Map({
    corvaAdminCompanyId: null,
  }),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SAVE_DASHBOARD_FILTER:
    case t.DELETE_DASHBOARD_FILTER:
    case t.LOAD_DASHBOARD_FILTERS:
      return state
        .setIn(['dashboardFilters', action.dashboardId], action.filters)
        .setIn(['defaultDashboardFilters', action.dashboardId], action.filters);
    case t.SET_DASHBOARD_FILTERS:
      return state.setIn(['dashboardFilters', action.dashboardId], action.filters);
    case t.UPDATE_FILTER:
      return state.setIn(['filters', action.filterKey], action.filterValue);
    default:
      return state;
  }
};
