import { fromJS, List } from 'immutable';
import { uniqBy } from 'lodash';

import * as dashboardConstants from '~/constants/dashboards';

const initialState = {
  dashboards: [],
  folders: [],
  currentDashboard: null,
  currentAlertDashboard: null,

  currentDashboardAppsLastAnnotations: List(),
  sharedWithMeDashboards: null,
  sharedWithMeFolders: null,
  dialogType: null,
  dialogProps: {},
  pdfGeneratingNotifications: [],
  highlightedApps: {},
  highlightedWorkflow: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboardConstants.UPDATE_FOLDERS: {
      return { ...state, folders: action.folders };
    }
    case dashboardConstants.UPDATE_DASHBOARDS: {
      return { ...state, dashboards: action.dashboards };
    }
    case dashboardConstants.FETCH_DASHBOARDS: {
      return { ...state, dashboards: action.dashboards, folders: action.folders };
    }
    case dashboardConstants.UPDATE_CURRENT_DASHBOARD:
    case dashboardConstants.FETCH_CURRENT_DASHBOARD: {
      return { ...state, currentDashboard: action.currentDashboard };
    }

    case dashboardConstants.FETCH_CURRENT_ALERT_DASHBOARD: {
      return { ...state, currentAlertDashboard: action.currentDashboard };
    }

    case dashboardConstants.SHOW_DIALOG:
      return { ...state, dialogType: action.dialogType, dialogProps: action.dialogProps || {} };

    case dashboardConstants.HIDE_DIALOG:
      return { ...state, dialogType: null, dialogProps: {} };

    case dashboardConstants.ADD_APP: {
      return {
        ...state,
        // NOTE: No current dashboard on App Store page
        currentDashboard: state.currentDashboard && {
          ...state.currentDashboard,
          apps: uniqBy([...state.currentDashboard.apps, action.app], 'id'),
        },
        highlightedApps: { ...state.highlightedApps, [action.app.id]: true },
      };
    }
    case dashboardConstants.REMOVE_APP: {
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.filter(app => app.id !== action.appId),
        },
      };
    }

    case dashboardConstants.UPDATE_APP: {
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.map(app =>
            app.id === action.app.id ? action.app : app
          ),
        },
      };
    }
    case dashboardConstants.MOVE_APPS: {
      return {
        ...state,
        currentDashboard: { ...state.currentDashboard, apps: action.apps },
      };
    }
    case dashboardConstants.UPDATE_ALL_APPS: {
      return {
        ...state,
        currentDashboard: { ...state.currentDashboard, apps: action.apps },
      };
    }
    case dashboardConstants.CLEAR_CURRENT_DASHBOARD: {
      return { ...state, currentDashboard: null };
    }
    case dashboardConstants.CLEAR_CURRENT_ALERT_DASHBOARD: {
      return { ...state, currentAlertDashboard: null };
    }
    case dashboardConstants.DELETE_DASHBOARD: {
      return {
        ...state,
        currentDashboard:
          state.currentDashboard.id === action.dashboardId ? null : state.currentDashboard,
        dashboards: state.dashboards.filter(d => d.id !== action.dashboardId),
      };
    }

    // NOTE: Last Annotations
    case dashboardConstants.SET_CURRENT_DASHBOARD_APPS_LAST_ANNOTATIONS:
      return { ...state, currentDashboardAppsLastAnnotations: action.lastAnnotations };
    case dashboardConstants.UPDATE_CURRENT_DASHBOARD_APP_LAST_ANNOTATION:
      return {
        ...state,
        currentDashboardAppsLastAnnotations: state.currentDashboardAppsLastAnnotations.map(item =>
          item.get('app_id') === action.appId &&
          item.getIn(['last_app_annotation', 'data', 'id']) === action.annotationId
            ? item.set('last_app_annotation', action.annotation)
            : item
        ),
      };

    case dashboardConstants.CLEAR_DASHBOARDS:
      return { ...state, dashboards: [] };
    case dashboardConstants.FETCH_SHARED_WITH_ME_DASHBOARDS_AND_FOLDERS: {
      return {
        ...state,
        sharedWithMeDashboards: action.dashboards,
        sharedWithMeFolders: action.folders,
      };
    }
    case dashboardConstants.HIDE_SHARED_WITH_ME_DASHBOARD: {
      return {
        ...state,
        sharedWithMeDashboards: state.sharedWithMeDashboards.filter(
          d => d.dashboard_share_id !== action.dashboardShareId
        ),
      };
    }

    case dashboardConstants.HIDE_SHARED_WITH_ME_FOLDER: {
      return {
        ...state,
        sharedWithMeFolders: state.sharedWithMeFolders.filter(
          folder => folder.id !== action.folderId
        ),
      };
    }

    // NOTE: pdf preparing notifications
    case dashboardConstants.ADD_PDF_GENERATING_NOTIFICATION:
      return {
        ...state,
        pdfGeneratingNotifications: [
          ...state.pdfGeneratingNotifications,
          action.pdfGeneratingNotification,
        ],
      };
    case dashboardConstants.REMOVE_PDF_GENERATING_NOTIFICATION:
      return {
        ...state,
        pdfGeneratingNotifications: state.pdfGeneratingNotifications.filter(
          ({ id }) => id !== action.pdfGeneratingNotificationId
        ),
      };
    case dashboardConstants.REMOVE_ALL_PDF_GENRATING_NOTIFICATIONS:
      return {
        ...state,
        pdfGeneratingNotifications: [],
      };
    case dashboardConstants.DISABLE_APP_HIGHLIGHT:
      return {
        ...state,
        highlightedApps: { ...state.highlightedApps, [action.appId]: false },
      };
    case dashboardConstants.DELETE_DASHBOARD_WORKFLOW:
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.filter(
            app => app.dashboard_workflow_id !== action.dashboardWorkflowId
          ),
          dashboard_workflows: state.currentDashboard.dashboard_workflows.filter(
            ({ id }) => id !== action.dashboardWorkflowId
          ),
        },
      };
    case dashboardConstants.UNGROUP_DASHBOARD_WORKFLOW:
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.map(app =>
            app.dashboard_workflow_id === action.dashboardWorkflowId
              ? { ...app, dashboard_workflow_id: null }
              : app
          ),
          dashboard_workflows: state.currentDashboard.dashboard_workflows.filter(
            ({ id }) => id !== action.dashboardWorkflowId
          ),
        },
      };
    case dashboardConstants.UNGROUP_ALL_DASHBOARD_WORKFLOWS:
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.map(app => ({ ...app, dashboard_workflow_id: null })),
          dashboard_workflows: [],
        },
      };
    case dashboardConstants.CHANGE_WORKFLOWS_ASSETS:
      return {
        ...state,
        currentDashboard: {
          ...state.currentDashboard,
          apps: state.currentDashboard.apps.map(app => {
            if (!(app.dashboard_workflow_id in action.workflows)) return app;

            const newSettings = action.workflows[app.dashboard_workflow_id].settings || {};
            const settings = app.settings.toJS ? app.settings.toJS() : { ...app.settings };

            return {
              ...app,
              settings: app.settings.toJS
                ? fromJS({ ...settings, ...newSettings })
                : { ...settings, ...newSettings },
            };
          }),
          dashboard_workflows: state.currentDashboard.dashboard_workflows.map(workflow => {
            return workflow.id in action.workflows
              ? {
                  ...workflow,
                  asset_id: action.workflows[workflow.id].asset_id,
                  settings: action.workflows[workflow.id].settings,
                }
              : workflow;
          }),
        },
      };
    case dashboardConstants.SET_HIGHLIGHTED_WORKFLOW:
      return { ...state, highlightedWorkflow: action.workflowId };
    default:
      return state;
  }
};
