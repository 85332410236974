import ReactGA from 'react-ga';

import { GOOGLE_ANALYTICS_ENABLED } from '~/externalServices/serviceFlags';
import { currentUserSelector, isImpersonatingSelector } from '~/selectors/login';

/**
 * Log data to Google Analytics
 */
export function logToGoogleAnalytics(page) {
  return (dispatch, getState) => {
    if (!GOOGLE_ANALYTICS_ENABLED) {
      return;
    }

    const currentUser = currentUserSelector(getState());
    const isImpersonating = isImpersonatingSelector(getState());

    if (!currentUser || isImpersonating) {
      return;
    }

    const userId = currentUser.get('id');

    ReactGA.pageview(page);

    ReactGA.set({
      userId,
      page,
    });
  };
}
