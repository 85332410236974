import { flattenDepth, range } from 'lodash';
import Jsona from 'jsona';
import * as queryString from 'query-string';

import {
  baseUrl,
  del,
  get,
  getFile,
  getWithHeaders,
  patch,
  post,
  put,
  sendFormData,
} from '../api/apiCore';

const dataFormatter = new Jsona();

export async function getWells(options, getterFn = get) {
  return getterFn('/v2/wells', options, { isImmutable: false });
}

export async function getWell(id, options) {
  return get(`/v2/wells/${id}`, options, { isImmutable: false });
}

export async function patchWell(id, data, fields = []) {
  return patch(`/v2/wells/${id}`, data, { fields }, { isImmutable: false });
}

export async function postWell(data) {
  return post('/v2/wells/', data, { isImmutable: false });
}

export async function getRigs(options = {}, getterFn = get) {
  return getterFn('/v2/rigs', options, { isImmutable: false });
}

export async function getRig(id, options = {}) {
  return get(`/v2/rigs/${id}`, options, { isImmutable: false });
}

export async function patchRig(id, data, fields = []) {
  return patch(`/v2/rigs/${id}`, data, { fields }, { isImmutable: false });
}

export async function postRig(data) {
  return post('/v2/rigs/', data, { isImmutable: false });
}

export async function getPrograms(options = {}, getterFn = get) {
  return getterFn('/v2/programs', options, { isImmutable: false });
}

export async function patchProgram(id, data) {
  return patch(`/v2/programs/${id}`, data, {}, { isImmutable: false });
}

export async function createProgram(data) {
  return post(`/v2/programs`, data, { isImmutable: false });
}

export async function getAsset(id, options = {}) {
  return get(`/v2/assets/${id}`, options, { isImmutable: false });
}

export async function patchAsset(id, data) {
  return patch(`/v1/assets/${id}`, data, {}, { isImmutable: false });
}

export async function resolveAsset(assetIds) {
  return post('/v2/assets/resolve', { assets: assetIds }, { isImmutable: false });
}

export async function resolveAssetRigId(assetId) {
  const resolvedResponse = await resolveAsset([assetId]);

  return resolvedResponse?.rigs?.[0]?.data?.id;
}

export async function getAssets(options = {}, getterFn = get) {
  return getterFn('/v2/assets', options, { isImmutable: false });
}

export async function getPads(options = {}, getterFn = get) {
  return getterFn('/v2/pads', options, { isImmutable: false });
}

export async function postWellsToPad(id, wellIds) {
  return post(`/v2/pads/${id}/wells`, { well_ids: wellIds }, { isImmutable: false });
}

export async function postViewerWellToPad(id, wellId) {
  return post(`/v2/pads/${id}/viewer_well`, { well_id: wellId }, { isImmutable: false });
}

export async function deleteViewerWellFromPad(id) {
  return del(`/v2/pads/${id}/viewer_well`);
}

export async function deleteWellFromPad(padId, wellId) {
  return del(`/v2/pads/${padId}/wells/${wellId}`);
}

export async function getPad(padId, options = {}) {
  return get(`/v2/pads/${padId}`, options, { isImmutable: false });
}

export async function postPad(options = {}) {
  return post('/v2/pads', options, { isImmutable: false });
}

export async function patchPad(padId, pad, fields = []) {
  return patch(`/v2/pads/${padId}`, pad, { fields }, { isImmutable: false });
}

export async function updatePadFracFleet(padId, fracFleetId, options) {
  return put(`/v2/pads/${padId}/frac_fleets/${fracFleetId}`, options, {}, { isImmutable: false });
}

export async function getFracFleets(options = {}, getterFn = get) {
  return getterFn('/v2/frac_fleets', options, { isImmutable: false });
}

export async function getFracFleet(id, options = {}) {
  return get(`/v2/frac_fleets/${id}`, options, { isImmutable: false });
}

export async function postFracFleet(options = {}) {
  return post('/v2/frac_fleets', options, { isImmutable: false });
}

export async function patchFracFleet(fracFleetId, fracFleet, fields = []) {
  return patch(`/v2/frac_fleets/${fracFleetId}`, fracFleet, { fields }, { isImmutable: false });
}

export async function deletePadFracFleet(fracFleetId, options) {
  return del(`/v2/pad_frac_fleets/${fracFleetId}`, options, { isImmutable: false });
}

export async function postPadToFracFleet(fracFleetId, padId) {
  return post(
    `/v2/frac_fleets/${fracFleetId}/pads`,
    { pad_ids: [padId], current_pad_id: padId },
    { isImmutable: false }
  );
}

export async function postFracFleetToPad(padId, currentFracFleetId, fracFleetIds) {
  return post(
    `/v2/pads/${padId}/frac_fleets`,
    {
      frac_fleet_ids: fracFleetIds,
      current_frac_fleet_id: currentFracFleetId,
    },
    { isImmutable: false }
  );
}

export async function postCurrentPadToFracFleet(fracFleetId, padId) {
  return post(
    `/v2/frac_fleets/${fracFleetId}/pads`,
    { current_pad_id: padId },
    { isImmutable: false }
  );
}

export async function getDrilloutUnit(drilloutUnitId, options = {}) {
  return get(`/v2/drillout_units/${drilloutUnitId}`, options, { isImmutable: false });
}

export async function postDrilloutUnit(options = {}) {
  return post('/v2/drillout_units', options, { isImmutable: false });
}

export async function patchDrilloutUnit(drilloutId, drillout, fields = []) {
  return patch(`/v2/drillout_units/${drilloutId}`, drillout, { fields }, { isImmutable: false });
}

export async function getDrilloutUnits(options = {}, getterFn = get) {
  return getterFn('/v2/drillout_units', options, { isImmutable: false });
}

export async function getNavigation() {
  try {
    const res = await get(
      '/v2/apps/app_categories',
      {
        category: 'wellhub',
        fields: 'all',
      },
      { isImmutable: false }
    );
    return dataFormatter.deserialize(res);
  } catch (err) {
    return [];
  }
}

export async function deleteAppStorageRecords(provider, collection, queryParams) {
  return del(`/v1/data/${provider}/${collection}`, queryParams, { isImmutable: false });
}

export async function getAppStorage(provider, collection, assetId, params = {}, companyId = null) {
  let queryParams = {};
  if (!assetId && companyId) {
    queryParams = { ...params, company_id: companyId };
  } else {
    queryParams = { ...params, asset_id: assetId };
  }

  return get(`/v1/data/${provider}/${collection}`, queryParams, { isImmutable: false });
}

export async function getDataAppStorage(provider, collection, params = {}) {
  return get(`/api/v1/data/${provider}/${collection}`, params, {
    isImmutable: false,
    getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
  });
}

export async function putAppStorage(provider, collection, id, item, queryParams = {}) {
  return put(`/v1/data/${provider}/${collection}/${id}`, item, queryParams, { isImmutable: false });
}

export async function postAppStorage(provider, collection, item) {
  return post(`/v1/data/${provider}/${collection}`, item, { isImmutable: false });
}

export async function deleteAppStorage(provider, collection, id) {
  return del(`/v1/data/${provider}/${collection}/${id}`, {}, { isImmutable: false });
}

export async function deleteFile(options) {
  return del('/v2/files', options, { isImmutable: false });
}

export async function getCompetitorAnalysisData(options) {
  return get('/v1/competitor_analysis', options, { isImmutable: false });
}

export async function getCompanies(options = {}) {
  return get('/v1/companies', options, { isImmutable: false });
}

export async function getPicklists() {
  return get('/v2/picklists', {}, { isImmutable: false });
}

export async function getPicklist(name) {
  return get(`/v2/picklists/${name}`, {}, { isImmutable: false });
}

export async function postPicklist(options = {}) {
  return post('/v2/picklists', options, { isImmutable: false });
}

export async function postPicklistItem(name, options = {}) {
  return post(`/v2/picklists/${name}/items`, options, { isImmutable: false });
}

export async function getFavorites(queryParams = {}) {
  return get(`/v2/favorites`, queryParams, { isImmutable: false });
}

export async function getTask(taskId) {
  return get(`/v2/tasks/${taskId}`, {}, { isImmutable: false });
}

export async function createTask(provider, appKey, assetId, props) {
  return post(
    `/v2/tasks`,
    {
      task: {
        provider,
        app_key: appKey,
        asset_id: assetId,
        properties: props,
      },
    },
    { isImmutable: false }
  );
}

export async function getCompanyGoals(companyId) {
  return get(`/v1/companies/${companyId}/settings/company_goals`, {}, { isImmutable: false });
}

export const getLASFileList = async assetId => {
  const $match = {
    asset_id: +assetId,
  };

  const $group = {
    _id: '$file',
    name: { $first: '$file' },
    timestamp: { $first: '$timestamp' },
  };

  const $sort = {
    timestamp: -1,
  };

  const queryJson = {
    aggregate: JSON.stringify([{ $match }, { $group }, { $sort }]),
  };

  try {
    return await getAppStorage('corva', 'formation-evaluation.metadata', assetId, queryJson);
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getLASMetadata = async (assetId, fileName) => {
  const query = `{asset_id#eq#${assetId}}AND{file#eq#'${fileName}'}`;
  try {
    return await getAppStorage('corva', 'formation-evaluation.metadata', assetId, { query });
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getLASFileDataByPage = async (assetId, fileName, pageNumber) => {
  const $match = { asset_id: +assetId, 'metadata.file': fileName };
  const $limit = 10000;
  const $skip = pageNumber * 10000;
  const $sort = { 'data.md': 1 };
  const $project = {
    data: '$data',
  };

  const queryJson = {
    aggregate: JSON.stringify([{ $match }, { $sort }, { $skip }, { $limit }, { $project }]),
  };

  try {
    const response = await getAppStorage('corva', 'formation-evaluation.data', assetId, queryJson);
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllLASFileData = async ({ assetId, fileName, recordsCount }) => {
  const pagesCount = Math.ceil(recordsCount / 10000);
  const promises = range(pagesCount).map(page => getLASFileDataByPage(assetId, fileName, page));

  return Promise.all(promises).then(responses => flattenDepth(responses));
};

export const getStreamById = id => {
  return get(`/v1/app_streams/${id}`, {}, { isImmutable: false });
};

export const getStreams = ({ assetId, segment = 'drilling', ...otherQueryParams }) => {
  return get(
    `/v1/app_streams`,
    { asset_id: assetId, segment, ...otherQueryParams },
    { isImmutable: false }
  );
};

export async function getWitsmlDepthLog(
  provider,
  collection,
  streamId,
  queryParams = {},
  otherParams = {}
) {
  return get(
    `/v2/data/app_streams/${streamId}/${provider}/${collection}`,
    {
      // NOTE: sort & limit should be specified in the query
      sort: '{measured_depth: 1}',
      limit: 10000,
      ...queryParams,
    },
    { isImmutable: false, ...otherParams }
  );
}

export const getInvoices = queryParams => {
  return get(`/v2/invoices`, queryParams, { isImmutable: false });
};

export const getInvoiceCSV = invoiceId => {
  return getFile(`/v2/invoices/${invoiceId}/proof`);
};

export const createApp = (data, params = {}) => {
  return post(`/v2/apps/`, data, { ...params, isImmutable: false });
};

export const getApps = queryParams => {
  return get(`/v2/apps`, queryParams, { isImmutable: false });
};

export const getAppsWithHeaders = queryParams => {
  return getWithHeaders(`/v2/apps`, queryParams, { isImmutable: false });
};

export const getAppCompanies = queryParams => {
  return get(`/v2/app_companies`, queryParams, { isImmutable: false });
};

export const getAppCategories = (queryParams = {}) => {
  return get('/v2/apps/categories', queryParams, { isImmutable: false });
};

export const getAppTypes = () => {
  return get('/v2/apps/types', {}, { isImmutable: false });
};

export const getAppTypesWithDocText = () => {
  return get('/v2/app_types', {}, { isImmutable: false });
};

export const getAppTypeWithDocTextById = appTypeId => {
  return get(`/v2/app_types/${appTypeId}`, {}, { isImmutable: false });
};

export const updateAppTypesWithDocText = (appTypeId, options) => {
  return patch(`/v2/app_types/${appTypeId}`, options, {}, { isImmutable: false });
};

export const getApp = (appId, queryParams) => {
  return get(`/v2/apps/${appId}`, queryParams, { isImmutable: false });
};

export const getAppSecrets = appId => {
  return get(`/v2/apps/${appId}/secrets`, {}, { isImmutable: false });
};

export const postAppSecret = (appId, payload) => {
  return post(`/v2/apps/${appId}/secrets`, payload, { isImmutable: false });
};

export const putAppSecret = (appId, payload) => {
  return put(`/v2/apps/${appId}/secrets`, payload, {}, { isImmutable: false });
};

export const deleteAppSecret = (appId, queryParams) => {
  return del(`/v2/apps/${appId}/secrets`, queryParams, { isImmutable: false });
};

export const getAppLogs = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/app_logs`, queryParams, { isImmutable: false });
};

export const getAppEventLogs = (appId, logStream, queryParams) => {
  return get(`/v2/apps/${appId}/app_logs/events/${logStream}`, queryParams, { isImmutable: false });
};

export const getAppLogsSearch = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/app_logs/search/`, queryParams, { isImmutable: false });
};

export const getAppMetrics = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/metrics`, queryParams, { isImmutable: false });
};

export const getAppsMetrics = queryParams => {
  return get(`/v2/app_metrics/for_selected_apps`, queryParams, { isImmutable: false });
};

export const getAppAssets = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/assets`, queryParams, { isImmutable: false });
};

export const postAppErrorAlert = (appId, payload) => {
  return post(`/v2/apps/${appId}/app_error_alerts`, payload, { isImmutable: false });
};

export const updateAppErrorAlert = (appId, payload) => {
  return patch(`/v2/apps/${appId}/app_error_alerts`, payload, { isImmutable: false });
};

export const subscribeAppErrorAlert = (appId, payload) => {
  return post(`/v2/apps/${appId}/app_error_alerts/subscribe`, payload, { isImmutable: false });
};

export const unsubscribeAppErrorAlert = appId => {
  return post(`/v2/apps/${appId}/app_error_alerts/unsubscribe`, {}, { isImmutable: false });
};

export const getAppErrorAlert = appId => {
  return get(`/v2/apps/${appId}/app_error_alerts`, {}, { isImmutable: false });
};

export const listTiers = queryParams => {
  return get(`/v2/tiers/`, queryParams, { isImmutable: false });
};

export const getTiersWithHeaders = queryParams => {
  return getWithHeaders(`/v2/tiers/`, queryParams, { isImmutable: false });
};

export function getPlatformSubscriptions(options = {}) {
  return get('/v2/platform_subscriptions', options, { isImmutable: false });
}

export function createPlatformSubscription(data) {
  return post(`/v2/platform_subscriptions`, data, { isImmutable: false });
}

export function getPlatformSubscription(subscriptionId, options = {}) {
  return get(`/v2/platform_subscriptions/${subscriptionId}`, options, { isImmutable: false });
}

export function deletePlatformSubscription(subscriptionId) {
  return del(`/v2/platform_subscriptions/${subscriptionId}`, {}, { isImmutable: false });
}

export function updatePlatformSubscription(subscriptionId, options) {
  return patch(`/v2/platform_subscriptions/${subscriptionId}`, options, {}, { isImmutable: false });
}

export function postDenyPlatformSubscription(subscriptionId) {
  return post(`/v2/platform_subscriptions/${subscriptionId}/deny`, {}, { isImmutable: false });
}

export function postCancelPlatformSubscription(subscriptionId) {
  return post(`/v2/platform_subscriptions/${subscriptionId}/cancel`, {}, { isImmutable: false });
}

export function postResumePlatformSubscription(subscriptionId) {
  return post(`/v2/platform_subscriptions/${subscriptionId}/resume`, {}, { isImmutable: false });
}

export function getProvisioningSubscriptions(options = {}) {
  return get('/v2/provisioning_subscriptions', options, { isImmutable: false });
}

export function createProvisioningSubscription(data) {
  return post('/v2/provisioning_subscriptions', data, { isImmutable: false });
}

export function getProvisioningSubscription(subscriptionId, options = {}) {
  return get(`/v2/provisioning_subscriptions/${subscriptionId}`, options, { isImmutable: false });
}

export function getProvisioningAppStreamsInfo(subscriptionId, options = {}) {
  return get(`/v2/provisioning_subscriptions/${subscriptionId}/app_streams`, options, {
    isImmutable: false,
  });
}

export function updateProvisioningSubscription(subscriptionId, options) {
  return patch(
    `/v2/provisioning_subscriptions/${subscriptionId}`,
    options,
    {},
    { isImmutable: false }
  );
}

export function deleteProvisioningSubscription(subscriptionId) {
  return del(`/v2/provisioning_subscriptions/${subscriptionId}`, {}, { isImmutable: false });
}

export function postDenyProvisioningSubscription(subscriptionId) {
  return post(`/v2/provisioning_subscriptions/${subscriptionId}/deny`, {}, { isImmutable: false });
}

export function postResumeProvisioningSubscription(subscriptionId) {
  return post(
    `/v2/provisioning_subscriptions/${subscriptionId}/resume`,
    {},
    { isImmutable: false }
  );
}

export function postCancelProvisioningSubscription(subscriptionId) {
  return post(
    `/v2/provisioning_subscriptions/${subscriptionId}/cancel`,
    {},
    { isImmutable: false }
  );
}

export const createTier = data => {
  return post('/v2/tiers', data, { isImmutable: false });
};

export const getTier = (tierId, queryParams) => {
  return get(`/v2/tiers/${tierId}`, queryParams, { isImmutable: false });
};

export const getBillingUsage = queryParams => {
  return get('/v2/usage', queryParams, { isImmutable: false });
};

export const getBillingSummaries = queryParams => {
  return get('/v2/usage/summaries', queryParams, { isImmutable: false });
};

export const updateTier = (tierId, tierFields) => {
  return patch(`/v2/tiers/${tierId}`, tierFields, {}, { isImmutable: false });
};

export const deleteTier = tierId => {
  return del(`/v2/tiers/${tierId}`, { isImmutable: false });
};

export function listSubscriptionFeatures(options = {}) {
  return get('/v2/subscription_features', options, { isImmutable: false });
}

export async function getDatasetTypes() {
  return get(`/v2/dataset_types`, {}, { isImmutable: false });
}

export async function updateDatasetType(datasetTypeId, options) {
  return patch(`/v2/dataset_types/${datasetTypeId}`, options, {}, { isImmutable: false });
}

export const updateApp = (appId, appFields, queryParams) => {
  const appFieldsParentKey = 'app';
  const flatParamsObject = Object.entries(appFields)
    .filter(([, value]) => value !== null && value !== undefined)
    .reduce((flatParamsAccum, [key, value]) => {
      const maybeArrayBrackets = Array.isArray(value) ? '[]' : '';

      return {
        ...flatParamsAccum,
        [`${appFieldsParentKey}[${key}]${maybeArrayBrackets}`]: value,
      };
    }, {});

  return sendFormData(`/v2/apps/${appId}`, flatParamsObject, queryParams, { method: 'PATCH' });
};

export const patchApp = (appId, options, queryParams = {}) => {
  return patch(`/v2/apps/${appId}`, options, queryParams, { isImmutable: false });
};
export const deleteApp = (appId, queryParams) => {
  return del(`/v2/apps/${appId}`, queryParams, { isImmutable: false });
};

export const uploadAppPackage = (appId, file) => {
  return sendFormData(`/v2/apps/${appId}/packages/upload`, { package: file, source: 'ui' });
};

export function getPackageDownloadLink(appId, packageId) {
  return `${baseUrl}/v2/apps/${appId}/packages/${packageId}/download`;
}

export const getAppPackages = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/packages`, queryParams, { isImmutable: false });
};

export const deleteAppPackage = (appId, packageId, queryParams) => {
  return del(`/v2/apps/${appId}/packages/${packageId}`, queryParams, { isImmutable: false });
};

export const updateAppPackage = (appId, packageId, packageFields) => {
  return patch(
    `/v2/apps/${appId}/packages/${packageId}`,
    packageFields,
    {},
    {
      isImmutable: false,
    }
  );
};

export const getPackageReviews = queryParams => {
  return get('/v2/package_reviews', queryParams, { isImmutable: false });
};

export const createPackageReview = entity => {
  return post('/v2/package_reviews', entity, { isImmutable: false });
};

export const getPackageReview = (packageReviewId, queryParams = {}) => {
  return get(`/v2/package_reviews/${packageReviewId}`, queryParams, { isImmutable: false });
};

export const patchPackageReview = (packageReviewId, data) => {
  return patch(`/v2/package_reviews/${packageReviewId}`, data, {}, { isImmutable: false });
};

export const deletePackageReview = reviewId => {
  return del(`/v2/package_reviews/${reviewId}`, {}, { isImmutable: false });
};

export const getDatasets = (params = {}) => {
  return get('/api/v1/dataset/', params, {
    isImmutable: false,
    getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
  });
};

export const getDatasetsByCompany = (params = {}) => {
  return get('/api/v1/dataset/company/', params, {
    isImmutable: false,
    getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
  });
};

export const getDataset = (provider, datasetName) => {
  return get(
    `/api/v1/dataset/${provider}/${datasetName}/`,
    {},
    { isImmutable: false, getBaseUrl: baseUrl => baseUrl.replace('api', 'data') }
  );
};

export const deleteDataset = (provider, datasetName) =>
  del(
    `/api/v1/dataset/${provider}/${datasetName}/`,
    {},
    { isImmutable: false, getBaseUrl: baseUrl => baseUrl.replace('api', 'data') }
  );

export const createDataset = (provider, datasetName, dataset) =>
  post(`/api/v1/dataset/${provider}/${datasetName}/`, dataset, {
    isImmutable: false,
    getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
  });

export const patchDataset = (provider, datasetName, dataset) =>
  patch(
    `/api/v1/dataset/${provider}/${datasetName}/`,
    dataset,
    {},
    { isImmutable: false, getBaseUrl: baseUrl => baseUrl.replace('api', 'data') }
  );

export const postDatasetIndex = ({ provider, datasetName }, indexEntity) =>
  post(`/api/v1/dataset/${provider}/${datasetName}/index/`, indexEntity, {
    getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
    isImmutable: false,
  });
export const postDatasetIndexAction = ({ provider, datasetName, indexName, action }) =>
  post(
    `/api/v1/dataset/${provider}/${datasetName}/index/${indexName}/${action}/`,
    {},
    { getBaseUrl: baseUrl => baseUrl.replace('api', 'data'), isImmutable: false }
  );

export const deleteDatasetIndex = ({ provider, datasetName, indexName }) =>
  del(
    `/api/v1/dataset/${provider}/${datasetName}/index/${indexName}/`,
    {},
    {
      getBaseUrl: baseUrl => baseUrl.replace('api', 'data'),
    }
  );

export const getUser = async id => get(`/v1/users/${id}`, {}, { isImmutable: false });

export const getUsers = options => {
  const {
    // FIXME: This is a temporary fix. Default limit (per_page) of 1000 is here to get more than
    // default amount of items from Back-end that is 100.
    // Ticket to fix this with pagination - CW-9173.
    page,
    perPage = 1000,
    sort,
    order,
    search,
    companyId,
    ids,
  } = options;
  const queryParams = {
    page,
    per_page: perPage,
    sort,
    order,
    ids,
    search: search !== '' ? search : undefined,
    // NOTE: For Corva admins if there is no 'company_id', it returns all companies users
    company_id: companyId !== null ? companyId : undefined,
  };
  return get('/v1/users', queryParams, { isImmutable: false });
};

export const getUsersV2 = async (params = {}) => get('/v2/users', params, { isImmutable: false });

export const getUserSettings = async userId => {
  return get(`/v2/users/${userId}/settings`, {}, { isImmutable: false });
};

export const postUserSettings = async (userId, userSettings) => {
  return post(`/v2/users/${userId}/settings`, userSettings, { isImmutable: false });
};

export const getUserSetting = async (userId, settingKey) => {
  return get(`/v2/users/${userId}/settings/${settingKey}`, {}, { isImmutable: false });
};

export const getAppSettingsTemplates = appKey => {
  return get(`/v2/apps/${appKey}/app_settings_templates`, {}, { isImmutable: false });
};

export const getAppSettingsTemplatesWithHeaders = (appKey, params = {}) => {
  return getWithHeaders(`/v2/apps/${appKey}/app_settings_templates`, params, {
    isImmutable: false,
  });
};

export const getAppSettingsTemplate = (appKey, id) => {
  return get(`/v2/apps/${appKey}/app_settings_templates/${id}`, {}, { isImmutable: false });
};

export const getApiKeys = queryParams => {
  return get('/v2/api_keys_management', queryParams, { isImmutable: false });
};

export const getApiKey = id => {
  return get(`/v2/api_keys_management/${id}`, {}, { isImmutable: false });
};

export const postTerminateApiKey = id => {
  return post(`/v2/api_keys_management/${id}/deactivate`, {}, { isImmutable: false });
};

export const postActivateApiKey = id => {
  return post(`/v2/api_keys_management/${id}/activate`, {}, { isImmutable: false });
};

export const postCreateApiKey = params => {
  return post(`/v2/api_keys_management`, params, { isImmutable: false });
};

export const patchUpdateApiKey = (id, params) => {
  return patch(`/v2/api_keys_management/${id}`, params, {}, { isImmutable: false });
};

export const patchAppSettingsTemplate = (appKey, id, { name, settings }) => {
  return patch(
    `/v2/apps/${appKey}/app_settings_templates/${id}`,
    {
      app_settings_template: {
        name,
        settings,
      },
    },
    {},
    { isImmutable: false }
  );
};

export const postAppSettingsTemplate = (appKey, { name, settings }) => {
  return post(
    `/v2/apps/${appKey}/app_settings_templates`,
    {
      app_settings_template: {
        name,
        settings,
      },
    },
    { isImmutable: false }
  );
};

export const deleteAppSettingsTemplate = (appKey, id) => {
  return del(`/v2/apps/${appKey}/app_settings_templates/${id}`, {}, { isImmutable: false });
};

export const shareAppSettingsTemplate = (appKey, id, userIds) => {
  const query = queryString.stringify({ user_id: userIds }, { arrayFormat: 'bracket' });
  return post(
    `/v2/apps/${appKey}/app_settings_templates/${id}/share?${query}`,
    {},
    { isImmutable: false }
  );
};

export const getAppHelpContent = (appId, queryParams) => {
  return get(`/v2/apps/${appId}/app_help_contents`, queryParams, { isImmutable: false });
};

export const deleteAppHelpContent = (appId, helpContentId) => {
  return del(`/v2/apps/${appId}/app_help_contents/${helpContentId}`, null, { isImmutable: false });
};

export const uploadAppHelpContent = (appId, appHelpContentData) => {
  return post(
    `/v2/apps/${appId}/app_help_contents`,
    { app_help_content: appHelpContentData },
    { isImmutable: false }
  );
};

export const updateAppHelpContent = (appId, helpContentId, appHelpContentData) => {
  return patch(
    `/v2/apps/${appId}/app_help_contents/${helpContentId}`,
    { app_help_content: appHelpContentData },
    {},
    { isImmutable: false }
  );
};

export const getAppHelpFileUploadLink = (appId, fileName, contentType) => {
  return get(
    `/v2/apps/${appId}/app_help_contents/signed_url`,
    { file_name: fileName, contentType },
    { isImmutable: false }
  );
};

export const getAppCategoriesBySegment = (queryParams = {}) => {
  return get('/v2/apps/app_categories', queryParams, { isImmutable: false });
};

export async function getOptions(queryParams) {
  return get(`/options/`, queryParams, { isImmutable: false });
}

export const getUserPermissions = async (userId, queryParams = {}) =>
  get(`/v2/users/${userId}/permissions`, queryParams, { isImmutable: false });

export const getGroupedUserPermissions = (userId, queryParams = {}) =>
  getWithHeaders(`/v2/users/${userId}/permissions/grouped`, queryParams, { isImmutable: false });

export const createBulkUserPermissions = async (userId, entity = {}) =>
  post(`/v2/users/${userId}/permissions/bulk_create`, entity, { isImmutable: false });

export const deleteBulkUserPermissions = (userId, queryParams) =>
  del(`/v2/users/${userId}/permissions/bulk_destroy`, queryParams, { isImmutable: false });

export const deleteUserPermission = async (userId, permissionId) =>
  del(`/v2/users/${userId}/permissions/${permissionId}`, {}, { isImmutable: false });

export const getGroups = async (queryParams = {}) =>
  get(`/v2/groups`, queryParams, { isImmutable: false });

export const getGroupsWithHeaders = async (queryParams = {}) =>
  getWithHeaders(`/v2/groups`, queryParams, { isImmutable: false });

export const getGroup = async (groupId, queryParams = {}) =>
  get(`/v2/groups/${groupId}`, queryParams, { isImmutable: false });

export const getGroupPermissions = async (groupId, queryParams = {}) =>
  get(`/v2/groups/${groupId}/permissions`, queryParams, { isImmutable: false });

export const getGroupedGroupPermissions = (groupId, queryParams = {}) =>
  getWithHeaders(`/v2/groups/${groupId}/permissions/grouped`, queryParams, { isImmutable: false });

export const postGroup = entity => post(`/v2/groups`, entity, { isImmutable: false });

export const createBulkGroupPermissions = (groupId, entity) =>
  post(`/v2/groups/${groupId}/permissions/bulk_create`, entity, { isImmutable: false });

export const deleteBulkGroupPermissions = (groupId, queryParams) =>
  del(`/v2/groups/${groupId}/permissions/bulk_destroy`, queryParams, { isImmutable: false });

export const patchGroup = (groupId, options) =>
  patch(`/v2/groups/${groupId}`, options, {}, { isImmutable: false });

export const deleteGroup = groupId => del(`/v2/groups/${groupId}`, {}, { isImmutable: false });

export const putUpdateAllGroupShares = (groupId, companyIds) =>
  put(
    `/v2/groups/${groupId}/group_shares/update_all`,
    { group_share: { company_ids: companyIds } },
    {},
    { isImmutable: false }
  );

export const getGroupShares = groupId =>
  get(`/v2/groups/${groupId}/group_shares`, {}, { isImmutable: false });

export const deleteGroupShare = (groupId, shareId) =>
  del(`/v2/groups/${groupId}/group_shares/${shareId}`, {}, { isImmutable: false });

export const getResources = (queryParams = {}) =>
  get(`/v2/resources`, queryParams, { isImmutable: false });

export const getUserGroups = (userId, queryParams = {}) =>
  get(`/v2/users/${userId}/groups`, queryParams, { isImmutable: false });

export const postUserGroup = (userId, entity) =>
  post(`/v2/users/${userId}/groups`, entity, { isImmutable: false });

export const deleteUserGroup = (userId, groupId) =>
  del(`/v2/users/${userId}/groups/${groupId}`, {}, { isImmutable: false });

export const postCopyUsersToGroup = entity =>
  post(`/v2/groups/copy_users`, entity, { isImmutable: false });

export const postUsersToGroup = (groupId, entity) =>
  post(`/v2/groups/${groupId}/assign_users`, entity, { isImmutable: false });

export const getBlacklistedDatasets = (groupId, queryParams = {}) =>
  get(`/v2/groups/${groupId}/blacklisted_datasets`, queryParams, { isImmutable: false });

export const createBlacklistedDataset = (groupId, entity) =>
  post(`/v2/groups/${groupId}/blacklisted_datasets`, entity, { isImmutable: false });

export const postBulkCreateBlackistedDataset = (groupId, entity) =>
  post(`/v2/groups/${groupId}/blacklisted_datasets/bulk_create`, entity, { isImmutable: false });

export const deleteBlacklistedDataset = (groupId, datasetId) =>
  del(`/v2/groups/${groupId}/blacklisted_datasets/${datasetId}`);

export async function deleteWell(id) {
  return del(`/v2/wells/${id}`);
}

export async function deleteRig(id) {
  return del(`/v2/rigs/${id}`);
}

export async function deleteFracFleet(fracFleetId) {
  return del(`/v2/frac_fleets/${fracFleetId}`);
}

export async function deletePad(padId) {
  return del(`/v2/pads/${padId}`);
}

export async function deleteProgram(programId) {
  return del(`/v2/programs/${programId}`);
}

export async function deleteDrilloutUnit(drilloutUnitId) {
  return del(`/v2/drillout_units/${drilloutUnitId}`);
}

export const getGoal = (queryParams = {}) => get(`/v2/goals`, queryParams, { isImmutable: false });

export const postGoal = goalEntity =>
  post(`/v2/goals`, { goal: goalEntity }, { isImmutable: false });

export const patchGoal = (goalId, goalEntity) => {
  return patch(`/v2/goals/${goalId}`, goalEntity, {}, { isImmutable: false });
};

export const deleteGoal = goalId => {
  return del(`/v2/goals/${goalId}`, {}, { isImmutable: false });
};

/** higher order function that returns request function based on provided assetType
 * @param {string} assetType - any asset type currently supported in
 * @param {boolean} shouldFetchHeaders - if true, fetch headers will be returned
 * ~/constants/assets
 * */
export function mapAssetGetRequest(assetType, shouldFetchHeaders = false) {
  const withProperGetter = fn => (...params) =>
    shouldFetchHeaders ? fn(...params, getWithHeaders) : fn(...params);

  const requestMap = {
    rig: getRigs,
    well: getWells,
    frac_fleet: getFracFleets,
    drillout_unit: getDrilloutUnits,
    pad: getPads,
    program: getPrograms,
  };

  return withProperGetter(requestMap[assetType] || getAssets);
}

/** higher order function that returns delete function based on provided assetType
 * @param {string} assetType - any asset type currently supported in
 * ~/constants/assets
 * */
export function mapAssetDeleteRequest(assetType) {
  switch (assetType) {
    case 'rig':
      return deleteRig;
    case 'well':
      return deleteWell;
    case 'frac_fleet':
      return deleteFracFleet;
    case 'drillout_unit':
      return deleteDrilloutUnit;
    case 'pad':
      return deletePad;
    case 'program':
      return deleteProgram;
    default:
      return () => undefined;
  }
}

/** higher order function that returns post function based on provided assetType
 * @param {string} assetType - any asset type currently supported in
 * ~/constants/assets
 * */
export function mapAssetPostRequest(assetType) {
  switch (assetType) {
    case 'frac_fleet':
      return postFracFleet;
    case 'drillout_unit':
      return postDrilloutUnit;
    case 'pad':
      return postPad;
    case 'well':
      return postWell;
    case 'rig':
      return postRig;
    case 'program':
      return createProgram;
    default:
      return () => undefined;
  }
}

/** higher order function that returns patch function based on provided assetType
 * @param {string} assetType - any asset type currently supported in
 * ~/constants/assets
 * */
export function mapAssetPatchRequest(assetType) {
  switch (assetType) {
    case 'frac_fleet':
      return patchFracFleet;
    case 'drillout_unit':
      return patchDrilloutUnit;
    case 'pad':
      return patchPad;
    case 'well':
      return patchWell;
    case 'rig':
      return patchRig;
    case 'program':
      return patchProgram;
    default:
      return () => undefined;
  }
}

export const getDocumentSections = (documentId, queryParams = {}) =>
  get(`/v2/documents/${documentId}/document_sections`, queryParams, { isImmutable: false });

export const getDocument = (documentId, queryParams = {}) =>
  get(`/v2/documents/${documentId}`, queryParams, { isImmutable: false });
export const getDocuments = (queryParams = {}) =>
  get(`/v2/documents`, queryParams, { isImmutable: false });

export const postDocument = entity => post(`/v2/documents`, entity, { isImmutable: false });
export const deleteDocument = documentId =>
  del(`/v2/documents/${documentId}`, {}, { isImmutable: false });

export const patchDocument = (documentId, options) =>
  patch(`/v2/documents/${documentId}`, options, {}, { isImmutable: false });

export const getAppDatasets = (appId, queryParams = {}) =>
  get(`/v2/apps/${appId}/app_datasets`, queryParams, { isImmutable: false });

export const deleteAppDataset = (appId, appDatasetId) =>
  del(`/v2/apps/${appId}/app_datasets/${appDatasetId}`, { isImmutable: false });

export const getDatasetsFilteredByApps = (queryParams = {}) =>
  get(`/v2/datasets/filtered_by_apps`, queryParams, { isImmutable: false });

export const postAppDataset = (appId, appDatasetEntity) =>
  post(`/v2/apps/${appId}/app_datasets`, appDatasetEntity, { isImmutable: false });

export const patchAppFollowableDataset = (appId, entity) =>
  patch(`/v2/apps/${appId}/app_datasets/mark_followable`, entity, {}, { isImmutable: false });

export async function getAssetsAutocomplete({ company_id, field, search } = { field: 'area' }) {
  return get('/v2/assets/autocomplete', { company_id, field, search }, { isImmutable: false });
}

export async function getS3SignedUrl(filename, contentType, assetId = null) {
  return get(
    `/v1/file/sign?file_name=${encodeURIComponent(filename)}${
      assetId ? `&asset_id=${assetId}` : ''
    }&contentType=${contentType}`,
    {},
    { isImmutable: false }
  );
}

/**
 * Generates a pre-signed S3 download link for a given file.
 *
 * @param {string} filename The name of the file to download. Ensure the file exists in the S3 bucket.
 * @param {string} [contentDisposition='attachment'] Specifies how the content should be displayed in the browser.
 * Use "inline" to display the file in the browser or "attachment" to prompt a download dialog.
 * @param {string} [responseContentType=null] - The desired content type for the file.
 * Overrides the original file's content type. Use this carefully to avoid unexpected behavior.
 */
export async function getS3DownloadLink(
  filename,
  contentDisposition = 'attachment',
  responseContentType = null
) {
  const searchParams = new URLSearchParams({
    file_name: filename,
    response_content_disposition: contentDisposition,
  });
  if (responseContentType) {
    searchParams.set('response_content_type', responseContentType);
  }

  return get(`/v1/file/download_link?${searchParams.toString()}`, {}, { isImmutable: false });
}

export async function getFeatureAbilityCheck(options = {}) {
  return get('/v2/ability_check/check_feature', options, { isImmutable: false });
}

export async function getWellClusters(options = {}) {
  return get('/v2/wells/clusters', options, { isImmutable: false });
}

export async function getRigClusters(options = {}) {
  return get('/v2/rigs/clusters', options, { isImmutable: false });
}

export async function getProgramClusters(options = {}) {
  return get('/v2/programs/clusters', options, { isImmutable: false });
}

export async function getPadClusters(options = {}) {
  return get('/v2/pads/clusters', options, { isImmutable: false });
}

export async function getFracFleetClusters(options = {}) {
  return get('/v2/frac_fleets/clusters', options, { isImmutable: false });
}

export async function getDrilloutUnitClusters(options = {}) {
  return get('/v2/drillout_units/clusters', options, { isImmutable: false });
}

export function mapGetClustersRequest(assetType) {
  switch (assetType) {
    case 'rig':
      return getRigClusters;
    case 'well':
      return getWellClusters;
    case 'frac_fleet':
      return getFracFleetClusters;
    case 'drillout_unit':
      return getDrilloutUnitClusters;
    case 'pad':
      return getPadClusters;
    case 'program':
      return getProgramClusters;
    default:
      return () => undefined;
  }
}

export async function getDashboard(userId, dashboardSlugOrId, params = {}) {
  return get(`/v1/users/${userId}/dashboards/${dashboardSlugOrId}`, params, { isImmutable: false });
}

export async function getDashboardAvailableTimezones(dashboardId, params = {}) {
  return get(`/v2/dashboards/${dashboardId}/available_timezones`, params, {
    isImmutable: false,
  });
}

export async function postDashboard(userId, dashboard) {
  return post(`/v1/users/${userId}/dashboards`, dashboard, { isImmutable: false });
}

export async function putDashboard(userId, dashboardId, dashboard) {
  return put(
    `/v1/users/${userId}/dashboards/${dashboardId}`,
    dashboard,
    {},
    { isImmutable: false }
  );
}

export async function patchDashboard(userId, dashboardId, dashboard) {
  return patch(
    `/v1/users/${userId}/dashboards/${dashboardId}`,
    dashboard,
    {},
    { isImmutable: false }
  );
}

export async function cloneDashboard(userId, dashboardId, dashboard) {
  return post(`/v1/users/${userId}/dashboards/${dashboardId}/clone`, dashboard, {
    isImmutable: false,
  });
}

export async function deleteDashboard(userId, id) {
  return del(`/v1/users/${userId}/dashboards/${id}`, {}, { isImmutable: false });
}

export async function patchDashboardShare(id, dashboardShare) {
  return patch(`/v1/dashboard_shares/${id}`, dashboardShare, {}, { isImmutable: false });
}

export async function getDashboards(params = {}) {
  return get('/v2/dashboards', params, { isImmutable: false });
}

export async function getUserDashboards(userId, params = {}) {
  return get(`/v2/users/${userId}/dashboards`, params, { isImmutable: false });
}

export async function getDashboardShares(params = {}) {
  return get('/v1/dashboard_shares', params, { isImmutable: false });
}

export async function getFolders(userId) {
  return get(`/v2/users/${userId}/dashboard_folders`, {}, { isImmutable: false });
}

export async function postFolder(userId, folder) {
  return post(`/v2/users/${userId}/dashboard_folders`, folder, { isImmutable: false });
}

export async function patchFolder(userId, folderId, folder) {
  return patch(`/v2/users/${userId}/dashboard_folders/${folderId}`, folder, {
    isImmutable: false,
  });
}

export async function deleteFolder(userId, folderId) {
  return del(
    `/v2/users/${userId}/dashboard_folders/${folderId}`,
    {},
    {
      isImmutable: false,
    }
  );
}

export async function getDashboardFolderShares(userId, folderId) {
  return get(
    `/v2/users/${userId}/dashboard_folders/${folderId}/dashboard_shares`,
    {},
    { isImmutable: false }
  );
}

export async function postDashboardFolderShares({ userId, folderId, ...dashboardShare }) {
  return post(
    `/v2/users/${userId}/dashboard_folders/${folderId}/dashboard_shares`,
    dashboardShare,
    { isImmutable: false }
  );
}

export async function patchDashboardFolderShares({
  userId,
  folderId,
  dashboardFolderShareId,
  ...dashboardShare
}) {
  return patch(
    `/v2/users/${userId}/dashboard_folders/${folderId}/dashboard_shares/${dashboardFolderShareId}`,
    dashboardShare,
    { isImmutable: false }
  );
}

export async function deleteDashboardFolderShares({ userId, folderId, dashboardFolderShareId }) {
  return del(
    `/v2/users/${userId}/dashboard_folders/${folderId}/dashboard_shares/${dashboardFolderShareId}`,
    {},
    { isImmutable: false }
  );
}

export async function postFolderShare(payload) {
  return post('/v2/dashboard_folder_shares', payload, { isImmutable: false });
}

export async function postUseFolderShare(folderShare, folderShareId) {
  return post(`/v2/dashboard_folder_shares/${folderShareId}/use`, folderShare, {
    isImmutable: false,
  });
}

export async function getFolderShares(params) {
  return get('/v2/dashboard_folder_shares', params, { isImmutable: false });
}

export async function patchFolderShare(folderShare, folderShareId) {
  return patch(
    `/v2/dashboard_folder_shares/${folderShareId}`,
    folderShare,
    {},
    { isImmutable: false }
  );
}

export async function deleteFolderShare(folderShareId) {
  return del(`/v2/dashboard_folder_shares/${folderShareId}`, {}, { isImmutable: false });
}

export async function getProfileGroups(companyId) {
  const queryParams = { company_id: companyId };
  return get('/v1/profile_groups', queryParams, { isImmutable: false });
}

export async function postAppToDashboard(userId, dashboardId, app, dashboardAssetId) {
  const params = { isImmutable: false };
  // On Asset Dashboards we append asset id for API to calculate asset availability (Subscriptions)
  if (dashboardAssetId) params.asset_id = dashboardAssetId;

  return post(`/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps`, app, params);
}
export async function postMultipleAssetsAppToDashboard(
  userId,
  dashboardId,
  payload,
  dashboardAssetId
) {
  const params = { isImmutable: false };
  // On Asset Dashboards we append asset id for API to calculate asset availability (Subscriptions)
  if (dashboardAssetId) params.asset_id = dashboardAssetId;

  return post(
    `/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps/bulk_create`,
    payload,
    params
  );
}

export async function deleteAppFromDashboard(userId, dashboardId, appId) {
  return del(`/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps/${appId}`);
}

export async function putAppToDashboard(userId, dashboardId, app, dashboardAssetId) {
  return put(
    `/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps/${app.id}`,
    app,
    dashboardAssetId ? { asset_id: dashboardAssetId } : {},
    { isImmutable: false }
  );
}

export async function putAppsToDashboard(userId, dashboardId, apps, dashboardAssetId) {
  return put(
    `/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps`,
    {
      dashboard_apps: apps, // NOTE: Backend requirement to use field dashboards_app for now
    },
    dashboardAssetId ? { asset_id: dashboardAssetId } : {},
    { isImmutable: false }
  );
}

export async function getProfiles(query) {
  return get('/v1/profile_groups', query, { isImmutable: false });
}

export function createAppPurchase(data) {
  return post('/v2/app_purchases', data, { isImmutable: false });
}

export async function getAppPurchases(query) {
  return get('/v2/app_purchases', query, { isImmutable: false });
}

export async function getAppPurchase(id, query) {
  return get(`/v2/app_purchases/${id}`, query, { isImmutable: false });
}

export async function approveAppPurchase(id) {
  return post(`/v2/app_purchases/${id}/approve`, {}, { isImmutable: false });
}

export async function denyAppPurchase(id) {
  return post(`/v2/app_purchases/${id}/deny`, {}, { isImmutable: false });
}

export async function postResumeAppPurchase(id) {
  return post(`/v2/app_purchases/${id}/resume`, {}, { isImmutable: false });
}

export async function getPurchaseSubscriptions(query) {
  return get('/v2/purchase_subscriptions', query, { isImmutable: false });
}

export async function deletePurchaseSubscription(uuid) {
  return del(`/v2/purchase_subscriptions/${uuid}`, {}, { isImmutable: false });
}

export async function postCancelPurchaseSubscription(id) {
  return post(`/v2/purchase_subscriptions/${id}/cancel`, {}, { isImmutable: false });
}

export async function postResumePurchaseSubscription(id) {
  return post(`/v2/purchase_subscriptions/${id}/resume`, {}, { isImmutable: false });
}

export async function getPurchaseSubscription(id, query) {
  return get(`/v2/purchase_subscriptions/${id}`, query, { isImmutable: false });
}

/**
 * @param {Object} params
 * @param {string} params.assetId
 * @param {('active'|'idle')} [params.status='active']
 *
 * 'idle' - only asset info will be copied
 *
 * 'active' - in addition to asset info, streams for data processing will be started
 *
 * @param {boolean} [params.background_run=true]
 * @param {('rerun'|'well_copy')} [params.rerun_action=undefined]
 *
 */
function postAssetRerun({ assetId, background_run = true, status = 'active', ...restContent }) {
  return post(
    `/v1/assets/${assetId}/rerun`,
    {
      background_run,
      status,
      ...restContent,
    },
    {
      isImmutable: false,
    }
  );
}

export function postWellRerun(assetId, payload) {
  return postAssetRerun({ assetId, rerun_action: 'rerun', ...payload });
}

export function cloneWell(assetId) {
  return postAssetRerun({ assetId, status: 'idle', rerun_action: 'well_copy' });
}

export async function postPartialWellRerun(wellId, data) {
  return post(`/v2/wells/${wellId}/partial_reruns`, data, { isImmutable: false });
}

export async function postAppRerun(appId, params, otherParams = {}) {
  return post(`/v2/apps/${appId}/app_runs`, params, { isImmutable: false, ...otherParams });
}

export async function postAssetsCopyActivities(params) {
  return post(`/v2/assets/copy_activities`, params, { isImmutable: false });
}

export async function getAppRuns(params) {
  return get(`/v2/app_runs`, params, { isImmutable: false });
}

export async function getAppRun(appId, appRunId) {
  return get(`/v2/apps/${appId}/app_runs/${appRunId}`, {}, { isImmutable: false });
}

export async function getAppRunDuplicateCheck(appId, params) {
  return get(`/v2/apps/${appId}/app_runs/duplicate_check`, params, { isImmutable: false });
}

export async function patchStopAppRun(appId, appRunId) {
  return patch(`/v2/apps/${appId}/app_runs/${appRunId}/stop`, {}, {}, { isImmutable: false });
}

export async function deleteAppRun(appId, appRunId) {
  return del(`/v2/apps/${appId}/app_runs/${appRunId}`);
}

export async function getAlerts(query) {
  return get(`/v1/alerts`, query, { isImmutable: false });
}

export async function getAlertsTotal(query) {
  return get(`/v1/alerts/totals`, query, { isImmutable: false });
}

export async function getAlertsDetails(query) {
  return get(`/v1/alerts/details`, query, { isImmutable: false });
}

export async function postAppConnection(payload) {
  return post('/v1/app_connections', payload, { isImmutable: false });
}

export async function patchAppConnection(appId, appConnection) {
  return patch(
    `/v1/app_connections/${appId}/update_package`,
    appConnection,
    {},
    { isImmutable: false }
  );
}

export function getWorkflows(params) {
  return get('/v2/workflows', params, { isImmutable: false });
}

export function getWorkflowsWithHeaders(params) {
  return getWithHeaders('/v2/workflows', params, { isImmutable: false });
}

export function postWorkflow(workflow) {
  return post('/v2/workflows', workflow, { isImmutable: false, hideNotification: true });
}

export function getWorkflow(workflowId) {
  return get(`/v2/workflows/${workflowId}`, {}, { isImmutable: false });
}

export function patchWorkflow(workflowId, workflow) {
  return patch(
    `/v2/workflows/${workflowId}`,
    workflow,
    {},
    { isImmutable: false, hideNotification: true }
  );
}

export function deleteWorkflow(workflowId) {
  return del(`/v2/workflows/${workflowId}`, {}, { isImmutable: false });
}

export function getWorkflowFileUploadLink(fileName, contentType) {
  return get(
    `/v2/workflows/signed_url`,
    { file_name: fileName, contentType },
    { isImmutable: false }
  );
}

export function getWorkflowApps(workflowId) {
  return get(`/v2/workflows/${workflowId}/workflow_apps`, {}, { isImmutable: false });
}

export function putWorkflowApps(workflowId, workflowApps) {
  return put(
    `/v2/workflows/${workflowId}/workflow_apps`,
    { workflow_apps: workflowApps },
    {},
    { isImmutable: false }
  );
}

export function deleteDashboardWorkflow(userId, dashboardId, dashboardWorkflowId) {
  return del(
    `/v2/users/${userId}/dashboards/${dashboardId}/dashboard_workflows/${dashboardWorkflowId}`,
    {},
    { isImmutable: false }
  );
}

export function postUngroupDashboardWorkflow(userId, dashboardId, dashboardWorkflowId) {
  return post(
    `/v2/users/${userId}/dashboards/${dashboardId}/dashboard_workflows/${dashboardWorkflowId}/ungroup`,
    {},
    { isImmutable: false }
  );
}

export function putDashboardWorkflowAssetSettings(
  userId,
  dashboardId,
  dashboardWorkflowId,
  assetSettings
) {
  return put(
    `/v2/users/${userId}/dashboards/${dashboardId}/dashboard_workflows/${dashboardWorkflowId}/change_asset`,
    { new_asset: assetSettings },
    {},
    { isImmutable: false }
  );
}

export function postUngroupAllDashboardWorkflows(userId, dashboardId) {
  return post(
    `/v2/users/${userId}/dashboards/${dashboardId}/dashboard_workflows/ungroup_all`,
    {},
    { isImmutable: false }
  );
}

export const getAppStoreBuilderTemplates = params => {
  return get(`/v2/app_store_templates`, params, { isImmutable: false });
};

export const postAppStoreBuilderTemplate = template => {
  return post(
    `/v2/app_store_templates`,
    {
      app_store_template: template,
    },
    { isImmutable: false }
  );
};

export const delAppStoreBuilderTemplate = id => {
  return del(`/v2/app_store_templates/${id}`, {}, { isImmutable: false });
};

export const patchAppStoreBuilderTemplate = template => {
  return patch(`/v2/app_store_templates/${template.id}`, template, {}, { isImmutable: false });
};

export const getAppStoreBuilderSections = params => {
  return get(`/v2/app_store_template_sections`, params, { isImmutable: false });
};

export const postAppStoreBuilderSection = section => {
  return post(
    `/v2/app_store_template_sections`,
    {
      app_store_template_section: section,
    },
    { isImmutable: false }
  );
};

export const patchAppStoreBuilderSection = section => {
  return patch(
    `/v2/app_store_template_sections/${section.id}`,
    { app_store_template_section: section },
    {},
    { isImmutable: false }
  );
};

export const delAppStoreBuilderSection = id => {
  return del(`/v2/app_store_template_sections/${id}`, {}, { isImmutable: false });
};

export function getAppStoreArticles(params) {
  return get('/v2/app_store_articles', params, { isImmutable: false });
}

export function getAppStoreArticlesWithHeaders(params) {
  return getWithHeaders('/v2/app_store_articles', params, { isImmutable: false });
}

export function postAppStoreArticle(article) {
  return post('/v2/app_store_articles', { app_store_article: article }, { isImmutable: false });
}

export function getAppStoreArticle(articleId) {
  return get(`/v2/app_store_articles/${articleId}`, {}, { isImmutable: false });
}

export function patchAppStoreArticle(articleId, article) {
  return patch(
    `/v2/app_store_articles/${articleId}`,
    { app_store_article: article },
    {},
    { isImmutable: false }
  );
}

export function deleteAppStoreArticle(articleId) {
  return del(`/v2/app_store_articles/${articleId}`, {}, { isImmutable: false });
}

export function getSignedUrl(folder, fileName, contentType) {
  return get(
    `/v2/signed_url`,
    { folder, file_name: fileName, contentType },
    { isImmutable: false }
  );
}

export function fetchColumnMapper(assetId, options = {}) {
  return get(
    `/v2/assets/${assetId}/settings`,
    { app_key: 'corva.column-mapper', segment: 'drilling', ...options },
    { isImmutable: false }
  );
}

export const getPermissionsAudit = queryParams =>
  getWithHeaders('/v2/permission_audits', queryParams, { isImmutable: false });

export const getProvisioningSubscriptionsAudit = queryParams =>
  getWithHeaders('/v2/provisioning_subscription_audits', queryParams, { isImmutable: false });

export const getPlatformSubscriptionsAudit = queryParams =>
  getWithHeaders('/v2/platform_subscription_audits', queryParams, { isImmutable: false });

export const getPurchaseSubscriptionsAudit = queryParams =>
  getWithHeaders('/v2/purchase_subscription_audits', queryParams, { isImmutable: false });

export const getDatasetsAudit = queryParams =>
  getWithHeaders('/v2/dataset_audits', queryParams, { isImmutable: false });

export const getPackageAudit = queryParams =>
  getWithHeaders('/v2/package_audits', queryParams, { isImmutable: false });

export const getAPIKeysAudit = queryParams =>
  getWithHeaders('/v2/api_key_audits', queryParams, { isImmutable: false });

export const getColumnMapperTemplates = (queryParams = {}) => {
  return get('/v2/column_mapper_templates', queryParams, { isImmutable: false });
};

export const getColumnMapperTemplatesWithHeaders = (queryParams = {}) => {
  return getWithHeaders('/v2/column_mapper_templates', queryParams, { isImmutable: false });
};

export const getColumnMapperTemplate = id => {
  return get(`/v2/column_mapper_templates/${id}`, {}, { isImmutable: false });
};

export const deleteColumnMapperTemplate = id => {
  return del(`/v2/column_mapper_templates/${id}`);
};

export const postColumnMapperTemplate = data => {
  return post('/v2/column_mapper_templates', data, { isImmutable: false, hideNotification: true });
};

export const patchColumnMapperTemplate = (id, data) => {
  return patch(
    `/v2/column_mapper_templates/${id}`,
    data,
    {},
    {
      isImmutable: false,
      hideNotification: true,
    }
  );
};

export async function getWorkflowRoles(queryParams) {
  return get('/v2/workflow_roles', queryParams, { isImmutable: false });
}

export const fetchCollection = (collection, assetId, extraParams = {}) => {
  const params = {
    limit: 100,
    sort: '{timestamp: -1}',
  };

  return getAppStorage('corva', collection, assetId, { ...params, ...extraParams });
};

export const getAppStreamTemplates = options => {
  return get('/v2/app_stream_templates', options, { isImmutable: false });
};
