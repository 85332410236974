import { push, replace } from 'react-router-redux';
import { fromJS, Map as ImmutableMap } from 'immutable';
import queryString from 'query-string';
import { sharedDCStore, updateUserUnits } from '@corva/ui/utils';
import { isMobileDetected, isNativeDetected } from '@corva/ui/utils/mobileDetect';
import * as jsonApi from '@corva/ui/clients/jsonApi';
import { difference, isEqual } from 'lodash';

import * as api from '~/clients/api';
import * as clientStorage from '~/clients/clientStorage';
import { makeAppReady, resetAppState, start } from '~/actions/main';
import { showSuccessNotification } from '~/actions/notificationToasts';
import { disconnect } from '~/actions/subscriptions';
import { updateIncidents } from '~/actions/incidents';
import { configurePerson as configureRollbarPerson } from '~/utils/rollbar';
import { startIntercomSession, stopIntercomSession } from '~/utils/intercom';
import { isReportsPage } from '~/utils/reports';
import { trackUserToMixpanel } from '~/actions/mixpanel';
import { initializeBeamer } from '~/utils/beamer';
import { initializeSnowplow } from '~/utils/snowplow';
import { initializeWootricNPS } from '~/utils/initializeWootricNPS';
import { removeOriginalUserData, setOriginalUserData } from '~/utils/impersonation';

import {
  ACCEPT_TERMS_SUCCESS,
  INVALID_VERIFICATION_CODE,
  LOG_IN,
  LOGGED_IN,
  LOGIN_ACCOUNT_LOCKED_FAILURE,
  LOGIN_API_FAILURE,
  LOGIN_AUTH_FAILED,
  LOGIN_TERMS_FAILURE,
  MISSING_VERIFICATION_CODE,
  RELOAD_CURRENT_USER,
  UPDATE_CURRENT_USER_SETTINGS,
} from '~/constants/login';
import {
  currentUserSelector,
  currentUserSingleAssetSelector,
  isImpersonatingSelector,
} from '~/selectors/login';
import { APIException } from '~/clients/api/apiCore';

import { ActionCreator, AppDispatch } from './types';

import {
  CompletionSingleAssetSettings,
  DrillingSingleAssetSettings,
  DrilloutUnitSingleAssetSettings,
  SingleAsset,
  SingleAssetSettings,
} from '../screens/App/screens/Main/screens/Dashboards/screens/DashboardsPage/components/DashboardInfoStore/types';
import { ExtendedSegment } from '~/types/segments';

type User = ImmutableMap<string, any>;

const loggingIn = () => ({ type: LOG_IN });
const loggedIn = (user: User) => ({ type: LOGGED_IN, user });
const loginAuthFailed = () => ({ type: LOGIN_AUTH_FAILED });
const loginApiFailed = () => ({ type: LOGIN_API_FAILURE });
const loginTermsFailed = () => ({ type: LOGIN_TERMS_FAILURE });
const accountLocked = () => ({ type: LOGIN_ACCOUNT_LOCKED_FAILURE });
const acceptTermsSuccess = () => ({ type: ACCEPT_TERMS_SUCCESS });
const invalidVerificationCode = () => ({ type: INVALID_VERIFICATION_CODE });
const missingVerificationCode = () => ({ type: MISSING_VERIFICATION_CODE });

const getJSValueOrNull = (immutableObject: ImmutableMap<string, any>, path: string[]) =>
  immutableObject?.getIn(path)?.toJS() ?? null;

function getUserUnits(user: User) {
  if (!user) return {};

  return {
    userUnits: getJSValueOrNull(user, ['unit_system']),
    companyUnits: getJSValueOrNull(user, ['company', 'unit_system']),
    companyCustomUnitSystem: getJSValueOrNull(user, ['company', 'custom_unit_system']),
    userCustomUnitSystem: getJSValueOrNull(user, ['custom_unit_system']),
    companyCustomUnits: getJSValueOrNull(user, ['company', 'custom_units']),
  };
}

function finishLogIn(dispatch: AppDispatch, user: User) {
  dispatch(start());
  const isDesktop = !isNativeDetected && !isMobileDetected;
  const isImpersonating = user.get('impersonating');

  if (isDesktop && !isReportsPage) startIntercomSession(user);

  if (!isImpersonating && !isReportsPage) {
    initializeSnowplow(user);
    initializeBeamer(user);
    initializeWootricNPS(user);
  }

  configureRollbarPerson(user);
  // @ts-ignore
  updateUserUnits(getUserUnits(user));
  dispatch(loggedIn(user));
  dispatch(makeAppReady());
}

function finishLogOut(dispatch: AppDispatch) {
  const isDesktop = !isNativeDetected && !isMobileDetected;
  if (isDesktop) stopIntercomSession();
  dispatch(resetAppState());
  // NOTE: logout clears whole state, but the incidents should stay
  dispatch(updateIncidents());
  disconnect();
  // @ts-ignore
  const socketClient = window[Symbol.for('socket')];
  if (socketClient) socketClient.disconnect();
  // @ts-ignore
  const resetPermissionsCache = window[Symbol.for('resetPermissionsCache')];
  if (resetPermissionsCache) resetPermissionsCache();
  // eslint-disable-next-line no-underscore-dangle
  sharedDCStore.__removeSharedDCStores();
}

function handleAuthError(dispatch: AppDispatch, err: APIException) {
  if (err.isAuthenticationProblem?.()) {
    if (err.isTermsProblem?.()) {
      dispatch(loginTermsFailed());
      dispatch(push('/login/terms-of-service'));
    } else if (err.isLockedAccountProblem?.()) {
      dispatch(accountLocked());
    } else if (err.isInvalidVerificationCode?.()) {
      dispatch(invalidVerificationCode());
    } else if (err.isMissingVerificationCode?.()) {
      dispatch(missingVerificationCode());
    } else if (err.isLoginProblem?.()) {
      dispatch(loginAuthFailed());
    } else {
      dispatch(loginApiFailed());
    }
  } else {
    dispatch(loginApiFailed());
  }
}

function login(request: Promise<void>, redirectLocation: string): ActionCreator {
  return async dispatch => {
    dispatch(loggingIn());
    localStorage.setItem('redirectURI', redirectLocation || '/');

    try {
      await request;
    } catch (error) {
      handleAuthError(dispatch, error);
      return;
    }

    let user = null;
    try {
      user = await jsonApi.getCurrentUser();
    } catch (error) {
      handleAuthError(dispatch, error);
      return;
    }

    const immutableUser = fromJS(user);

    finishLogIn(dispatch, immutableUser);

    // External URL - redirect with document.location
    const isAbsoluteUrl = (redirectLocation || '').match(/^https?:\/\//);
    if (isAbsoluteUrl) {
      document.location = redirectLocation;
    } else {
      dispatch(push(redirectLocation === '/' ? '/dashboards' : redirectLocation));
    }

    trackUserToMixpanel(immutableUser);
    localStorage.removeItem('redirectURI');
  };
}

export function loginWithPassword(
  email: string,
  password: string,
  redirectLocation = '/',
  recaptchaToken = '',
  verificationCode: string,
  verificationMode: string
) {
  return login(
    api.loginWithPassword(email, password, recaptchaToken, verificationCode, verificationMode),
    redirectLocation
  );
}

export function loginWithAuth0Token(platform: string, token: string, redirectLocation = '/') {
  return login(jsonApi.loginWithAuth0Token(platform, token), redirectLocation);
}

export function acceptTermsOfService({
  consentToProcessData,
}: {
  consentToProcessData: boolean;
}): ActionCreator {
  return async dispatch => {
    let user;

    try {
      user = await api.acceptTermsOfService();
      if (consentToProcessData) {
        user = await api.patchUser(user.get('id'), {
          consent_to_process_data: consentToProcessData,
        });
      }
    } catch (err) {
      return;
    }

    dispatch(acceptTermsSuccess());
    dispatch(showSuccessNotification('Terms of service were accepted'));
    finishLogIn(dispatch, user);
    dispatch(push('/'));
    trackUserToMixpanel(user);
  };
}

export const LOG_OUT = 'login/LOG_OUT';
export function logout(): ActionCreator {
  return async dispatch => {
    await api.logout();
    clientStorage.stopImpersonation();

    finishLogOut(dispatch);
    removeOriginalUserData();
    dispatch(
      push({ pathname: '/login', state: { pathname: localStorage.getItem('redirectURI') } })
    );
  };
}

export function loginCheck(
  { search, pathname }: { search: string; pathname: string },
  { onCheckFinish }: { onCheckFinish: () => void }
): ActionCreator {
  return async (dispatch, getState) => {
    const isReportUnsubscribeRoute = pathname.startsWith('/login/report-unsubscribe');

    try {
      // Used by mobile web view & BE side during reports generation
      const { jwt: passedByQueryJwt } = queryString.parse(window.location.search);
      if (passedByQueryJwt) {
        await api.loginWithCorvaJWT(passedByQueryJwt);
      }

      // START: A FALLBACK FOR MOBILE APP DURING JWT IN COOKIES RELEASE.
      // WILL BE REMOVED AFTER THE SUCCESSFUL RELEASE
      let jwtPassedFromLocalStore = localStorage.getItem('jwt');

      if (/^".*"$/.test(jwtPassedFromLocalStore)) {
        jwtPassedFromLocalStore = jwtPassedFromLocalStore.slice(1, -1);
      }

      if (jwtPassedFromLocalStore) {
        try {
          await api.loginWithCorvaJWT(jwtPassedFromLocalStore);
        } catch (error) {
          console.error(error);
        }
        localStorage.removeItem('jwt');
      }
      // END: A FALLBACK FOR MOBILE APP DURING JWT IN COOKIES RELEASE

      let user;
      try {
        user = await jsonApi.getCurrentUser();
      } catch (error) {
        const isTermsRoute = pathname.startsWith('/login/terms-of-service');
        if (error.isTermsProblem?.() && !isTermsRoute) {
          dispatch(loginTermsFailed());
          dispatch(push('/login/terms-of-service'));
          onCheckFinish();
          return;
        }
      }

      const isLoginRoute = pathname.startsWith('/login');

      if (isLoginRoute && !isReportUnsubscribeRoute) {
        if (user) {
          dispatch(push('/'));
        }

        onCheckFinish();
        return;
      }

      if (user) {
        const immutableUser = fromJS(user);
        finishLogIn(dispatch, immutableUser);

        const impersonating = isImpersonatingSelector(getState());

        if (!impersonating) {
          trackUserToMixpanel(immutableUser);
        }
      } else {
        if (isReportUnsubscribeRoute) {
          onCheckFinish();
          return;
        }
        clientStorage.stopImpersonation();
        removeOriginalUserData();
        dispatch(push({ pathname: '/login', state: { search, pathname } }));
        dispatch(makeAppReady());
      }
    } catch (e) {
      jsonApi.logout();
      clientStorage.stopImpersonation();
      removeOriginalUserData();
      dispatch(push({ pathname: '/login', state: { search, pathname } }));
      dispatch(makeAppReady());
      // If it IS an authentication problem, then they just need to log in.
      if (!e.isAuthenticationProblem || !e.isAuthenticationProblem()) {
        // otherwise display API error on the login page.
        dispatch(loginApiFailed());
      }
    }
    onCheckFinish();
  };
}

/**
 * Impersonation redux action
 * @param {Number} userId - user id to impersonate
 * @param {Object} options.redirectURL - the URL to redirect after stopping impersonation
 * @param {Object} options.currentUser - the user which started impersonation
 */
export function startImpersonation(
  userId: number,
  options: { redirectURL: string; currentUser: any } = {
    redirectURL: null,
    currentUser: null,
  }
): ActionCreator {
  return async dispatch => {
    try {
      setOriginalUserData(options.currentUser);
      await api.startImpersonation(userId);
      clientStorage.startImpersonation({ redirectURL: options.redirectURL || '/config/users' });

      finishLogOut(dispatch);

      const user = await jsonApi.getCurrentUser();

      if (!user) {
        throw new Error('user is not logged in');
      }

      const immutableUser = fromJS(user);

      stopIntercomSession();

      finishLogIn(dispatch, immutableUser);
      dispatch(showSuccessNotification('Impersonation started'));
      dispatch(push('/dashboards'));
    } catch (e) {
      handleAuthError(dispatch, e);
    }
  };
}

export function stopImpersonation(): ActionCreator {
  return async dispatch => {
    try {
      await api.stopImpersonation();
      const redirectURL = clientStorage.getImpersonationRedirectURL() || '/config/users';
      clientStorage.stopImpersonation();

      finishLogOut(dispatch);
      removeOriginalUserData();

      const user = await jsonApi.getCurrentUser();

      if (!user) {
        throw new Error('user is not logged in');
      }

      const immutableUser = fromJS(user);

      finishLogIn(dispatch, immutableUser);
      startIntercomSession(immutableUser);
      dispatch(push(redirectURL));
      dispatch(showSuccessNotification('Impersonation stopped'));
      trackUserToMixpanel(immutableUser);
    } catch (e) {
      handleAuthError(dispatch, e);
    }
  };
}

export function reloadCurrentUser(): ActionCreator {
  return async dispatch => {
    let user;
    try {
      user = await jsonApi.getCurrentUser();
    } catch (error) {
      handleAuthError(dispatch, error);
      return;
    }

    if (!user) return;

    const immutableUser = fromJS(user);

    configureRollbarPerson(immutableUser);
    // @ts-ignore
    updateUserUnits(getUserUnits(immutableUser));
    dispatch({ type: RELOAD_CURRENT_USER, user: immutableUser });
  };
}

export function setCurrentSingleAsset(
  singleAssetUpdate: SingleAssetSettings,
  segment: ExtendedSegment
): ActionCreator {
  return async (dispatch, getState) => {
    if (
      !(
        (singleAssetUpdate as CompletionSingleAssetSettings).fracFleetId ||
        (singleAssetUpdate as CompletionSingleAssetSettings).padId ||
        (singleAssetUpdate as CompletionSingleAssetSettings).completionWellAssetId
      ) &&
      !(singleAssetUpdate as DrillingSingleAssetSettings).rigId &&
      !(singleAssetUpdate as DrilloutUnitSingleAssetSettings).drilloutUnitId
    )
      return;

    const currentSingleAssetSettings = currentUserSingleAssetSelector(getState());
    const nextSettings = {
      ...currentSingleAssetSettings,
      ...singleAssetUpdate,
    };

    if (isEqual(nextSettings, currentSingleAssetSettings)) return;

    dispatch(setCurrentUserSettings('singleAsset', nextSettings));
    updateAssetIdInUrl(dispatch, singleAssetUpdate, segment);
  };
}

export const pickSingleAssetFieldsBySegment = (
  singleAssetUpdate: SingleAsset,
  segment: ExtendedSegment
) => {
  if (segment === 'drilling') {
    return {
      rigId: singleAssetUpdate.rigId || null,
      rigAssetId: singleAssetUpdate.rigAssetId || null,
      wellId: singleAssetUpdate.wellId || null,
      wellAssetId: singleAssetUpdate.wellAssetId || null,
    };
  } else if (segment === 'completion') {
    return {
      fracFleetId: singleAssetUpdate.fracFleetId || null,
      padId: singleAssetUpdate.padId || null,
      completionWellAssetId: singleAssetUpdate.completionWellAssetId || null,
    };
  } else if (segment === 'drillout') {
    return { drilloutUnitId: singleAssetUpdate.drilloutUnitId || null };
  }

  return {};
};

export const singleAssetFieldsBySegment: Record<ExtendedSegment, string[]> = {
  drilling: ['rigId', 'wellId', 'rigAssetId', 'wellAssetId'],
  completion: ['fracFleetId', 'padId', 'completionWellAssetId'],
  drillout: ['drilloutId'],
};

export const allSingleAssetFields = Object.values(singleAssetFieldsBySegment).flat();

export const fieldsToCleanup: Record<ExtendedSegment, string[]> = {
  drilling: difference(allSingleAssetFields, singleAssetFieldsBySegment.drilling),
  completion: difference(allSingleAssetFields, singleAssetFieldsBySegment.completion),
  drillout: difference(allSingleAssetFields, singleAssetFieldsBySegment.drillout),
};

function cleanupSearchParams(
  searchParams: SingleAssetSettings,
  segment: ExtendedSegment
): SingleAssetSettings {
  const cleanedSearchParams = { ...searchParams };

  fieldsToCleanup[segment].forEach(field => {
    // @ts-ignore
    delete cleanedSearchParams[field];
  });

  return cleanedSearchParams;
}

export function getUpdatedUrl(
  singleAssetUpdate: SingleAssetSettings,
  segment: ExtendedSegment
): string {
  const url = new URL(window.location.href);
  const searchParams = Object.fromEntries(
    Object.entries(new URLSearchParams(url.search))
  ) as SingleAssetSettings;

  const currentUrlSingleAsset = pickSingleAssetFieldsBySegment(
    searchParams as SingleAsset,
    segment
  );
  const nextUrlSingleAsset = pickSingleAssetFieldsBySegment(singleAssetUpdate, segment);

  if (isEqual(currentUrlSingleAsset, nextUrlSingleAsset)) return null;

  // Ensure all values are strings when creating URLSearchParams
  const cleanedSearchParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(cleanupSearchParams(searchParams, segment)).map(([key, value]) => [
        key,
        String(value),
      ])
    )
  );

  Object.entries(nextUrlSingleAsset).forEach(([key, value]) =>
    cleanedSearchParams.set(key, String(value))
  );

  return `${url.pathname}?${cleanedSearchParams.toString()}`;
}

function updateAssetIdInUrl(
  dispatch: AppDispatch,
  singleAssetUpdate: SingleAssetSettings,
  segment: ExtendedSegment
) {
  const nextUrl = getUpdatedUrl(singleAssetUpdate, segment);
  if (!nextUrl) return;

  dispatch(replace(nextUrl));
}

export function setCurrentUserSettings(
  setting: Record<string, any> | string,
  value?: any
): ActionCreator {
  return async (dispatch, getState) => {
    const user = currentUserSelector(getState());

    if (!user) return;

    const userId = user.get('id');

    // NOTE: Multiple settings set support
    const settings =
      arguments.length === 2 ? { [setting as string]: value } : (setting as Record<string, any>);

    try {
      await api.setCurrentUserSettings(userId, settings);
    } catch (e) {
      // Ignore error
      return;
    }

    dispatch(reloadCurrentUser());
  };
}

export const updateCurrentUserSettings = (settings: Record<string, any>) => ({
  type: UPDATE_CURRENT_USER_SETTINGS,
  settings,
});

export function setUserSegment(segment: 'drilling' | 'completion'): ActionCreator {
  return async (dispatch, getState) => {
    const user = currentUserSelector(getState());

    if (!user) {
      return;
    }

    const userId = user.get('id');
    const impersonating = user.get('impersonating');
    let newUser;

    try {
      newUser = await api.patchUser(userId, { current_segment: segment });
    } catch (e) {
      // Do not dispatch when patching failed.
    }

    if (!newUser) {
      return;
    }

    const userWithImpersonation = newUser.set('impersonating', impersonating);

    dispatch({ type: RELOAD_CURRENT_USER, user: userWithImpersonation });
  };
}
