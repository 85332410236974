export const SELECT_NEW_COMPANY = 'SET_COMPANY';
export const SELECT_NEW_RIG = 'SELECT_NEW_RIG';
export const SELECT_NEW_WELL = 'SELECT_NEW_WELL';
export const SELECT_NEW_LOGS = 'SELECT_NEW_LOGS';
export const CANCEL_WITSML_CONNECTION = 'CANCEL_WITSML_CONNECTION';
export const SET_SERVER_ID = 'SET_SERVER_ID';
export const SET_TARGET_WELL = 'SET_TARGET_WELL';
export const SELECT_WELLBORE = 'SELECT_WELLBORE';

export const SET_COMPANIES = 'INIT';
export const SET_RIGS = 'SET_RIGS';
export const SET_WELLS = 'SET_WELLS';

export const BULK_UPDATE = 'BULK_UPDATE';
