import { browserHistory } from 'react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';

import loginMiddleware from '~/middlewares/login';

import rootReducer from './rootReducer';

// NOTE: In order to use Redux DebTools Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(loginMiddleware, thunk, routerMiddleware(browserHistory)))
);

export default store;
