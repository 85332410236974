import { push } from 'react-router-redux';
import get from 'lodash/get';
import * as notificationToastsHelpers from '@corva/ui/utils/notificationToasts';
import { notifications as notificationsConstants } from '@corva/ui/constants';
import { SEGMENTS } from '@corva/ui/constants/segment';
import { playNotificationSound } from '@corva/ui/utils/audio';

import notificationSoundURL from '~/assets/audio/notification.mp3';
import Avatar from '~/screens/App/shared/components/Avatar';
import AlertNotificationContent from '~/screens/App/shared/components/Notifications/AlertNotificationContent';

import { currentUserSelector } from '~/selectors/login';

const { NOTIFICATION_VARIANTS } = notificationsConstants;

const emptyAction = { type: null };

export function showSuccessNotification(message, options = {}) {
  notificationToastsHelpers.showSuccessNotification(message, options);
  return emptyAction;
}

export function showErrorNotification(message, options = {}) {
  notificationToastsHelpers.showErrorNotification(message, options);
  return emptyAction;
}

export function showWarningNotification(message) {
  notificationToastsHelpers.showWarningNotification(message);
  return emptyAction;
}

export function showInfoNotification(message) {
  notificationToastsHelpers.showInfoNotification(message);
  return emptyAction;
}

export function showAlertNotification(alert) {
  return (_dispatch, getState) => {
    const alertName = alert.getIn(['alert_definition', 'name']);
    const alertLevelKey = alert.getIn(['alert_definition', 'level'], 'info').toLowerCase();
    const alertId = alert.get('id');
    const alertDecisionPath = alert.get('decision_path');
    const wellName = alert.getIn(['asset', 'name']);
    const isCompletionAlert = alert.get('segment')?.includes(SEGMENTS.COMPLETION);
    const parentAssetName = isCompletionAlert
      ? alert.getIn(['asset', 'frac_fleet_name'])
      : alert.getIn(['asset', 'parent_asset', 'name']) ||
        alert.getIn(['asset', 'parent_asset_name']);
    const stages = alert.get('stage_numbers', []).join();

    const notification = {
      content: (
        <AlertNotificationContent
          alertId={alertId}
          alertName={alertName}
          alertDecisionPath={alertDecisionPath}
          parentAssetName={parentAssetName}
          wellName={wellName}
          stages={stages}
        />
      ),
      variant: 'info',
      hideIcon: true,
      alertLevel: alertLevelKey,
      autoHideDuration: 10000,
    };

    const currentUser = currentUserSelector(getState());
    const playAlertSound = currentUser.getIn(['preference', 'play_alerts_sound']);

    if (playAlertSound) {
      playNotificationSound(new Audio(notificationSoundURL));
    }

    notificationToastsHelpers.createNotification(notification);
  };
}

export function showDatasetExportNotification({ content, title }) {
  return () => {
    const notification = {
      autoHideDuration: Infinity,
      content,
      variant: NOTIFICATION_VARIANTS.neutral,
      title,
    };

    notificationToastsHelpers.createNotification(notification);
  };
}

export function showMentionNotification(notificationItem) {
  return dispatch => {
    const { message, status } = notificationItem;

    let variant = NOTIFICATION_VARIANTS.info;
    if (status === 'approved') variant = NOTIFICATION_VARIANTS.success;
    else if (status === 'rejected') variant = NOTIFICATION_VARIANTS.error;

    const notification = {
      icon: (
        <Avatar
          displayName={message}
          imgSrc={get(notificationItem, ['trigger', 'user', 'profile_photo'])}
          size={20}
        />
      ),
      autoHideDuration: 3000,
      content: get(notificationItem, ['trigger', 'body']),
      variant,
      title: message,
      onClick: () => dispatch(push('/notifications')),
    };

    if (variant === NOTIFICATION_VARIANTS.success || variant === NOTIFICATION_VARIANTS.error) {
      notification.title = null;
      notification.content = message;
    } else {
      // FIXME: Just to keep old styles for alert & mention
      notification.alertLevel = 'mention-alert';
    }

    notificationToastsHelpers.createNotification(notification);
  };
}
