import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore, push } from 'react-router-redux';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { theme, initHighcharts } from '@corva/ui/config';
import '@corva/ui/config/extendNative';
import { OpenFeatureProvider } from '@openfeature/react-sdk';

import { logToMixpanel } from '~/actions/mixpanel';
import { initIntercom } from '~/utils/intercom';
import { historyPush } from '~/utils/router';
import '~/utils/markerio';

import './config/initEcharts';
import './config/emoji-mart';
import './config/rollbar';
import './config/ga';
import './config/lightbox';
import './config/mixpanel';
import './config/moment';
import './config/mapbox';
import './config/themeVariables';
import './config/openFeature';
import routes from './config/routes';
import store from './config/store';
import './globalStyles';

initHighcharts();
initIntercom();

const history = syncHistoryWithStore(browserHistory, store);
history.push = historyPush;

// NOTE: Programmatic navigation utility for native apps
window.navigateAppTo = path => {
  store.dispatch(push(path));
};

// NOTE: Workaround to access store outside Redux from 'utils.js' and dispatch
// actions from 'api.js'. 'import' breaks logic
// Need to refactor 'api.js' using Redux architecture (reducers and actions)
window.reduxStore = store;
window[Symbol.for('logToMixpanel')] = (...args) => store.dispatch(logToMixpanel(...args));

const generateClassName = createGenerateClassName({
  productionPrefix: 'core',
});

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme.darkTheme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <OpenFeatureProvider>
            <Router history={history} routes={routes} />
          </OpenFeatureProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);
