import * as queryString from 'query-string';
import { List, Map } from 'immutable';
import omitBy from 'lodash/omitBy';

import { segment as segmentConstants } from '@corva/ui/constants';
import * as jsonApi from '@corva/ui/clients/jsonApi';
import { createTask } from '~/clients/jsonApi';

import * as clientStorage from '../clientStorage';
import { del, get, getFile, getWithHeaders, patch, post, put } from './apiCore';

const { SEGMENTS } = segmentConstants;

export function loginWithPassword(
  email,
  password,
  recaptchaToken,
  verificationCode,
  verificationMode
) {
  const params = { email, password };
  if (recaptchaToken) params.recaptcha_token = recaptchaToken;
  if (verificationCode) params.verification_code = verificationCode;
  if (verificationCode) params.verification_mode = verificationMode;

  return post('/v1/user_token', { auth: params });
}

/*
Note: this endpoint is used when we already have Corva token
but need to login a user to the web part - during reports generation
when BE opens web view to make a screenshot or
in Corva mobile web view.
*/
export function loginWithCorvaJWT(token) {
  return post('/v1/user_token', {
    auth: {
      jwt_token: token,
    },
  });
}

export function acceptTermsOfService() {
  return put('/v1/users/accept_tos');
}

export async function logout() {
  // FIXME: Now in the app all settings that are wrote to local storage getting to it's root. It
  // may cause an issue when user1 logs out and user2 logs in. All settings from local storage of
  // user1 will be applied to user2
  //
  // Also clearing settings from clientStorage on log out provides bad UX for user
  //
  // We should store setting in clientStorage by user id. This way we don't need to clear
  // settings on logout
  clientStorage.removeAssetPageSettings();
  clientStorage.removeFeedInputRigId();

  return jsonApi.logout();
}

export async function startImpersonation(userId) {
  return post('/v1/impersonated_sessions', { user_id: userId });
}

export async function stopImpersonation() {
  return post('/v1/impersonated_sessions/stop');
}

export async function requestVerification(params) {
  return post('/v1/sessions/verification/request', params);
}

export async function getCurrentUserRecentAssets() {
  return get('/v1/users/current/recent_assets');
}

export async function getAlerts({
  page,
  perPage,
  start,
  end,
  levels,
  segment,
  assets,
  rigs,
  validation,
  classification,
  name,
  subscription,
} = {}) {
  const qry = queryString.stringify(
    omitBy(
      {
        page,
        per_page: perPage,
        start: start || undefined,
        end: end || undefined,
        levels,
        segment,
        rigs,
        validation,
        alert_classification: classification,
        name,
        subscription,
        assets,
      },
      v => v === 'all'
    )
  );

  return get(`/v1/alerts?${qry}`);
}

export async function alertCheck(
  alertId,
  { start, end, levels, rigs, validation, name, subscription, classification } = {}
) {
  const qry = queryString.stringify(
    omitBy(
      {
        start: start || undefined,
        end: end || undefined,
        levels,
        rigs,
        validation,
        name,
        subscription,
        alert_classification: classification,
      },
      v => v === 'all'
    )
  );

  return post(`/v1/alerts/${alertId}/check?${qry}`);
}

export async function getAlert(id) {
  return get(`/v1/alerts/${id}`);
}

export async function patchAlert(alertId, alert) {
  return patch(`/v1/alerts/${alertId}`, alert);
}

export async function deleteAlert(alertId) {
  return del(`/v1/alerts/${alertId}`);
}

export async function classifyAlert(alertId, classification) {
  return post(`/v1/alerts/${alertId}/classify`, {
    alert: {
      alert_classification: classification,
    },
  });
}

export async function acknowledgeAlert(alertId, acknowledge) {
  return post(`/v1/alerts/${alertId}/acknowledge`, {
    acknowledged: acknowledge,
  });
}

export async function getAlertNotificationTypes(isImmutable = true) {
  return get('/v1/alerts/notifications/types', {}, { isImmutable });
}

export async function postAlertDefinition(alertDef) {
  const alertDefJS = Map.isMap(alertDef) ? alertDef.toJS() : alertDef;

  return post('/v1/alerts/definitions', alertDefJS);
}

export async function getAlertDefinitions(isImmutable = true) {
  return get('/v1/alerts/definitions', {}, { isImmutable });
}

export async function getAlertDefinition(alertDefId) {
  return get(`/v1/alerts/definitions/${alertDefId}`);
}

export async function putAlertDefinition(alertDefId, alertDef) {
  const alertDefJS = Map.isMap(alertDef) ? alertDef.toJS() : { ...alertDef };

  delete alertDefJS.id;

  if (alertDef.company) {
    alertDefJS.company_id = alertDefJS.company.id;
    delete alertDefJS.company;
  }

  return put(`/v1/alerts/definitions/${alertDefId}`, alertDefJS);
}

export async function patchAlertDefinition(alertDefId, alertDef) {
  const alertDefJS = Map.isMap(alertDef) ? alertDef.toJS() : { ...alertDef };

  delete alertDefJS.id;

  if (alertDef.company) {
    alertDefJS.company_id = alertDefJS.company.id;
    delete alertDefJS.company;
  }

  return patch(`/v1/alerts/definitions/${alertDefId}`, alertDefJS);
}

export async function deleteAlertDefinition(alertDefId) {
  return del(`/v1/alerts/definitions/${alertDefId}`);
}

export async function setAlertDefinitionSubscription(id, subscription = Map()) {
  if (subscription.get('alert_notification_types', List()).isEmpty()) {
    return post(`/v1/alerts/definitions/${id}/unsubscribe`);
  }

  return post(`/v1/alerts/definitions/${id}/subscribe`, subscription.toJS());
}

export async function copyAlertDefinition(alertDefId) {
  return post(`/v1/alerts/definitions/${alertDefId}/copy`);
}

export async function postFeedItem(feedItem) {
  return post('/v1/activities', feedItem);
}

export async function getAlertComments(alertId) {
  return get(`/v1/alerts/${alertId}/comments`);
}

export async function postAlertComment(alertId, alertComment) {
  return post(`/v1/alerts/${alertId}/comments`, alertComment);
}

export async function patchFeedItemComment(feedItemId, commentId, comment) {
  return patch(`/v1/activities/${feedItemId}/comments/${commentId}`, comment);
}

export async function deleteFeedItemComment(feedItemId, commentId) {
  return del(`/v1/activities/${feedItemId}/comments/${commentId}`);
}

export async function getDashboards(userId) {
  return get(`/v1/users/${userId}/dashboards`);
}

export async function getDashboardTemplates(type) {
  return get('/v1/dashboards/templates', { type });
}

export async function useDashboardTemplate(userId, id, templateId, assetId) {
  return post(`/v1/users/${userId}/dashboards/${id}/use_template`, {
    template_dashboard_id: templateId,
    asset_id: assetId,
  });
}

export async function getDashboard(userId, id) {
  return get(`/v1/users/${userId}/dashboards/${id}`);
}

export async function postDashboard(userId, dashboard) {
  const dashboardJS = Map.isMap(dashboard) ? dashboard.toJS() : dashboard;

  return post(`/v1/users/${userId}/dashboards`, dashboardJS);
}

export async function cloneDashboard(userId, id, dashboard) {
  const dashboardJS = Map.isMap(dashboard) ? dashboard.toJS() : dashboard;
  return post(`/v1/users/${userId}/dashboards/${id}/clone`, dashboardJS);
}

export async function putDashboard(userId, id, dashboard) {
  const dashboardJS = Map.isMap(dashboard) ? dashboard.toJS() : dashboard;

  return put(`/v1/users/${userId}/dashboards/${id}`, dashboardJS);
}

export async function deleteDashboard(userId, id) {
  return del(`/v1/users/${userId}/dashboards/${id}`);
}

export async function createApp(userId, dashboardId, app) {
  return post(`/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps`, app);
}

export async function updateApp(userId, dashboardId, app) {
  return put(
    `/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps/${app.get('id')}`,
    app.toJS()
  );
}

export async function updateApps(userId, dashboardId, apps) {
  return put(`/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps`, {
    dashboard_apps: apps, // NOTE: Backend requirement to use field dashboards_app for now
  });
}

export async function deleteApp(userId, dashboardId, appId) {
  return del(`/v1/users/${userId}/dashboards/${dashboardId}/dashboard_apps/${appId}`);
}

export async function getAssetsWithHeaders(types = [], options = {}, otherParams = {}) {
  const {
    page,
    perPage,
    sort,
    order,
    search,
    companyId,
    limit,
    ids = [],
    status,
    parentAssetId,
    programId,
    start,
    end,
  } = options;

  const queryParams = {
    types,
    ids,
    status: status || undefined,
    parent_asset_id: parentAssetId || undefined,
    program: programId || undefined,
    page,
    per_page: perPage,
    sort,
    order,
    search: search !== '' ? search : undefined,
    company_id: companyId !== null ? companyId : undefined,
    limit,
    start,
    end,
  };

  return getWithHeaders('/v1/assets', queryParams, otherParams);
}

export async function getAssets(types = [], options = {}, otherParams = { isImmutable: true }) {
  // NOTE: Temporary solution for loading
  // all the assets present in database by default
  const dataAndHeaders = await getAssetsWithHeaders(
    types,
    { perPage: 1000, ...options },
    otherParams
  );
  return otherParams.isImmutable ? dataAndHeaders.get('data') : dataAndHeaders.data;
}

export async function getAllAssets(types, options) {
  // NOTE: Load assets in chunks is faster than loading them in one request
  // NOTE: Also, the problem here - we don't know how many assets exists
  // NOTE: So we fetch 2000 for now

  const optionsArray = [];
  const limit = 2000;
  const perPage = 250;

  for (let i = 1; i <= Math.floor(limit / perPage + 1); i += 1) {
    optionsArray.push({
      ...options,
      perPage,
      page: i,
    });
  }

  const responses = await Promise.all(optionsArray.map(opts => getAssetsWithHeaders(types, opts)));

  return List(responses).flatMap(response => response.get('data'));
}

export async function getAsset(id) {
  return get(`/v1/assets/${id}`);
}

export async function getActiveChildAsset(id) {
  return get(`/v1/assets/${id}/active_child`);
}

export async function postAsset(asset) {
  const assetJS = Map.isMap(asset) ? asset.toJS() : asset;

  return post('/v1/assets', assetJS);
}

// NOTE: It's required to pass 'company_id' if update other company rig. Otherwise it 'company_id'
// will be changed to current user's 'company_id'
export async function putAsset(id, asset) {
  const assetJS = Map.isMap(asset) ? asset.toJS() : asset;

  return put(`/v1/assets/${id}`, assetJS);
}

export async function deleteAsset(id) {
  return del(`/v1/assets/${id}`);
}

export async function getAppStorage(provider, collection, assetId, params = Map(), appKey) {
  return get(`/v1/data/${provider}/${collection}`, params.merge({ asset_id: assetId }).toJS(), {
    appKey,
  });
}

export async function getAppStorageRecord(provider, collection, recordId) {
  return get(`/v1/data/${provider}/${collection}/${recordId}`, {});
}

export async function postAppStorage(provider, collection, item, isImmutable = true) {
  return post(`/v1/data/${provider}/${collection}`, isImmutable ? item.toJS() : item, {
    isImmutable,
  });
}

export async function postTaskDocument(provider, collection, data, params = Map()) {
  const qry = queryString.stringify(params.toJS());
  return post(`/v1/tasks/${provider}/${collection}?${qry}`, { data });
}

export async function putAppStorage(provider, collection, id, item) {
  return put(`/v1/data/${provider}/${collection}/${id}`, item.toJS());
}

export async function deleteAppStorage(provider, collection, id) {
  return del(`/v1/data/${provider}/${collection}/${id}`);
}

export async function deleteAppStorageRecords(provider, collection, query) {
  return del(`/v1/data/${provider}/${collection}`, query);
}

export async function getWellTimeline(wellId) {
  return get(`/v1/jobs/${wellId}/drill_view_timeline_slider`);
}

export async function getS3SignedUrl(filename, contentType, assetId = null) {
  return get(
    `/v1/file/sign?file_name=${encodeURIComponent(filename)}${
      assetId ? `&asset_id=${assetId}` : ''
    }&contentType=${contentType}`
  );
}

export async function postDataFilter(filters) {
  return post('/v1/data_filters', filters.toJS());
}

export async function putDataFilter(filter) {
  return put(`/v1/data_filters/${filter.get('id')}`, filter.toJS());
}

export async function deleteDataFilter(filterId) {
  return del(`/v1/data_filters/${filterId}`);
}

export async function getDataFilters(userId, dashboardId) {
  return get(`/v1/users/${userId}/dashboards/${dashboardId}/data_filters`);
}

export async function getDataQuerySnippets() {
  return get('/v1/data_query_snippets');
}

export async function postDataQuerySnippet(snippet) {
  const snippetJS = snippet.toJS ? snippet.toJS() : snippet;
  return post('/v1/data_query_snippets', snippetJS);
}

export async function putDataQuerySnippet(snippet) {
  const snippetJS = Map.isMap(snippet) ? snippet.toJS() : snippet;
  return put(`/v1/data_query_snippets/${snippet.id}`, snippetJS);
}

export async function deleteDataQuerySnippet(snippetId) {
  return del(`/v1/data_query_snippets/${snippetId}`);
}

export async function getAppStreamsWithHeaders(options = {}, otherParams = {}) {
  const {
    page,
    perPage,
    sort,
    order,
    search,
    companyId,
    type,
    assetId,
    rigId,
    segment,
    status,
    logType,
    sourceType,
    dataReceivedAt,
    lastActiveAt,
    startedBy,
  } = options;
  const queryParams = {
    page,
    per_page: perPage,
    sort,
    order,
    search: search !== '' ? search : undefined,
    company_id: companyId !== null ? companyId : undefined,
    segment: segment !== SEGMENTS.DRILLING ? segment : undefined,
    type,
    asset_id: assetId,
    rig_id: rigId,
    status,
    log_type: logType,
    source_type: sourceType,
    data_received_at: dataReceivedAt,
    last_active_at: lastActiveAt,
    started_by: startedBy,
  };
  return getWithHeaders('/v1/app_streams', queryParams, otherParams);
}

export async function getAppStreams(options = {}) {
  const dataAndHeaders = await getAppStreamsWithHeaders(options);
  const data = dataAndHeaders.get('data');
  return data;
}

export async function getAppStream(id, options, otherParams) {
  return get(`/v1/app_streams/${id}`, options, otherParams);
}

export async function postAppStream(stream, params) {
  const streamJS = Map.isMap(stream) ? stream.toJS() : stream;
  return post('/v1/app_streams', streamJS, params);
}

export async function deleteAppStream(id) {
  return del(`/v1/app_streams/${id}`);
}

export async function updateAppStream(id, stream, otherParams) {
  const streamJS = Map.isMap(stream) ? stream.toJS() : stream;
  return put(`/v1/app_streams/${id}`, streamJS, {}, otherParams);
}

export function cloneAppStream(id, name, assetId) {
  return post(
    `/v1/app_streams/${id}/clone`,
    {
      name,
      asset_id: assetId,
    },
    { hideNotification: true }
  );
}

export function batchUpdateAppStreamsStatus(ids, status) {
  return patch('/v1/app_streams/batch_update', { app_stream_ids: ids, app_stream: { status } });
}

export function batchDeleteAppStreams(ids) {
  return del('/v1/app_streams/batch_destroy', { app_stream_ids: ids });
}

export async function forceResumeStream(id) {
  return post(`/v1/app_streams/${id}/force_resume`);
}

export async function getAppConnection(id) {
  return get(`/v1/app_connections/${id}`);
}

export async function updateAppConnection(id, connection) {
  const connectionJS = Map.isMap(connection) ? connection.toJS() : connection;
  return put(`/v1/app_connections/${id}`, connectionJS);
}

export async function deleteAppConnection(id) {
  return del(`/v1/app_connections/${id}`);
}

export async function getColumnMapping(assetId, sourceType) {
  return get(
    `/v1/assets/${assetId}/app_connection_settings?app_key=corva.completion-column-mapper&source_type=${sourceType}`
  );
}

export async function getApp(appKey, id = 0) {
  // NOTE: id = 0 is temporary solution. Should be removed when Apps moved to Back-End
  return get(`/v1/apps/${id}?app_key=${appKey}`);
}

export async function getApps(type) {
  return get(`/v1/apps?type=${type}`);
}

export async function postAppConnection(streamId, appType, appSettings) {
  const connection = {};
  connection.app_stream_id = streamId;
  connection.app_id = appType?.constants?.METADATA?.appId || appType.id;
  connection.settings = appSettings;
  connection.status = 'active';
  return post('/v1/app_connections', connection);
}

export async function postUser(user) {
  const userJS = Map.isMap(user) ? user.toJS() : user;
  return post('/v1/users', userJS);
}

export async function getUser(id) {
  return get(`/v1/users/${id}`);
}

export async function getUsersWithHeaders(options = {}) {
  const {
    // FIXME: This is a temporary fix. Default limit (per_page) of 1000 is here to get more than
    // default amount of items from Back-end that is 100.
    // Ticket to fix this with pagination - CW-9173.
    page,
    perPage = 2000,
    sort,
    order,
    search,
    companyId,
    ids,
    isImmutable,
  } = options;
  const queryParams = {
    page,
    per_page: perPage,
    sort,
    order,
    ids,
    search: search !== '' ? search : undefined,
    // NOTE: For Corva admins if there is no 'company_id', it returns all companies users
    company_id: companyId !== null ? companyId : undefined,
  };
  return getWithHeaders('/v1/users', queryParams, { isImmutable });
}

export async function getUsers(options = {}) {
  const { isImmutable = true } = options;
  const dataAndHeaders = await getUsersWithHeaders(options);
  const data = isImmutable ? dataAndHeaders.get('data') : dataAndHeaders?.data;
  return data;
}

export async function putUser(id, user) {
  const userJS = Map.isMap(user) ? user.toJS() : user;
  return put(`/v1/users/${id}`, userJS);
}

export async function patchUser(id, user) {
  const userJS = Map.isMap(user) ? user.toJS() : user;
  return patch(`/v1/users/${id}`, userJS);
}

export async function updateUserPassword(userId, passwordData) {
  return patch(`/v1/users/${userId}/password`, passwordData);
}

export async function deleteUser(id) {
  return del(`/v1/users/${id}`);
}

export async function patchUserPreferences(userId, userPreferences) {
  const userPreferencesJS = Map.isMap(userPreferences) ? userPreferences.toJS() : userPreferences;
  return patch(`/v1/users/${userId}/preferences`, userPreferencesJS);
}

export async function setCurrentUserSettings(userId, userSettings) {
  const userSettingsJS = Map.isMap(userSettings) ? userSettings.toJS() : userSettings;
  return post(`/v1/users/${userId}/settings`, userSettingsJS);
}

export async function getUserSetting(userId, settingKey) {
  return get(`/v1/users/${userId}/settings/${settingKey}`);
}

export async function getUserSettings(userId) {
  return get(`/v1/users/${userId}/settings`);
}

export async function deleteUserSetting(userId, settingKey) {
  return del(`/v1/users/${userId}/settings/${settingKey}`);
}

export async function getUserAutocomplete(type, value, companyId) {
  const queryParams = { type };

  if (value) {
    queryParams.search = value;
  }
  if (companyId) {
    queryParams.company_id = companyId;
  }

  return get('/v1/users/autocomplete', queryParams);
}

export const exportUsers = company_id =>
  get('/v2/users/export', { company_id }, { responseType: 'blob', isImmutable: false });

export async function postUserResetPassword(user) {
  const userJS = Map.isMap(user) ? user.toJS() : user;
  return post('/v1/registrations/password/reset', userJS);
}

export async function postUserForgotPassword(user) {
  const userJS = Map.isMap(user) ? user.toJS() : user;
  return post('/v1/registrations/password/forgot', userJS);
}

export async function getCompany() {
  return get('/v1/company');
}

export async function getCompanies() {
  return get('/v1/companies');
}

export async function patchCompany(company) {
  const companyJS = Map.isMap(company) ? company.toJS() : company;
  return patch('/v1/company', companyJS);
}

export async function getCompanyGoals(companyId) {
  return get(`/v1/companies/${companyId}/settings/company_goals`);
}

export async function getCompanySettings(companyId) {
  return get(`/v1/companies/${companyId}/settings`);
}

export async function postCompanyGoals(companyGoal, companyId) {
  const companyGoalJS = Map.isMap(companyGoal) ? companyGoal.toJS() : companyGoal;
  return post(`/v1/companies/${companyId}/settings`, companyGoalJS);
}

export async function getReportSchedule(id) {
  return get(`/v1/reports/schedule/${id}`);
}

export async function getReportSchedules() {
  return get('/v1/reports/schedule');
}

export async function putScheduleReports(schedule) {
  return put('/v1/reports/schedule', schedule);
}

export async function downloadDashboardReportAsPDF(path) {
  return get(`/v1/reports/generate_async?path=reports/${path}`);
}

export async function postProfile(profile) {
  const profileJS = Map.isMap(profile) ? profile.toJS() : profile;
  return post('/v1/profile_groups', profileJS);
}

export async function getProfile(id) {
  return get(`/v1/profile_groups/${id}`);
}

export async function getProfiles(companyId) {
  const queryParams = { company_id: companyId };
  return get('/v1/profile_groups', queryParams);
}

export async function putProfile(id, profile) {
  const profileJS = Map.isMap(profile) ? profile.toJS() : profile;
  return put(`/v1/profile_groups/${id}`, profileJS);
}

export async function patchProfile(id, profile) {
  const profileJS = Map.isMap(profile) ? profile.toJS() : profile;
  return patch(`/v1/profile_groups/${id}`, profileJS);
}

export async function deleteProfile(id) {
  return del(`/v1/profile_groups/${id}`);
}

export async function postProfileUserConnection(profileUserConnection) {
  const profileUserConnectionJS = Map.isMap(profileUserConnection)
    ? profileUserConnection.toJS()
    : profileUserConnection;
  return post('/v1/profile_group_users', profileUserConnectionJS);
}

export async function getProfileUserConnections(params = {}) {
  return get('/v1/profile_group_users', params);
}

export async function deleteProfileUserConnection(id) {
  return del(`/v1/profile_group_users/${id}`);
}

export async function postDashboardShare(dashboardShare) {
  const dashboardShareJS = Map.isMap(dashboardShare) ? dashboardShare.toJS() : dashboardShare;
  return post('/v1/dashboard_shares', dashboardShareJS);
}

/**
 * Creates a Dashboard User Share for Current User based on Dashboard Profile Share
 */
export async function makeDashboardProfileShareVisible(dashboardProfileShareId, params = {}) {
  return post(`/v1/dashboard_shares/${dashboardProfileShareId}/use`, params);
}

// NOTE: By default Back-end responses only with type === 'dashboard' or 'asset_dashboard'
export async function getDashboardShares(params = {}) {
  return get('/v1/dashboard_shares', params);
}

export async function patchDashboardShare(id, dashboardShare) {
  const dashboardShareJS = Map.isMap(dashboardShare) ? dashboardShare.toJS() : dashboardShare;
  return patch(`/v1/dashboard_shares/${id}`, dashboardShareJS);
}

export async function deleteDashboardShare(id) {
  return del(`/v1/dashboard_shares/${id}`);
}

export async function getDataAudits(assetId, params = Map()) {
  const qry = queryString.stringify(params.merge({ asset_id: assetId }).toJS());
  return get(`/v1/data_audits?${qry}`);
}

export async function unsubscribeFromReport(reportId, token) {
  return get(`/v1/reports/schedule/${reportId}/unsubscribe?token=${token}`);
}

export async function getFeedItems({
  page,
  perPage,
  start,
  end,
  assets,
  type,
  segment,
  users,
  id,
  companyId,
} = {}) {
  if (id) return get(`/v1/activities/${id}`, { include_actions: true });

  return get('/v1/activities', {
    page,
    per_page: perPage,
    start: start ? start + 60000 : undefined,
    end: end ? end + 60000 : undefined,
    assets,
    users,
    type,
    segment,
    company_id: companyId,
    include_actions: true,
  });
}

export async function getApiVersion() {
  return get('/');
}

export async function getDrillstring(drillstringId) {
  return get(`/v1/data/corva/data.drillstring/${drillstringId}`);
}

export async function getCompetitorAnalysisAssets(options) {
  return get('/v1/competitor_analysis/assets', options);
}

export async function getNotificationsItems({
  page,
  perPage,
  start,
  end,
  rigs,
  type,
  segment,
  users,
  triggerType,
  notificationType,
  acknowledged,
  order,
  sort,
} = {}) {
  return get('/v1/notifications', {
    page,
    per_page: perPage,
    start: start || undefined,
    end: end || undefined,
    rigs,
    users,
    type,
    segment,
    trigger_type: triggerType,
    notification_type: notificationType,
    acknowledged,
    order,
    sort,
  });
}

export async function getNotification(id) {
  return get(`/v1/notifications/${id}`);
}

export async function getUnreadNotificationsCount(options = {}) {
  return get('/v1/notifications/count/unread', options);
}

export async function acknowledgeNotifications(ids) {
  return post('/v1/notifications/acknowledge', { ids });
}

export async function acknowledgeAllNotifications() {
  return post('/v1/notifications/acknowledge_all');
}

export async function rerunMetrics({ assetId, target }) {
  if (!assetId) return null;

  try {
    const { id } = await createTask('corva', 'tasks.metrics-rerunner', assetId, {
      version: 'v2.1',
      target,
    });

    return { id };
  } catch (err) {
    console.error(err);
    return { error: 'An error occurred while metrics rerun.' };
  }
}
