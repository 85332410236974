import { Map, OrderedMap } from 'immutable';

import { alerts } from '@corva/ui/constants';

const initialState = Map({
  newAlerts: OrderedMap(),
  alertBanners: [],
});

function listToOrderedMapById(list) {
  const keyValuePairsArray = list.map(listItem => [listItem.get('id'), listItem]).toArray();

  return OrderedMap(keyValuePairsArray);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case alerts.ADD_NEW_ALERTS:
      return state.update('newAlerts', newAlerts =>
        newAlerts.concat(listToOrderedMapById(action.newAlerts))
      );
    case alerts.DELETE_NEW_ALERT:
      return state.deleteIn(['newAlerts', action.newAlertId]);
    case alerts.CLEAR_NEW_ALERTS:
      return state.set('newAlerts', OrderedMap());
    case alerts.ADD_ALERT_BANNERS:
      return state.set('alertBanners', state.get('alertBanners').concat(action.alerts));
    case alerts.REMOVE_ALERT_BANNER: {
      return state.set(
        'alertBanners',
        state.get('alertBanners').filter(alert => alert.id !== action.alertId)
      );
    }
    default:
      return state;
  }
};
