import { isProdEnv, isBetaEnv } from '~/utils/env';

export const NAME = 'main';

export const RESET_APP_STATE = 'RESET_APP_STATE';
export const MAKE_APP_READY = 'MAKE_APP_READY';
export const START_LOAD = 'START_LOAD';
export const FINISH_RELOAD = 'FINISH_RELOAD';
export const SHOW_LOAD = 'SHOW_LOAD';
export const HIDE_LOAD = 'HIDE_LOAD';

export const CORVA_COMPANY_ID = isProdEnv || isBetaEnv ? 3 : 1;
export const CORVA_COMPANY_PROVIDER = 'corva';
export const CORVA_COMPANY_NAME = 'Corva';

export const EQUINOR_PROD_COMPANY_PROVIDER = 'equinor';
export const EQUINOR_EU_COMPANY_PROVIDER = 'eqnr';
export const HAL_COMPANY_PROVIDER = 'halliburton';
export const BDE_COMPANY_PROVIDER = 'big-data-energy';
export const YPF_COMPANY_PROVIDER = 'ypf';

export const MAIN_NAV_HEIGHT = 50;

export const ITEMS_PER_PAGE = 20;

export const sortOrders = {
  ASC: 'asc',
  DESC: 'desc',
};
