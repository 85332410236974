import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ENABLED } from '~/externalServices/serviceFlags';

const REACT_APP_MIXPANEL_API_TOKEN =
  process.env.REACT_APP_MIXPANEL_API_TOKEN || '29c5f18f4e771715ca4c57e161e2d873';
const { REACT_APP_MIXPANEL_API_HOST } = process.env;

if (MIXPANEL_ENABLED) {
  const config = {
    ...(REACT_APP_MIXPANEL_API_HOST ? { api_host: REACT_APP_MIXPANEL_API_HOST } : {}),
  };
  mixpanel.init(REACT_APP_MIXPANEL_API_TOKEN, config);
}
