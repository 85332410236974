import * as TYPES from './types';
// Good reducers that handle any async actions, are going to be large
// because they act as finite state machines that handle loading and error flags,

// One of the things I like to do, is keep a flat reducer, but introduce a more intuitive data shape
// inside of 'connection' to the component.

export const initialState = {
  selectedCompany: null,
  selectedRig: null,
  selectedWell: null,

  companies: [],
  rigs: [],
  wells: [],

  companiesLoading: false,
  rigsLoading: false,
  wellsLoading: false,

  companiesError: null,
  rigsError: null,
  wellsError: null,

  wellFilter: '',
  selectedLogs: [],
  selectedWellbore: null,
  targetWell: null,
  serverId: '',
  witsmlVersion: null,
  logs: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.SELECT_NEW_COMPANY:
      return { ...state, selectedCompany: payload, selectedWell: null, selectedRig: null };
    case TYPES.SELECT_NEW_RIG:
      return { ...state, selectedRig: payload, selectedWell: null };
    case TYPES.SELECT_NEW_WELL:
      return { ...state, selectedWell: payload };
    case TYPES.SELECT_NEW_LOGS:
      return { ...state, selectedLogs: payload };
    case TYPES.SET_SERVER_ID:
      return {
        ...state,
        ...payload,
      };
    case TYPES.SELECT_WELLBORE:
      return { ...state, selectedWellbore: payload, logs: payload.logs };
    case TYPES.SET_TARGET_WELL:
      return { ...state, targetWell: payload };
    case TYPES.SET_COMPANIES:
      return {
        ...state,
        companies: payload.data,
        companiesLoading: payload.loading,
        companiesError: payload.error,
      };
    case TYPES.SET_RIGS:
      return {
        ...state,
        rigs: payload.data,
        rigsLoading: payload.loading,
        rigsError: payload.error,
      };
    case TYPES.SET_WELLS:
      return {
        ...state,
        wells: payload.data,
        wellsLoading: payload.loading,
        wellsError: payload.error,
      };
    case TYPES.CANCEL_WITSML_CONNECTION:
      return initialState;
    case TYPES.BULK_UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
