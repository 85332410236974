import { getUnresolvedIncidents } from '~/clients/incidents';
import { UPDATE_INCIDENTS } from '~/constants/incidents';
import { STATUSPAGE_ENABLED } from '~/externalServices/serviceFlags';

const updateIncidentsSuccess = (rawIncidents, updatedAt) => ({
  type: UPDATE_INCIDENTS,
  rawIncidents,
  updatedAt,
});

export const updateIncidents = () => async dispatch => {
  if (STATUSPAGE_ENABLED) {
    const rawIncidents = await getUnresolvedIncidents();
    dispatch(updateIncidentsSuccess(rawIncidents, new Date()));
  }
};
