import { handleAuthenticationProblem } from '~/clients/api/apiCore';

function isPromise(o) {
  return Promise.resolve(o) === o;
}

/*
 * For any action creator that returns a Promise (which is usually a server call),
 * this middleware will add a rejection handler to that promise. If it is rejected
 * with an authentication problem, we will route to the login page.
 */
export default () => next => action => {
  const result = next(action);
  if (isPromise(result)) {
    return result.catch(e => {
      handleAuthenticationProblem(e);
      return Promise.reject(e);
    });
  }
  return result;
};
