import { List, Map } from 'immutable';

import * as t from '~/constants/subscriptions';

const initialState = Map({
  userSubscriptions: Map(),
  userData: Map(),
  appAssetSubscriptions: Map(),
  appAssetData: Map(),
});

/**
 * Processes data that comes in and prepares it for use in the apps.
 * In many (most) cases the data won't be touched at all.
 * @param state
 * @param action
 * @returns {Stack.<T|U>|List<T>|Number|Stack<T>|Cursor|List.<T|U>|*}
 */
function processNewSubscriptionData(state, action) {
  const behavior = action.params.get('behavior', false);

  if (behavior && List.isList(action.data)) {
    const currentData = state.getIn([
      'appAssetData',
      action.appInstanceId,
      action.provider,
      action.collection,
      action.event || '',
    ]);
    if (currentData) {
      // Custom data processing for various subscription behaviors.
      if (behavior === 'turnover') {
        // If the turnover behavior is set, we slice off the number of rows we're going to add,
        // and add the new rows
        return currentData.slice(action.data.size).concat(action.data);
      } else if (behavior === 'accumulate') {
        // If the accumulate behavior is set, we add the new rows to the data and keep the old data
        return currentData.concat(action.data);
      }
    }
  }

  return action.data;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SUBSCRIBE_APP_FOR_ASSET:
      return state.setIn(
        [
          'appAssetSubscriptions',
          action.appInstanceId,
          action.provider,
          action.collection,
          action.event || '',
        ],
        Map({
          assetId: action.assetId,
          params: action.params,
        })
      );
    case t.UNSUBSCRIBE_APP_FROM_ASSET: {
      const { appInstanceId, subscriptions } = action;
      return subscriptions.reduce(
        (updatedState, { provider, collection, event = '' }) =>
          updatedState
            .removeIn(['appAssetSubscriptions', appInstanceId, provider, collection, event])
            .removeIn(['appAssetData', appInstanceId, provider, collection, event]),
        state
      );
    }
    case t.RECEIVE_APP_ASSET_DATA: {
      const { appInstanceId, provider, collection, event = '', assetId, params } = action;
      const activeSub = state.getIn([
        'appAssetSubscriptions',
        appInstanceId,
        provider,
        collection,
        event,
      ]);
      if (
        activeSub &&
        activeSub.get('assetId') === assetId &&
        activeSub.get('params').equals(params)
      ) {
        return state.setIn(
          ['appAssetData', appInstanceId, provider, collection, event],
          processNewSubscriptionData(state, action)
        );
      }
      return state;
    }
    case t.RECEIVE_INITIAL_APP_ASSET_DATA: {
      const { appInstanceId, provider, collection, event = '', assetId, params } = action;
      const activeSub = state.getIn([
        'appAssetSubscriptions',
        appInstanceId,
        provider,
        collection,
        event,
      ]);
      if (
        activeSub &&
        activeSub.get('assetId') === assetId &&
        activeSub.get('params').equals(params)
      ) {
        return state.setIn(
          ['appAssetData', appInstanceId, provider, collection, event],
          action.data
        );
      }
      return state;
    }
    case t.SET_APP_ASSET_DATA_ERROR: {
      const { appInstanceId, provider, collection, event = '', error } = action;
      return state.setIn(
        ['appAssetData', appInstanceId, provider, collection, event],
        Map({ data: Map({ error }) })
      );
    }
    case t.SUBSCRIBE_USER_FOR_DATA: {
      const { companyId, userId, subscriptions } = action;
      return subscriptions.reduce(
        (updatedState, { collection }) =>
          updatedState.setIn(['userSubscriptions', companyId, userId, collection], true),
        state
      );
    }
    case t.UNSUBSCRIBE_USER_FROM_DATA: {
      const { companyId, userId, subscriptions } = action;
      return subscriptions.reduce(
        (updatedState, { collection }) =>
          updatedState
            .removeIn(['userSubscriptions', companyId, userId, collection])
            .removeIn(['userData', companyId, userId, collection]),
        state
      );
    }
    case t.RECEIVE_USER_DATA: {
      const activeSubscriptionExists = state.hasIn([
        'userSubscriptions',
        action.companyId,
        action.userId,
        action.collection,
      ]);
      return activeSubscriptionExists
        ? state
            // NOTE: Clean up previous feed sub items
            .removeIn(['userData', action.companyId, action.userId, action.collection])
            .removeIn(['userData', action.companyId, undefined, action.collection])
            .setIn(['userData', action.companyId, action.userId, action.collection], action.data)
        : state;
    }
    default:
      return state;
  }
};
