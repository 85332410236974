import PropTypes from 'prop-types';
import { Link } from 'react-router';
import TimelineIcon from '@material-ui/icons/Timeline';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  alertLink: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  alertAssets: {
    fontSize: 12,
    lineHeight: '30px',
  },
  alertDecisionPath: {
    fontSize: 12,
  },
  alertStage: {
    marginLeft: 8,
    fontSize: 12,
    color: theme.palette.primary.text6,
  },
  decisionPathIcon: {
    height: '20px !important',
    width: '20px !important',
    marginRight: 5,
    marginBottom: '-5px',
  },
}));

const AlertNotificationContent = ({
  alertId,
  alertName,
  alertDecisionPath,
  parentAssetName,
  wellName,
  stages,
}) => {
  const styles = useStyles();

  return (
    <Link to={`/alerts/${alertId}`} className={styles.alertLink}>
      <div>
        <span>{alertName}</span>
        <br />
        <span className={styles.alertAssets}>
          {parentAssetName} - {wellName}
        </span>
        {stages.length > 0 && <span className={styles.alertStage}>Stage {stages}</span>}
        <br />
        <span className={styles.alertDecisionPath}>
          <TimelineIcon className={styles.decisionPathIcon} htmlColor="#fff" />
          {alertDecisionPath}
        </span>
      </div>
    </Link>
  );
};

AlertNotificationContent.propTypes = {
  alertId: PropTypes.string.isRequired,
  alertName: PropTypes.string.isRequired,
  alertDecisionPath: PropTypes.string.isRequired,
  parentAssetName: PropTypes.string.isRequired,
  wellName: PropTypes.string.isRequired,
  stages: PropTypes.string.isRequired,
};

export default AlertNotificationContent;
