import { baseRoute } from '~/constants/incidents';

const APIkey = '2d55c09c-aa31-453b-8850-ca94c1736803';

const HEADERS = {
  'Content-Type': 'application/json',
  Authorization: `OAuth ${APIkey}`,
};

// NOTE: We don't need advanced error handler here
const request = async (route, options, defaultValue) => {
  let response;
  try {
    response = await fetch(route, {
      headers: HEADERS,
      ...options,
    });
  } catch (e) {
    if (defaultValue) {
      return defaultValue;
    }

    throw e;
  }
  // NOTE: Mute Too many requests api.statuspage.io error
  if (response.status === 420) return [];
  return response.json();
};

// NOTE: There will be more functions in the future
const route = `${baseRoute}/incidents/unresolved.json`;

export async function getUnresolvedIncidents() {
  const response = await request(route, { method: 'GET' }, []);

  return response;
}
