export const NAME = 'dashboards';

export const FETCH_DASHBOARDS = 'FETCH_DASHBOARDS';
export const UPDATE_DASHBOARDS = 'UPDATE_DASHBOARDS';
export const UPDATE_FOLDERS = 'UPDATE_FOLDERS';
export const FETCH_CURRENT_DASHBOARD = 'FETCH_CURRENT_DASHBOARD';
export const FETCH_CURRENT_ALERT_DASHBOARD = 'FETCH_CURRENT_ALERT_DASHBOARD';

export const MOVE_APPS = 'MOVE_APPS';
export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_ALL_APPS = 'UPDATE_ALL_APPS';
export const ADD_APP = 'ADD_APP';
export const REMOVE_APP = 'REMOVE_APP';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const CLEAR_CURRENT_DASHBOARD = 'CLEAR_CURRENT_DASHBOARD';
export const CLEAR_CURRENT_ALERT_DASHBOARD = 'CLEAR_CURRENT_ALERT_DASHBOARD';
export const UPDATE_CURRENT_DASHBOARD = 'UPDATE_CURRENT_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const SET_CURRENT_DASHBOARD_APPS_LAST_ANNOTATIONS =
  'SET_CURRENT_DASHBOARD_APPS_LAST_ANNOTATIONS';
export const UPDATE_CURRENT_DASHBOARD_APP_LAST_ANNOTATION =
  'UPDATE_CURRENT_DASHBOARD_APP_LAST_ANNOTATION';
export const CLEAR_DASHBOARDS = 'CLEAR_DASHBOARDS';
export const FETCH_SHARED_WITH_ME_DASHBOARDS_AND_FOLDERS =
  'FETCH_SHARED_WITH_ME_DASHBOARDS_AND_FOLDERS';
export const HIDE_SHARED_WITH_ME_DASHBOARD = 'HIDE_SHARED_WITH_ME_DASHBOARD';
export const HIDE_SHARED_WITH_ME_FOLDER = 'HIDE_SHARED_WITH_ME_FOLDER';
export const ADD_PDF_GENERATING_NOTIFICATION = 'ADD_PDF_GENERATING_NOTIFICATION';
export const REMOVE_PDF_GENERATING_NOTIFICATION = 'REMOVE_PDF_GENERATING_NOTIFICATION';
export const REMOVE_ALL_PDF_GENRATING_NOTIFICATIONS = 'REMOVE_ALL_PDF_GENRATING_NOTIFICATIONS';

export const DISABLE_APP_HIGHLIGHT = 'DISABLE_APP_HIGHLIGHT';
export const DELETE_DASHBOARD_WORKFLOW = 'DELETE_DASHBOARD_WORKFLOW';
export const UNGROUP_DASHBOARD_WORKFLOW = 'UNGROUP_DASHBOARD_WORKFLOW';
export const UNGROUP_ALL_DASHBOARD_WORKFLOWS = 'UNGROUP_ALL_DASHBOARD_WORKFLOWS';
export const CHANGE_WORKFLOWS_ASSETS = 'CHANGE_WORKFLOWS_ASSETS';

export const SET_HIGHLIGHTED_WORKFLOW = 'SET_HIGHLIGHTED_WORKFLOW';

export const DASHBOARD_TYPES = {
  asset: 'asset_dashboard',
  dashboard: 'dashboard',
  alert_dashboard: 'alert_dashboard',
  dashboard_folder: 'dashboard_folder',
} as const;

export const DASHBOARD_CATEGORY = {
  usageAnalytics: 'usageAnalyticsDashboard',
};

export const DIALOG_TYPES = {
  addAppToDashboard: 'addAppToDashboard',
  assetDashboardAddOrEdit: 'assetDashboardAddOrEdit',
  companyDashboards: 'companyDashboards',
  changeUnits: 'changeUnits',
  dashboardAddOrEdit: 'dashboardAddOrEdit',
  dashboardDeleteOrHide: 'dashboardDeleteOrHide',
  dashboardSharing: 'dashboardSharing',
  dashbooardScheduleReports: 'dashbooardScheduleReports',
  editWorkflows: 'editWorkflows',
  sharedWithMeDashboards: 'sharedWithMeDashboards',
  changeTimezone: 'changeTimezone',
  copyWorkflow: 'copyWorkflow',
} as const;
