import { Map } from 'immutable';
import * as t from '~/constants/main';

const initialState = Map({
  isAppReady: false,
  // TODO: Create actions for 'isLoading' to dispatch on any request
  // Create overlay (!) Loading component to display to not unmount and remount other components
  isLoading: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case t.MAKE_APP_READY:
      return state.set('isAppReady', true);
    case t.START_LOAD:
      return state.set('isLoading', true);
    case t.SHOW_LOAD:
      return state.set('isLoading', true);
    case t.HIDE_LOAD:
      return state.set('isLoading', false);
    default:
      return state;
  }
};
