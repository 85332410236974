import { ASSET_TYPES } from '~/constants/assets';

type ImmutableAsset = ImmutableMap<any>;

export function isResolvableAsset(asset: ImmutableAsset) {
  return ASSET_TYPES.getIn([asset.get('asset_type'), 'isResolvedToActiveChild']);
}

export function isResolvedAsset(asset: any) {
  return asset.hasIn(['active_child', 'id']) || asset.hasIn(['last_active_child', 'id']);
}

// TODO: refine type of assetsList
export function getActiveDescendant(assetsList: ImmutableAsset[], assetId: number) {
  // @ts-ignore
  let asset = assetsList.get(assetId);
  while (asset && isResolvableAsset(asset)) {
    if (isResolvedAsset(asset)) {
      // @ts-ignore
      asset = assetsList.get(
        asset.getIn(['active_child', 'id']) || asset.getIn(['last_active_child', 'id'])
      );
    } else {
      return null;
    }
  }
  return asset;
}
