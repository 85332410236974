import { Map, OrderedMap } from 'immutable';

import * as t from '~/constants/streams';

const initialState = Map({
  // NOTE: Read Redux best practices:
  // https://redux.js.org/docs/recipes/reducers/NormalizingStateShape.html
  streams: OrderedMap(),
  currentStream: null,
  streamsTotal: 0, // NOTE: Streams Page pagination
});

export default (state = initialState, action) => {
  switch (action.type) {
    case t.UPDATE_STREAMS: {
      // NOTE: Example:
      // let newOrderedMap = OrderedMap([['key0', 'value0'], ['key1', 'value1'], ...])
      // https://facebook.github.io/immutable-js/docs/#/OrderedMap/OrderedMap
      const keyValuePairsArray = action.streams.map(stream => [stream.get('id'), stream]).toArray();

      return state.set('streams', OrderedMap(keyValuePairsArray)).set('streamsTotal', action.total);
    }
    case t.UPDATE_CURRENT_STREAM:
      return state.set('currentStream', action.currentStream);
    default:
      return state;
  }
};
