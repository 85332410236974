// FIXME: in the future keys (messages) on the API will change
// An example 'PASSWORD_MUST_CONTAIN'

// NOTE: API send 2 types of messages now: first letter capital and lowercase

export const DISPLAY_HTTP_MESSAGES = {
  'Current password incorrect': 'Current password incorrect.',
  'Password is too short (minimum is 8 characters), Password requires at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character':
    'Password is too short (minimum is 8 characters), Password requires at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character',
  'Password is too short (minimum is 8 characters)':
    'Password is too short (minimum is 8 characters)',
  'Password requires at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character':
    'Password requires at least 1 uppercase, 1 lowercase, 1 digit, and 1 special character',
  'Password is too short (minimum is 8 characters), Password must be at least 8 characters long.':
    'Password is too short (minimum is 8 characters).',
  'Password must contain at least one uppercase letter.':
    'Password must contain at least one uppercase letter.',
  'Password must contain at least one lowercase letter.':
    'Password must contain at least one lowercase letter.',
  'Password must contain at least one digit.': 'Password must contain at least one digit.',
  'Password must contain at least one symbol.': 'Password must contain at least one symbol.',
};
