export const MIXPANEL_ACTION_TYPES = {
  PLATFORM: {
    ASSET_SEARCH: 'Platform - Asset Search',
  },
  FAVORITES: {
    STAR_HOVER: 'Favorites - Star Hover',
    ADD_TO_FAVORITES: 'Favorites - Add to Favorites',
  },
  ASSET_MAP: {
    ASSET_SEARCH: 'Asset Map - Asset Search',
    FILTERS_CLICK: 'Asset Map - Filters Click',
  },
  APP_STORE: {
    SEARCH: 'App Store - Search',
  },
  PAGE: {
    VIEW: 'Page view',
    PASSIVE_VIEW: 'Page view - Passive',
  },
  DASHBOARD: {
    CREATE: 'Dashboard - Create',
    SCHEDULE_REPORT: 'Dashboard - Scheduled Report',
    DOWNLOAD_REPORT: 'Dashboard - Download Report',
    SHARE_DASHBOARD: 'Dashboard - Share',
    SHARE_FOLDER: 'Folder - Share',
    CHANGE_TIMEZONE: 'Dashboard - Change Timezone',
    CHANGE_UNITS: 'Dashboard - Change Units',
    USAGE: 'Dashboard - Usage',
  },

  APP: {
    ADD_TO_DASHBOARD: 'App - Add to Dashboard',
    VIEW: 'App - View',
    VIEW_CONTINUOUS: 'App - View Continuous',
    HOVER: 'App - Hover',
    CLICK: 'App - Click',
    SETTINGS: 'App - Settings',
    SHARE: 'App - Share',
    EXPORT: 'App - Export',
    OPEN_HELP_CENTER: 'App - Open Help Center',

    // NOTE: Only traces app events below
    ZOOM_IN: 'App - Zoom In',
    ZOOM_OUT: 'App - Zoom Out',
    JUMP: 'App - Jump',
    TO_DEFAULT_VIEW: 'App - To Default View',
    MOVE_TIME_WINDOW_UP: 'App - Move Time Window Up',
    MOVE_TIME_WINDOW_DOWN: 'App - Move Time Window Down',
  },
  // NOTE: This event is used in corva/ui components. Do not edit it.
  REDIRECT: {
    ASSET_DASHBOARD: 'Redirect - Asset Dashboard',
  },
  // NOTE: Only Data Quality/Stream Quality app events below
  DQ_APP_CUSTOM: {
    CLICK: 'DQ App Custom - Click',
  },
} as const;

type NestedValueOf<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object ? NestedValueOf<T[K]> : T[K];
    }[keyof T]
  : never;

export type MixpanelActionType = NestedValueOf<typeof MIXPANEL_ACTION_TYPES>;

export const MIXPANEL_DASHBOARD_ACTION_TYPES = Object.values(MIXPANEL_ACTION_TYPES.DASHBOARD);
export const MIXPANEL_APP_ACTION_TYPES = Object.values(MIXPANEL_ACTION_TYPES.APP);
export const MIXPANEL_IN_APP_ACTIONS = new Set([
  MIXPANEL_ACTION_TYPES.APP.VIEW,
  MIXPANEL_ACTION_TYPES.APP.VIEW_CONTINUOUS,
  MIXPANEL_ACTION_TYPES.APP.HOVER,
  MIXPANEL_ACTION_TYPES.APP.CLICK,
]);

export const MIXPANEL_PAGE_TYPES = Object.values(MIXPANEL_ACTION_TYPES.PAGE);

export const LOG_APP_VIEW_DELAY = 5000; // NOTE: 5 seconds to define that user looks on an App
export const LOG_APP_VIEW_CONTINUOUS_DELAY = 10; // NOTE: Send App View Continuous every 10 seconds

export const LOG_APP_HOVER_DELAY = 2000;

export const EVENT_MESSAGES = {
  multiasset: 'multiasset',
  noAsset: 'No Asset',
  noAssetId: 'No Asset Id',
  noRig: 'No Rig',
  noRigId: 'No Rig Id',
  noWell: 'No Well',
  noWellId: 'No Well Id',
  noFleet: 'No Fleet',
  noFleetId: 'No Fleet Id',
  noPad: 'No Pad',
  noPadId: 'No Pad Id',
  noDrilloutUnit: 'No Drillout Unit',
  noDrilloutUnitId: 'No Drillout Unit ID',
  noProgramId: 'No Program Id',
  noProgramName: 'No Program Name',
};
