import markerSDK from '@marker.io/browser';

import { isBetaEnv } from './env';

const MARKER_IO_ID = '6453b2ebab4cf3bdb9193556';

let markerWidget;

const initializeMarkerIo = async () => {
  markerWidget = await markerSDK.loadWidget({
    project: MARKER_IO_ID,
    silent: true,
    ssr: {
      renderDelay: 5000, // 0 - 15000 (ms)
    },
  });
  markerWidget.hide();
};

if (isBetaEnv) initializeMarkerIo();

export const captureBug = (customData = {}) => {
  markerWidget.setReporter({
    email: customData.userEmail,
    fullName: customData.userName,
  });
  markerWidget.setCustomData(customData);
  markerWidget.capture('fullscreen');
  markerWidget.on('feedbackbeforesend', markerWidget.hide);
  markerWidget.on('feedbackdiscarded', markerWidget.hide);
  markerWidget.on('feedbackerror', markerWidget.hide);
};

export const getReportCustomData = (app, currentUser) => ({
  appKey: app.app.app_key,
  appName: app.app.name,
  userEmail: currentUser.email,
  userName: `${currentUser.first_name} ${currentUser.last_name}`,
});
