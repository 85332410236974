import mixpanel from 'mixpanel-browser';
import { omitBy, isNil, get, isEmpty } from 'lodash';
import { SEGMENTS } from '@corva/ui/constants/segment';
import { APP_KEYS } from '~/constants/apps';
import { isLoggingAnalyticsToConsole } from '~/clients/clientStorage';

import {
  EVENT_MESSAGES,
  MIXPANEL_ACTION_TYPES,
  MIXPANEL_APP_ACTION_TYPES,
  MIXPANEL_DASHBOARD_ACTION_TYPES,
  MIXPANEL_IN_APP_ACTIONS,
  MIXPANEL_PAGE_TYPES,
} from '~/constants/mixpanel';

import {
  MIXPANEL_ENABLED,
  REQUIRE_USER_CONSENT_FOR_TRACKING,
} from '~/externalServices/serviceFlags';
import utils from '~/utils/index';
import {
  currentUserSelector,
  isCorvaV3FeaturesEnabledSelector,
  isCurrentUserCorvaUserSelector,
  isImpersonatingSelector,
} from '~/selectors/login';
import { getCompanyDomainFromEmail } from '~/utils/analytics';
import { trackSnowplowEvent } from '~/utils/snowplow';
import { getOriginalUserData } from '~/utils/impersonation';

const getEntityField = (entity, field) => (entity.toJS ? entity.get(field) : get(entity, field));

const getIsCorvaV3FeaturesEnabled = ({ isImpersonating, actionType, state }) => {
  if (isImpersonating && SNOWPLOW_ONLY_EVENTS.includes(actionType)) {
    const { isCorvaV3FeaturesEnabled } = getOriginalUserData() || {};

    return isCorvaV3FeaturesEnabled;
  }

  return isCorvaV3FeaturesEnabledSelector(state);
};

const getAppFullName = app => {
  if (app.app.app_key === APP_KEYS.drillingOperations) {
    const operation = utils.getOperationByType(getEntityField(app.settings, 'operationType'));

    let name = app.title;
    if (operation) name = `${name} - ${operation.title}`;
    return name;
  }

  return app.title;
};

const getAppSegment = app => {
  if (app.settings?.segment) return [app.settings.segment];

  if (!isEmpty(app.segment)) return app.segment;

  return [];
};

const getPadData = (appData, padId = null) => {
  const pad = appData.fracFleet?.pad_frac_fleets?.find(({ pad }) =>
    padId ? pad.id === padId : pad.id === appData.fracFleet.current_pad_id
  )?.pad;

  return {
    padId:
      appData.selectedPad?.id ||
      pad?.id ||
      appData.wellParents?.getIn(['pad', 'id']) ||
      EVENT_MESSAGES.noPadId,
    padName:
      appData.selectedPad?.name ||
      pad?.name ||
      appData.wellParents?.getIn(['pad', 'name']) ||
      EVENT_MESSAGES.noPad,
  };
};

const getFracFleetId = appData =>
  +appData.fracFleet?.id ||
  +appData.parentAsset?.id ||
  +appData.completionWellInfo?.fracFleet.id ||
  appData.wellParents?.getIn(['fracFleet', 'id']) ||
  EVENT_MESSAGES.noFleetId;

const getFracFleetName = appData =>
  appData.fracFleet?.name ||
  appData.parentAsset?.name ||
  appData.completionWellInfo?.fracFleet.name ||
  appData.wellParents?.getIn(['fracFleet', 'name']) ||
  EVENT_MESSAGES.noFleet;

const PLAIN_PAYLOAD_EVENTS = new Set([
  MIXPANEL_ACTION_TYPES.APP_STORE.SEARCH,
  MIXPANEL_ACTION_TYPES.ASSET_MAP.ASSET_SEARCH,
  MIXPANEL_ACTION_TYPES.DASHBOARD.USAGE,
  MIXPANEL_ACTION_TYPES.FAVORITES.ADD_TO_FAVORITES,
  MIXPANEL_ACTION_TYPES.FAVORITES.STAR_HOVER,
  MIXPANEL_ACTION_TYPES.PLATFORM.ASSET_SEARCH,
  MIXPANEL_ACTION_TYPES.REDIRECT.ASSET_DASHBOARD,
  MIXPANEL_ACTION_TYPES.APP.OPEN_HELP_CENTER,
  MIXPANEL_ACTION_TYPES.DQ_APP_CUSTOM.CLICK,
]);

const getLogData = (type, action, { isCorvaV3FeaturesEnabled }) => {
  if (PLAIN_PAYLOAD_EVENTS.has(type)) return action;

  // Note: remove MIXPANEL_ACTION_TYPES.PAGE.PASSIVE_VIEW in 2026
  if (MIXPANEL_PAGE_TYPES.includes(type))
    return { URL: action.page, corvaV3: isCorvaV3FeaturesEnabled };

  if (MIXPANEL_DASHBOARD_ACTION_TYPES.includes(type))
    return {
      'Dashboard ID': action.dashboard.id,
      'Dashboard name': action.dashboard.name,
      corvaV3: isCorvaV3FeaturesEnabled,
    };

  if (MIXPANEL_APP_ACTION_TYPES.includes(type)) {
    const { app, dashboard, appDataRef = {}, metadata = {} } = action;
    const logData = {
      'App ID': app.id,
      'App name': getAppFullName(app),
      'Dashboard ID': dashboard.id,
      'Dashboard name': dashboard.name,
      corvaV3: isCorvaV3FeaturesEnabled,
    };
    if (type === MIXPANEL_ACTION_TYPES.APP.VIEW_CONTINUOUS)
      logData['View duration'] = metadata.appViewDuration || 0;

    const isMultiRig = app?.app?.is_multirig;
    const isCompletionApp = app?.segment?.[0] === SEGMENTS.COMPLETION;
    const isDrillingApp = app?.segment?.[0] === SEGMENTS.DRILLING;

    if (MIXPANEL_IN_APP_ACTIONS.has(type)) {
      // DC-3203
      // NOTE: DC, platform
      const appData = appDataRef.current || {};
      if (isMultiRig) {
        logData['Asset ID'] = EVENT_MESSAGES.multiasset;
        logData['Rig ID'] = EVENT_MESSAGES.multiasset;
        logData['Rig Name'] = EVENT_MESSAGES.multiasset;
        logData['Well ID'] = EVENT_MESSAGES.multiasset;
        logData['Well Name'] = EVENT_MESSAGES.multiasset;
        logData['Program ID'] = EVENT_MESSAGES.multiasset;
        logData['Program Name'] = EVENT_MESSAGES.multiasset;
      } else {
        logData['Asset ID'] =
          appData.rig?.asset_id ||
          appData.asset?.get?.('parent_asset_id') ||
          EVENT_MESSAGES.noAssetId;
        // NOTE: DC
        logData['Rig ID'] = appData.rig?.id || appData.rigId || EVENT_MESSAGES.noRigId;
        logData['Program ID'] = appData.program?.id || EVENT_MESSAGES.noProgramId;
        logData['Program Name'] = appData.program?.name || EVENT_MESSAGES.noProgramName;
        // NOTE: DC, platform
        logData['Rig Name'] =
          appData.rig?.name || appData.asset?.get?.('parent_asset_name') || EVENT_MESSAGES.noRig;
        // NOTE: DC, platform, withDrilloutUnit
        logData['Well ID'] =
          appData.well?.id ||
          appData.asset?.get?.('id') ||
          appData.asset?.id ||
          EVENT_MESSAGES.noWellId;
        logData['Well Name'] =
          appData.well?.name ||
          appData.asset?.get?.('name') ||
          appData.asset?.name ||
          EVENT_MESSAGES.noWell;
      }

      // NOTE: DC, platform
      logData.Segment = getAppSegment(app);

      // NOTE: DC, withFracFleet, fallback value
      logData['Fleet ID'] = getFracFleetId(appData);
      logData['Fleet Name'] = getFracFleetName(appData);

      const { padId, padName } = getPadData(appData, app?.settings?.padId);
      logData['Pad ID'] = padId;
      logData['Pad Name'] = padName;

      // NOTE: withDrilloutUnit
      logData['Drillout Unit ID'] =
        appData.asset?.drillout_unit?.id || EVENT_MESSAGES.noDrilloutUnitId;

      logData['Drillout Unit Name'] =
        appData.asset?.drillout_unit?.name || EVENT_MESSAGES.noDrilloutUnit;

      if (!isMultiRig && isCompletionApp) {
        logData['Rig ID'] = EVENT_MESSAGES.noRigId;
        logData['Rig Name'] = EVENT_MESSAGES.noRig;
      }

      if (!isMultiRig && isDrillingApp) {
        logData['Fleet ID'] = EVENT_MESSAGES.noFleetId;
        logData['Fleet Name'] = EVENT_MESSAGES.noFleet;
        logData['Pad ID'] = EVENT_MESSAGES.noPadId;
        logData['Pad Name'] = EVENT_MESSAGES.noPad;
      }

      return logData;
    }

    // NOTE: For all older app events
    const appSettings = action.app?.settings || {};
    if (appSettings.toJS) logData['Asset ID'] = appSettings.get('assetId');
    // NOTE: Old platform app
    else {
      // NOTE: DevCenter app
      if (appSettings.rigId) logData['Rig ID'] = appSettings.rigId;
      if (appSettings.fracFleetId) logData['Frac Fleet ID'] = appSettings.fracFleetId;
    }
    return omitBy(logData, isNil);
  }

  return {};
};

const SNOWPLOW_ONLY_EVENTS = [MIXPANEL_ACTION_TYPES.APP.VIEW_CONTINUOUS];

/**
 * Log data to Mixpanel
 */
export function logToMixpanel(type, action) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = currentUserSelector(state);
    const isImpersonating = isImpersonatingSelector(state);
    const isCurrentUserCorvaUser = isCurrentUserCorvaUserSelector(state);

    if (!currentUser) return;

    const isCorvaV3FeaturesEnabled = getIsCorvaV3FeaturesEnabled({
      isImpersonating,
      actionType: type,
      state,
    });

    const logData = getLogData(type, action, { isCorvaV3FeaturesEnabled });
    if (!logData) return;

    // NOTE: For testing purpose
    if (isLoggingAnalyticsToConsole()) console.log('Event:', type, logData); // eslint-disable-line

    trackSnowplowEvent(type, logData);

    // NOTE: Track app view continuous ONLY for snowplow analytics. Skip that event for others
    if (SNOWPLOW_ONLY_EVENTS.includes(type)) return;

    if (isImpersonating) return;

    const commonData = {
      'Company ID': currentUser.get('company_id'),
      Company: currentUser.get('company').get('name'),
      'Company Domain': getCompanyDomainFromEmail(currentUser.get('email')),
      'User ID': currentUser.get('id'),
      email: currentUser.get('email'),
      'User name': `${currentUser.get('first_name')} ${currentUser.get('last_name')}`,
      Segment: JSON.stringify(currentUser.getIn(['preference', 'segment'])),
      Title: currentUser.get('title'),
      Group: currentUser.get('group')?.toJS() || [],
      Resource: currentUser.get('resource')?.toJS() || [],
      Role: currentUser.get('role'),
    };

    if (!MIXPANEL_ENABLED) return;

    // NOTE: Track ONLY page view mixpanel event for Corva users (Greg)
    if (isCurrentUserCorvaUser && type !== MIXPANEL_ACTION_TYPES.PAGE.VIEW) return;
    mixpanel.register(commonData);
    mixpanel.track(type, logData);
  };
}

export function trackUserToMixpanel(user) {
  if (!MIXPANEL_ENABLED) {
    return;
  }

  if (REQUIRE_USER_CONSENT_FOR_TRACKING && !user.get('consent_to_process_data')) {
    mixpanel.opt_out_tracking();
  } else {
    mixpanel.opt_in_tracking();
    mixpanel.identify(user.get('id'));

    mixpanel.people.set({
      $name: `${user.get('first_name')} ${user.get('last_name')}`,
      $email: user.get('email'),
      Role: user.get('role'),
    });
  }
}
