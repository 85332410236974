import { Component } from 'react';
import { connect } from 'react-redux';

import { logToGoogleAnalytics } from '~/actions/ga';
import { logToMixpanel } from '~/actions/mixpanel';

import { MIXPANEL_ACTION_TYPES } from '~/constants/mixpanel';

const ONE_HOUR = 1000 * 60 * 60;

// NOTE: Source: https://github.com/react-ga/react-ga/issues/122#issuecomment-299692833
const withPageLogger = WrappedComponent => {
  class PageLogger extends Component {
    componentDidMount() {
      this.passivePageViewIntervalId = null;

      this.logPageChange(this.props.location.pathname, this.props.location.search);
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.search !== prevProps.location.search
      ) {
        this.logPageChange(this.props.location.pathname, this.props.location.search);
      }
    }

    /**
     * Handle route updates. Only for production
     */
    trackPageView(page) {
      this.props.logToGoogleAnalytics(page);
      this.props.logToMixpanel(MIXPANEL_ACTION_TYPES.PAGE.VIEW, { page });
    }

    /**
     * Handle route passive view. Only for production
     */
    trackPassivePageView(page) {
      this.props.logToMixpanel(MIXPANEL_ACTION_TYPES.PAGE.PASSIVE_VIEW, { page });
    }

    // NOTE: It doesn't track on app initialization, because current user isn't available yet”
    logPageChange(pathname, search = '') {
      const page = pathname + search;

      this.trackPageView(page);

      if (this.passivePageViewIntervalId !== null) {
        clearInterval(this.passivePageViewIntervalId); // NOTE: Previous page
      }
      this.trackPassivePageView(page); // NOTE: Fire on interval initialization
      this.passivePageViewIntervalId = setInterval(() => this.trackPassivePageView(page), ONE_HOUR);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(null, {
    logToGoogleAnalytics,
    logToMixpanel,
  })(PageLogger);
};

export default withPageLogger;
